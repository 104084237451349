import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import DocumentsView from '@/views/DocumentsView.vue';
import ApplicantsView from '@/views/ApplicantsView.vue';
import OrdersView from '@/views/OrdersView.vue';
import PaymentsView from '@/views/PaymentsView.vue';
import SettingsView from '@/views/SettingsView.vue';
import SuppliersView from '@/views/SuppliersView.vue';
import UserManagementView from '@/views/UserManagementView.vue';
import ProfileView from '@/views/ProfileView.vue';
import FirstTimePassword from '@/views/FirstTimePassword.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';
import ForgotPasswordResetView from '@/views/ForgotPasswordResetView.vue';
import RegisterForm from '@/views/RegistrationForm.vue';
import MealsView from '@/views/MealsView.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/meals',
    name: 'MealsView',
    component: MealsView,
  },
  {
    path: '/register',
    name: 'RegisterForm',
    component: RegisterForm,
  },
  {
    path: '/forgot-password',
    name: 'ResetPasswordView',
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'ForgotPasswordResetView',
    component: ForgotPasswordResetView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/documents',
    name: 'DocumentsView',
    component: DocumentsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/applicants',
    name: 'ApplicantsView',
    component: ApplicantsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders',
    name: 'OrdersView',
    component: OrdersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payments',
    name: 'PaymentsView',
    component: PaymentsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/suppliers',
    name: 'SuppliersView',
    component: SuppliersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'UserManagementView',
    component: UserManagementView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: FirstTimePassword,
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  }
];

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.VUE_APP_BASEURL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      try {
        await store.dispatch('refreshToken');
        next();
      } catch (error) {
        console.error('Failed to refresh token', error);
        next('/login');
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;