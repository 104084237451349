<template>
    <v-card>
      <v-card-title> Úprava objednávky č. {{ orderId }} </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orderItems"
          :loading="isLoading"
          :items-per-page="10"
          hide-default-footer
          :hide-default-header="smAndDown"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.meno_jedla="{ item }">
            {{ item.meno_jedla ?? "Neznáme jedlo" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.porcia_jedla="{ item }">
            {{ item.porcia_jedla ?? "Neznáma porcia" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.cena_jedla="{ item }">
            €{{ item.cena_jedla.toFixed(2) ?? "Neznáma cena" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.quantita_jedla="{ item }">
            {{ item.quantita_jedla ?? 1 }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.celkova_cena_jedla="{ item }">
           {{ item.quantita_jedla > 0 ? `€${(item.cena_jedla * item.quantita_jedla).toFixed(2)}` : '-' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-btn small @click="incrementQuantity(item)">+</v-btn>
            <v-btn small @click="decrementQuantity(item)" :disabled="item.quantita_jedla <= 0">-</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex flex-column align-end">
      <v-btn class="mb-2" @click="saveChanges">Uložiť zmeny</v-btn>
      <v-chip
        color="green"
        text-color="white"
      >
        Celková cena objednávky: €{{ totalOrderPrice.toFixed(2) }}
      </v-chip>
    </v-card-actions>
    
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
  </template>
  
  <script>
import { ref, computed } from "vue";
import Services from "@/api/services";
import { useDisplay } from "vuetify/lib/framework.mjs";

export default {
  name: "OrderItemsEditor",
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { smAndDown } = useDisplay();
    const orderItems = ref([]);
    const isLoading = ref(true);

    const headers = [
      { title: "Meno jedla", value: "meno_jedla" },
      { title:"Porcia jedla", value: "porcia_jedla" },
      { title: "Cena jedla", value: "cena_jedla" },
      { title: "Počet jedla", value: "quantita_jedla" },
      { title: "Celková cena jedla", value: "celkova_cena_jedla" },
      { value: "actions", sortable: false, align: "end" },
    ];

    const totalOrderPrice = computed(() => {
      return orderItems.value.reduce((total, item) => {
        return item.quantita_jedla > 0
          ? total + item.cena_jedla * item.quantita_jedla
          : total;
      }, 0);
    });

    const fetchOrderItems = async () => {
      try {
        isLoading.value = true;
        orderItems.value = await Services.getOrderItems(props.orderId);
        console.log("Order items:", orderItems.value);
      } catch (error) {
        console.error("Error fetching order items:", error);
        showSnackbar("Chyba pri načítaní položiek objednávky", "error");
      } finally {
        isLoading.value = false;
      }
    };

    const incrementQuantity = (item) => {
      item.quantita_jedla++;
    };

    const decrementQuantity = (item) => {
      if (item.quantita_jedla > 0) {
        item.quantita_jedla--;
      }
    };

    const saveChanges = async () => {
      try {
        isLoading.value = true; // Show loading indicator
        const itemsToUpdate = orderItems.value.map(item => ({
          id: item.id,
          quantity: item.quantita_jedla
        }));
        const response = await Services.updateOrderItems(props.orderId, itemsToUpdate);
        console.log("Response:", response);
        if (response.message === "Order items updated successfully") {
          showSnackbar("Zmeny boli úspešne uložené", "success");
          await fetchOrderItems(); // Refresh the order items
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        console.error("Error saving changes:", error);
        showSnackbar("Nastala chyba pri ukladaní zmien: " + error.message, "error");
      } finally {
        isLoading.value = false; // Hide loading indicator
      }
    };


    // Fetch order items when component is mounted
    fetchOrderItems();


    const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
    };

    return {
      headers,
      orderItems,
      isLoading,
      totalOrderPrice,
      incrementQuantity,
      decrementQuantity,
      saveChanges,
      smAndDown,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5;
  /* Adjust line-height to ensure the text is centered */
  display: inline-block;
  /* Ensure the button is displayed as an inline element */
}
</style>