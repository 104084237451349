<template>
  <div>
    <h1 :class="[$vuetify.display.smAndDown ? 'text-h5' : 'text-h4', 'text-center mb-6 font-weight-bold']">Rodinná Škola</h1>

    <v-card
      class="mx-auto pa-6 pb-8"
      :elevation="$vuetify.display.smAndDown ? 2 : 8"
      :max-width="$vuetify.display.smAndDown ? '100%' : '448'"
      :rounded="$vuetify.display.smAndDown ? 'md' : 'lg'"
    >
      <h2 :class="[$vuetify.display.smAndDown ? 'text-h6' : 'text-h5', 'text-center mb-6']">Resetovanie prihlasovacieho hesla</h2>

      <v-text-field
        v-model="email"
        density="compact"
        placeholder="Emailová adresa"
        prepend-inner-icon="mdi-email-outline"
        variant="outlined"
        class="mb-4"
        required
      ></v-text-field>

      <v-card
        v-if="errorMessage"
        class="mb-4"
        color="red lighten-5"
        variant="tonal"
      >
        <v-card-text class="text-caption">
          {{ errorMessage }}
        </v-card-text>
      </v-card>

      <v-btn
        class="mb-4 custom-btn"
        :disabled="!isFormValid"
        :size="$vuetify.display.smAndDown ? 'x-large' : 'large'"
        variant="outlined"
        block
        @click="requestPasswordReset"
      >
        Vyžiadať resetovanie hesla
      </v-btn>
    </v-card>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </div>
</template>

<script>
import services from '@/api/services';
import router from '@/router';

export default {
  data() {
    return {
      email: '',
      errorMessage: '',
      nackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  computed: {
    isFormValid() {
      return this.email.trim() !== '';
    }
  },
  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    async requestPasswordReset() {
      try {
        await services.forgotPassword(this.email);
        this.showSnackbar('Email na resetovanie hesla bol odoslaný', 'success');
        router.push({ name: 'ForgotPasswordResetView', query: { email: this.email } });
      } catch (error) {
        console.error(error);
        this.errorMessage = "Vyskytla sa chyba. Skúste to neskôr.";
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.custom-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.v-btn.custom-btn.v-btn--disabled {
  color: grey !important;
}

@media (max-width: 600px) {
  .v-card {
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
</style>
