<template>
  <v-container fluid class="pa-0">
    <v-row v-if="!showInfo">
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-data-table 
              :headers="headers" 
              :items="orders"
              :loading="isLoading" 
              :items-per-page="10"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.info="{ item }">
                <a 
                  @click.prevent="!isBeforeOrderTime(item.order_time) ? showOrderInfo(item.id) : null" 
                  :class="['order-link', { 'clickable': !isBeforeOrderTime(item.order_time) }]"
                >
                  {{ item.info }}
                </a>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.order_date="{ item }">
                {{ formatDate(item.order_time) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.order_time="{ item }">
                {{ formatDateToTime(item.order_time) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.send="{ item }">
                {{ item.email_sent ? "Áno" : "Nie" }}
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-btn 
                  v-if="isBeforeOrderTime(item.order_time)"
                  small
                  @click="showOrderInfo(item.id)"
                  class="mr-2"
                >
                  Info
                </v-btn>
                <v-btn 
                  v-if="!isBeforeOrderTime(item.order_time)" 
                  @click="downloadInvoice(item.id)" 
                  small
                >
                  Stiahnuť faktúru
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-btn icon @click="closeOrderInfo" class="mb-4">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <SupplierInfoComponent :orderId="selectedOrderId" />
      </v-col>
    </v-row>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
import Services from "@/api/services";
import moment from "moment";
import { useDisplay } from "vuetify/lib/framework.mjs";
import SupplierInfoComponent from "./SupplierInfoComponent.vue";

export default {
  components: {
    SupplierInfoComponent
  },
  setup() {
    const mobile = useDisplay();
    const orders = ref([]);
    const isLoading = ref(false);
    const showInfo = ref(false);
    const selectedOrderId = ref(null);

    const headers = [
      { title: "Objednávka", key: "info" },
      { title: "Dátum", key: "order_date" },
      { title: "Odoslané", key: "send" },
      { title: "Čas objednávky", key: "order_time" },
      { title: "Škola", key: "school" },
      { title: "Počet obedov", key: "total_meals", align: "center" },
      { title: "Akcie", key: "actions", sortable: false },
    ];

    const fetchOrders = async () => {
  isLoading.value = true;
  try {
    const supplierEmail = localStorage.getItem("email");
    const supplier_id = await Services.getUserIdByEmail(supplierEmail);
    const response = await Services.getOrdersForSupplier(supplier_id);
    console.log("Orders response:", response);
    
    if (Array.isArray(response) && response.length > 0) {
      orders.value = await Promise.all(
        response.map(async (order) => {
          let schoolName = "Neznáma škola";
          try {
            const schoolDetails = await Services.getSchoolById(order.school_id);
            console.log("School Details:", schoolDetails);
            if (schoolDetails && schoolDetails.name) {
              schoolName = schoolDetails.name;
            }
            console.log("School Name:", schoolName);
          } catch (error) {
            console.error(`Error fetching school ${order.school_id}:`, error);
          }

          return {
            ...order,
            school: schoolName,
            info: `Objednávka #${order.id}`,
          };
        })
      );
      console.log("Processed Orders:", orders.value);
    } else {
      orders.value = [];
      console.log("No orders found for this supplier");
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("No orders found for this supplier");
      orders.value = [];
    } else {
      console.error("Error fetching orders:", error);
      showSnackbar("Chyba pri načítaní objednávok", "error");
    }
  } finally {
    isLoading.value = false;
  }
};

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    const formatDateToTime = (date) => {
      return moment(date).format("HH:mm");
    };

    const isBeforeOrderTime = (orderTime) => {
      return moment().isBefore(moment(orderTime));
    };

    const showOrderDetails = (orderId) => {
      console.log(`Show details for order ${orderId}`);
      showSnackbar(`Zobrazujú sa detaily objednávky #${orderId}`, "info");
    };

    const showOrderInfo = (orderId) => {
      selectedOrderId.value = orderId;
      showInfo.value = true;
    };

    const closeOrderInfo = () => {
      showInfo.value = false;
      selectedOrderId.value = null;
    };

    const downloadInvoice = (orderId) => {
      console.log(`Downloading invoice for order ${orderId}`)
    };

    onMounted(() => {
      fetchOrders();
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      orders,
      headers,
      isLoading,
      formatDate,
      formatDateToTime,
      isBeforeOrderTime,
      showOrderDetails,
      showOrderInfo,
      closeOrderInfo,
      downloadInvoice,
      mobile,
      showInfo,
      selectedOrderId,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.order-link {
  color: purple;
  text-decoration: none;
  cursor: pointer;
}

.order-link:hover {
  text-decoration: underline;
}
</style>