<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-form ref="form" v-model="valid" lazy-validation class="form-container">
          <v-text-field
            v-model="meal.name"
            :rules="[rules.required]"
            label="Meno jedla"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          <v-textarea
            v-model="meal.description"
            :rules="[rules.required]"
            label="Popis jedla"
            variant="underlined"
            required
            class="input-field"
          ></v-textarea>
          <v-select
            v-model="meal.allergens"
            :items="allergenOptions"
            item-text="name"
            item-value="id"
            label="Alergény"
            variant="underlined"
            multiple
            class="input-field"
          ></v-select>
          <v-text-field
            v-model.number="meal.price_supplier"
            :rules="[rules.required, rules.number]"
            label="Cena od dodávateľa"
            variant="underlined"
            type="number"
            step="0.01"
            required
            class="input-field"
          ></v-text-field>
          <v-text-field
            v-model.number="meal.price_supplier_half"
            :rules="[rules.number]"
            label="Cena polovičnej porcie od dodávateľa"
            variant="underlined"
            type="number"
            step="0.01"
            class="input-field"
          ></v-text-field>
          <v-checkbox
            v-model="meal.has_fixed_student_price"
            label="Fixná cena pre študenta"
            class="input-field"
          ></v-checkbox>
          <v-text-field
            v-if="meal.has_fixed_student_price"
            v-model.number="meal.price_student"
            :rules="[rules.required, rules.number]"
            label="Fixná cena pre študenta"
            variant="underlined"
            type="number"
            step="0.01"
            required
            class="input-field"
          ></v-text-field>
          <v-date-picker
            v-model="meal.available_date"
            :rules="[rules.required]"
            width="100%"
            label="Dátum dostupnosti jedla"
            required
            landscape
            class="custom-date-picker input-field"
          ></v-date-picker>

          <v-alert
            v-if="showDateAlert"
            type="error"
            class="mt-2"
          >
            Prosím, vyberte dátum dostupnosti jedla.
          </v-alert>

          <v-btn 
            variant="outlined" 
            @click="submit" 
            :disabled="!isFormValid"
            color="purple"
            :block="mobile"
            class="submit-btn mt-4"
          >
            Pridať jedlo
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import Services from '@/api/services';
import moment from 'moment';
import { useDisplay } from 'vuetify/lib/framework.mjs';
export default {
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },
  emits: ['meal-added'],
  setup(props, { emit }) {
    const { mobile } = useDisplay();
    const form = ref(null);
    const valid = ref(false);
    const allergens = ref([]);
    const showDateAlert = ref(false);
    const meal = ref({
      name: '',
      description: '',
      allergens: [],
      price_supplier: null,
      price_supplier_half: null,
      has_fixed_student_price: false,
      price_student: null,
      available_date: null,
    });

    const allergenOptions = ref([]);

    const rules = {
      required: value => !!value || 'Required.',
      number: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be a number.',
    };

    const isFormValid = computed(() => {
      return valid.value && meal.value.available_date !== null;
    });

    const fetchAllergens = async () => {
      try {
        const response = await Services.getAllergens();
        allergens.value = response;
        allergenOptions.value = response.map(allergen => `${allergen.code}: ${allergen.name}`);
      } catch (error) {
        console.error('Error fetching allergens:', error);
      }
    };

    onMounted(() => {
      fetchAllergens();
    });

    const submit = async () => {
      if (form.value && form.value.validate()) {
        if (meal.value.available_date === null) {
          showDateAlert.value = true;
          return;
        }

        try {
          const formattedDate = moment(meal.value.available_date).format('YYYY-MM-DD');
          
          const selectedAllergenIds = meal.value.allergens.map(selected => {
            const allergen = allergens.value.find(allergen => `${allergen.code}: ${allergen.name}` === selected);
            return allergen ? allergen.id : null;
          }).filter(id => id !== null);

          console.log("Supplier ID: ", props.supplier.id);

          const newMeal = {
            name: meal.value.name,
            description: meal.value.description,
            allergens: selectedAllergenIds,
            price_supplier: meal.value.price_supplier,
            price_supplier_half: meal.value.price_supplier_half,
            has_fixed_student_price: meal.value.has_fixed_student_price,
            price_student: meal.value.has_fixed_student_price ? meal.value.price_student : null,
            date: formattedDate,
            supplier_id: props.supplier.id,
          };

          const response = await Services.addMeal(newMeal);
          console.log('New meal:', response);

          emit('meal-added', newMeal);
          if (form.value) {
            form.value.reset();
          }
          meal.value = {
            name: '',
            description: '',
            allergens: [],
            price_supplier: null,
            price_supplier_half: null,
            has_fixed_student_price: false,
            price_student: null,
            available_date: null,
          };
          showDateAlert.value = false;
        } catch (error) {
          console.error('Error adding meal:', error);
          showSnackbar('Chyba pri pridávaní jedla. Skúste to znova.', 'error');
        }
      }
    };

    const removeTrailingDecimalSeparator = (value) => {
      if (value !== null) {
        const stringValue = value.toString();
        if (stringValue.endsWith('.') || stringValue.endsWith(',')) {
          return parseFloat(stringValue.slice(0, -1));
        }
      }
      return value;
    };

    watch(() => meal.value.price_supplier, (newValue) => {
      meal.value.price_supplier = removeTrailingDecimalSeparator(newValue);
    });

    watch(() => meal.value.price_supplier_half, (newValue) => {
      meal.value.price_supplier_half = removeTrailingDecimalSeparator(newValue);
    });

    watch(() => meal.value.price_student, (newValue) => {
      meal.value.price_student = removeTrailingDecimalSeparator(newValue);
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      meal,
      allergenOptions,
      rules,
      submit,
      showDateAlert,
      isFormValid,
      mobile,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-button {
  width: 100%;
  max-width: 200px;
}

.custom-date-picker {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.custom-date-picker :deep(.v-date-picker-header__value) {
  color: purple !important;
}

.custom-date-picker :deep(.v-date-picker-controls .v-btn) {
  color: purple !important;
}

.v-btn-toggle {
  display: flex;
  justify-content: center;
}

.v-btn-toggle .v-btn {
  flex: 1;
}

.active-btn {
  background-color: purple !important;
  color: white !important;
}

.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
  margin-left: 0;
}

.custom-date-picker {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>
