<template>
    <v-col cols="12" md="6" lg="5">
      <v-transition-fade>
        <v-spacer></v-spacer>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="supplierData.name"
            label="Názov dodávateľa"
            :rules="[rules.required]"
            variant="underlined"
            class="mb-4 input-field"
          ></v-text-field>
  
          <v-text-field
            v-model="supplierData.email"
            label="Email"
            :rules="[rules.required, rules.email]"
            variant="underlined"
            class="mb-4 input-field"
          ></v-text-field>
  
          <v-text-field
            v-model="supplierData.phone_number"
            label="Telefónne číslo"
            :rules="[rules.required, rules.phoneNumber]"
            variant="underlined"
            class="mb-4 input-field"
          ></v-text-field>
  
          <v-text-field
            v-model="supplierData.address"
            label="Adresa"
            :rules="[rules.required]"
            variant="underlined"
            class="mb-4 input-field"
          ></v-text-field>
  
          <v-menu
            v-model="orderTimeMenu"
            :close-on-content-click="false"
            @v-click-outside="orderTimeMenu = false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="supplierData.time_of_sending_order"
                label="Čas poslania objednávky"
                readonly
                density="compact"
                v-bind="props"
                :rules="[rules.required]"
                variant="underlined"
                class="mb-4 input-field"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="orderTimeMenu"
              v-model="supplierData.time_of_sending_order"
              format="24hr"
              @update:model-value="orderTimeMenu = false"
            >
              <template v-slot:actions>
                <v-btn
                  text
                  color="purple"
                  @click="orderTimeMenu = false"
                >
                  Zatvoriť
                </v-btn>
              </template>
            </v-time-picker>
          </v-menu>
      
          <v-menu
            v-model="changeDeadlineMenu"
            :close-on-content-click="false"
            @v-click-outside="changeDeadlineMenu = false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="supplierData.order_modification_limit_time"
                label="Zmeny do"
                readonly
                density="compact"
                v-bind="props"
                :rules="[rules.required, rules.timeChangeDeadlineBeforeOrderTime]"
                variant="underlined"
                class="mb-4 input-field"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="changeDeadlineMenu"
              v-model="supplierData.order_modification_limit_time"
              format="24hr"
              @update:model-value="changeDeadlineMenu = false"
            >
              <template v-slot:actions>
                <v-btn
                  text
                  color="purple"
                  @click="changeDeadlineMenu = false"
                >
                  Zatvoriť
                </v-btn>
              </template>
            </v-time-picker>
          </v-menu>
          
          <v-text-label class="mb-4">Poslať objednávku emailom</v-text-label>
          <v-btn-toggle
            v-model="supplierData.send_order_by_email"
            mandatory
            class="mb-4 input field"
          >
            <v-btn 
              :value="true" 
              :class="{ 'active-btn': supplierData.send_order_by_email === true }"
            >
              Áno
            </v-btn>
            <v-btn 
              :value="false" 
              :class="{ 'active-btn': supplierData.send_order_by_email === false }"
            >
              Nie
            </v-btn>
          </v-btn-toggle>
  
          <v-spacer></v-spacer>
          <v-btn
            @click="submit"
            :disabled="!valid"
            class="mt-4"
            variant="outlined"
          >
            Aktualizovať
          </v-btn>
        </v-form>
      </v-transition-fade>
    </v-col>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { VTimePicker } from 'vuetify/labs/VTimePicker';
  import Services from '@/api/services';
  
  export default {
    emits: ['supplier-updated'],
    components: {
      VTimePicker,
    },
    setup(props, { emit }) {
      const form = ref(null);
      const valid = ref(false);
      const orderTimeMenu = ref(false);
      const changeDeadlineMenu = ref(false);
  
      const supplierData = ref({
        name: '',
        email: '',
        phone_number: '',
        address: '',
        time_of_sending_order: null,
        order_modification_limit_time: null,
        send_order_by_email: false,
      });
  
      const rules = {
        required: value => !!value || 'Povinné pole.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Neplatný email.';
        },
        phoneNumber: value => {
          const pattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
          return pattern.test(value) || 'Neplatné telefónne číslo.';
        },
        timeChangeDeadlineBeforeOrderTime: value => {
          if (!supplierData.value.time_of_sending_order || !value) return true;
          return new Date(`2000-01-01T${value}`) < new Date(`2000-01-01T${supplierData.value.time_of_sending_order}`) || 'Zmeny objednávky musia byť pred časom poslania objednávky.';
        },
      };
  
      const formatTime = (time) => {
        if (!time) return null;
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
      };
  
      const fetchSupplierData = async () => {
        try {
          const email = localStorage.getItem('email');
          if (!email) {
            throw new Error('No email found in localStorage');
          }
          const response = await Services.getSupplierByEmail(email);
          console.log('Supplier data fetched successfully:', response);
          supplierData.value = {
            ...response,
            send_order_by_email: response.send_order_by_email || false,
            time_of_sending_order: formatTime(response.time_of_sending_order),
            order_modification_limit_time: formatTime(response.order_modification_limit_time),
          };
        } catch (error) {
          console.error('Error fetching supplier data:', error);
          showSnackbar('Nepodarilo sa načítať údaje dodávateľa', 'error');
        }
      };
  
      const submit = async () => {
          if (form.value.validate()) {
            try {
              const dataToSubmit = {
                ...supplierData.value,
                time_of_sending_order: supplierData.value.time_of_sending_order + ':00',
                order_modification_limit_time: supplierData.value.order_modification_limit_time + ':00',
              };
              const response = await Services.updateSupplierData(dataToSubmit);
              showSnackbar('Údaje dodávateľa boli úspešne aktualizované', 'success');
              console.log('Supplier data updated successfully:', response);
              emit('supplier-updated');
            } catch (error) {
              showSnackbar('Prosím, vyplňte všetky povinné polia správne', 'warning');
              console.error('Failed to update supplier data:', error);
            }
          }
        };
  
      onMounted(() => {
        fetchSupplierData();
      });

      const snackbar = ref(false);
      const snackbarText = ref('');
      const snackbarColor = ref('success');
      const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
      };
  
      return {
        form,
        valid,
        orderTimeMenu,
        changeDeadlineMenu,
        supplierData,
        rules,
        submit,
        formatTime,
        snackbar,
        snackbarText,
        snackbarColor,
      };
    },
  };
  </script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .v-btn:disabled {
    color: rgba(128, 0, 128, 0.5) !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }
  
  .v-btn-toggle {
    display: flex;
    justify-content: center;
  }
  
  .v-btn-toggle .v-btn {
    flex: 1;
  }
  
  .active-btn {
    background-color: purple !important;
    color: white !important;
  }
  
  .input-field {
    margin-bottom: 20px;
  }
  </style>