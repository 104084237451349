<template>
  <BaseLayout>
    <v-container fluid class="p-0">
      <v-row justify="start">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card outlined class="mb-4">
            <v-card-title 
              class="text-h6 font-weight-bold clickable d-flex justify-space-between align-center"
              @click="togglePasswordChange"
            >
              <span>Zmena hesla</span>
              <v-icon>
                {{ showPasswordChange ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-card-title>
            <v-expand-transition>
              <v-card-text v-show="showPasswordChange">
                <v-form @submit.prevent="changePassword">
                  <v-text-field
                    v-model="oldPassword"
                    label="Staré heslo"
                    type="password"
                    variant="underlined"
                    :rules="[v => !!v || 'Staré heslo je povinné pole']"
                    @click:clear="clearFunctionOld"
                    density="compact"
                    class="small-field"
                  ></v-text-field>
                  <v-text-field
                    v-model="newPassword"
                    label="Nové heslo"
                    type="password"
                    variant="underlined"
                    :rules="passwordRules"
                    @click:clear="clearFunctionNew"
                    density="compact"
                    class="small-field"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    label="Potvrď nové heslo"
                    type="password"
                    variant="underlined"
                    :rules="confirmPasswordRules"
                    @click:clear="clearFunctionConfirm"
                    density="compact"
                    class="small-field"
                  ></v-text-field>
                  <v-btn 
                    type="submit" 
                    :disabled="!isPasswordValid"
                    class="mt-2"
                    variant="outlined"
                  >
                    Uložiť
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar v-model="successSnackbar" color="success" timeout="3000">
        Heslo bolo úspešne zmenené!
      </v-snackbar>

      <v-snackbar v-model="errorSnackbar" color="error" timeout="3000">
        {{ errorMessage }}
      </v-snackbar>
      <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
    </v-container>
  </BaseLayout>
</template>

<script>
import { ref, computed } from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'
import { useStore } from 'vuex'

export default {
  name: 'ProfileView',
  components: {
    BaseLayout,
  },
  setup() {
    const oldPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const successSnackbar = ref(false)
    const errorSnackbar = ref(false)
    const errorMessage = ref('')
    const showPasswordChange = ref(false)
    const store = useStore()

    const passwordRules = [
      v => !!v || 'Heslo je povinné pole',
      v => (v && v.length >= 8) || 'Heslo musí obsahovať aspoň 8 znakov',
    ]

    const confirmPasswordRules = [
      v => !!v || 'Nutné potvrdiť heslo',
      v => v === newPassword.value || 'Heslá sa nezhodujú',
    ]

    const isPasswordValid = computed(() => 
      oldPassword.value.length > 0 &&
      newPassword.value.length >= 8 && 
      confirmPassword.value === newPassword.value
    )

    const togglePasswordChange = () => {
      showPasswordChange.value = !showPasswordChange.value
    }

    const changePassword = async () => {
      try {
        await store.dispatch('setPassword', {
          old_password: oldPassword.value,
          password: newPassword.value,
          email: localStorage.getItem('email')
        })
        oldPassword.value = ''
        newPassword.value = ''
        confirmPassword.value = ''
        showSnackbar('Heslo bolo úspešne zmenené', 'success')
        successSnackbar.value = true
        showPasswordChange.value = false
      } catch (error) {
        console.error(error)
        if (error.response && error.response.status === 401) {
          errorMessage.value = 'Nesprávne staré heslo. Skúste to znova.'
        } else {
          errorMessage.value = 'Pri zmene hesla sa vyskytla chyba. Skúste to znova neskôr.'
        }
        errorSnackbar.value = true
      }
    }

    const clearFunctionOld = () => {
      oldPassword.value = ''
    }

    const clearFunctionNew = () => {
      newPassword.value = ''
    }

    const clearFunctionConfirm = () => {
      confirmPassword.value = ''
    }


    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };
    
    return {
      oldPassword,
      newPassword,
      confirmPassword,
      passwordRules,
      confirmPasswordRules,
      isPasswordValid,
      changePassword,
      clearFunctionOld,
      clearFunctionNew,
      clearFunctionConfirm,
      successSnackbar,
      errorSnackbar,
      errorMessage,
      showPasswordChange,
      togglePasswordChange,
      snackbar,
      snackbarText,
      snackbarColor,
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.text-h6 {
  font-weight: 500;
  color: #333;
}

.clickable {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-text {
  padding-top: 0;
}

.v-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-field {
  max-width: 300px;
}
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent;
  box-shadow: none;
}
</style>
