<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-form ref="form" v-model="valid" lazy-validation class="form-container">
          <!-- Parent search field -->

          <!-- Student fields -->
          <v-text-field v-model="student.user.firstName" :rules="[rules.required]" label="Meno" variant="underlined"
            class="input-field" required></v-text-field>
          <v-text-field v-model="student.user.lastName" :rules="[rules.required]" label="Priezvisko"
            variant="underlined" class="input-field" required></v-text-field>
          <v-text-field v-model="student.classroom" :rules="[rules.required]" label="Trieda" variant="underlined"
            class="input-field" required></v-text-field>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ props }">
              <v-text-field v-model="formattedDateOfBirth" label="Dátum narodenia" prepend-icon="mdi-calendar" readonly
                v-bind="props" :rules="[rules.required]" variant="underlined" class="input-field"></v-text-field>
            </template>
            <v-date-picker v-model="student.date_of_birth" @update:model-value="menu = false"></v-date-picker>
          </v-menu>
          <v-btn-toggle v-model="student.studyForm" mandatory class="mb-4 input-field">
            <v-btn value="Denné štúdium" :disabled="student.schools.length > 1"
              :class="{ 'active-btn': student.studyForm === 'Denné štúdium', 'disabled-btn': student.schools.length > 1 }">
              Denné štúdium
            </v-btn>
            <v-btn value="Externé štúdium" :class="{ 'active-btn': student.studyForm === 'Externé štúdium' }">
              Externé štúdium
            </v-btn>
          </v-btn-toggle>
          <div v-for="(school, index) in student.schools" :key="index" class="d-flex align-center input-field">
            <v-select v-model="student.schools[index]" :items="availableSchools(index)" item-title="name"
              item-value="id" variant="underlined" label="Škola" required :rules="[rules.required]"></v-select>
            <v-btn icon @click="removeSchool(index)" v-if="student.schools.length > 1">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </div>

          <!-- "Pridať školu" button -->
          <v-btn text @click="addSchool"
            :disabled="student.studyForm === 'Denné štúdium' || student.schools.length >= filteredSchools.length"
            class="text-button mb-4">
            + Pridať školu
          </v-btn>

          <v-card-title class="text-h6 mb-2">Zákonný zástupca</v-card-title>

          <v-autocomplete v-model="selectedParent" :items="parentSearchResults" :loading="isSearchingParent"
            v-model:search-input="parentSearch" item-title="email" item-value="id" label="Vyhľadať rodiča"
            placeholder="Zadajte email rodiča" prepend-icon="mdi-account-search" return-object
            :no-data-text="noDataText" @update:search="handleSearch" variant="solo">
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <v-list-item-content>
                  <v-list-item-title>{{ item.raw.first_name }} {{ item.raw.last_name }}</v-list-item-title>
                  <v-list-item-subtitle class="text-grey">{{ item.raw.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-expand-transition>
            <div v-if="!selectedParent">
              <v-text-field variant="underlined" v-model="newParent.firstName" label="Meno rodiča"
                :rules="[rules.required]" required></v-text-field>
              <v-text-field variant="underlined" v-model="newParent.lastName" label="Priezvisko rodiča"
                :rules="[rules.required]" required></v-text-field>
              <v-text-field variant="underlined" v-model="newParent.email" label="Email rodiča"
                :rules="[rules.required, rules.email]" required></v-text-field>
              <v-text-field variant="underlined" v-model="newParent.phoneNumber" label="Telefónne číslo rodiča"
                :rules="[rules.required, rules.phoneNumber]" required></v-text-field>
            </div>
          </v-expand-transition>
          <!-- Spacer between buttons -->
          <v-spacer class="mb-4"></v-spacer>

          <!-- "Pridať študenta" button -->
          <v-btn variant="outlined" @click="submit" :disabled="!valid" class="submit-btn">
            Pridať študenta
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, watch } from 'vue';
import Services from '@/api/services';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { debounce } from 'lodash';
import { format, parse, isValid } from 'date-fns';

export default {
  name: 'StudentRegistrationForm',
  props: {
    schools: {
      type: Array,
      required: true,
    },
  },
  emits: ['student-added'],
  setup(props, { emit }) {
    const { mobile } = useDisplay();
    const form = ref(null);
    const valid = ref(false);
    const menu = ref(false);
    const parentSearch = ref('');
    const selectedParent = ref(null);
    const parentSearchResults = ref([]);
    const isSearchingParent = ref(false);
    const newParent = ref({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    });

    const student = ref({
      user: {
        firstName: '',
        lastName: '',
        hashed_password: '1234',
        user_type: 'not_specified',
      },
      date_of_birth: null,
      classroom: '',
      studyForm: 'Denné štúdium',
      schools: [''],
    });

    const rules = {
      required: value => !!value || 'Povinné pole.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Neplatný e-mail.';
      },
      phoneNumber: value => {
        const pattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        return pattern.test(value) || 'Neplatné telefónne číslo.';
      }
    };

    const filteredSchools = computed(() => {
      return props.schools.filter(school => school !== 'Všetky školy');
    });


    const noDataText = ref('Začnite písať pre vyhľadávanie');

    const handleSearch = debounce(async (value) => {
      if (value && value.length >= 3) {
        isSearchingParent.value = true;
        noDataText.value = 'Vyhľadávanie...';
        try {
          const results = await Services.searchParentByEmail(value);
          console.log('Parent search results:', results);
          parentSearchResults.value = [...results]; // Force reactivity
          noDataText.value = results.length === 0 ? 'Rodič nenájdený. Nový rodič bude vytvorený.' : 'Žiadne výsledky';
        } catch (error) {
          console.error('Error searching for parent:', error);
          showSnackbar('Chyba pri vyhľadávaní rodiča', 'error');
          parentSearchResults.value = [];
          noDataText.value = 'Chyba pri vyhľadávaní';
        } finally {
          isSearchingParent.value = false;
        }
      } else {
        parentSearchResults.value = [];
        noDataText.value = 'Začnite písať pre vyhľadávanie';
      }
    }, 300);

    watch(selectedParent, (newValue) => {
      if (newValue) {
        console.log('Selected parent:', newValue);
      }
    });

    const formattedDateOfBirth = computed({
      get: () => {
        if (student.value.date_of_birth) {
          const date = new Date(student.value.date_of_birth);
          return isValid(date) ? format(date, 'dd.MM.yyyy') : '';
        }
        return '';
      },
      set: (value) => {
        if (value && typeof value === 'string') {
          const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
          if (isValid(parsedDate)) {
            student.value.date_of_birth = format(parsedDate, 'yyyy-MM-dd');
          } else {
            student.value.date_of_birth = null;
          }
        } else {
          student.value.date_of_birth = null;
        }
      }
    });

    const addSchool = () => {
      if (student.value.schools.length < filteredSchools.value.length && student.value.studyForm !== 'Denné štúdium') {
        student.value.schools.push('');
        student.value.studyForm = 'Externé štúdium';
      }
    };

    const removeSchool = (index) => {
      student.value.schools.splice(index, 1);
      if (student.value.schools.length === 1) {
        student.value.studyForm = 'Denné štúdium';
      }
    };

    const availableSchools = (currentIndex) => {
      return filteredSchools.value.filter(school =>
        !student.value.schools.includes(school) ||
        student.value.schools[currentIndex] === school
      );
    };

    const debouncedParentSearch = debounce(async (value) => {
      if (value && value.length >= 3) {
        isSearchingParent.value = true;
        try {
          const results = await Services.searchParentByEmail(value);
          parentSearchResults.value = results;
        } catch (error) {
          console.error('Error searching for parent:', error);
          showSnackbar('Chyba pri vyhľadávaní rodiča', 'error');
          parentSearchResults.value = [];
        } finally {
          isSearchingParent.value = false;
        }
      } else {
        parentSearchResults.value = [];
      }
    }, 300);

    const submit = async () => {
      console.log('Submit function called');
      if (form.value.validate()) {
        console.log('Form validated');
        try {
          let parentId;
          if (selectedParent.value !== null) {
            console.log('Selected parent:', selectedParent.value);
            parentId = selectedParent.value.id;
          } else {
            console.log('Creating new parent');
            const parentData = {
              first_name: newParent.value.firstName,
              last_name: newParent.value.lastName,
              email: newParent.value.email,
              phone_number: newParent.value.phoneNumber,
              hashed_password: '1234',
              user_type: 'parent',
            };
            console.log('Parent data:', parentData);
            const newParentResponse = await Services.registerParent(parentData);
            console.log('New parent response:', newParentResponse);
            parentId = await Services.getUserIdByEmail(newParent.value.email);
          }
          console.log('Parent ID:', parentId);

          console.log('Getting school IDs');
          const schoolIds = await Promise.all(
            student.value.schools.map(async (school) => {
              if (school) {
                const response = await Services.getSchoolIdByName(school);
                console.log(`School ID for ${school}:`, response);
                return response;
              }
              return null;
            })
          );

          student.value.schools = schoolIds.filter(id => id !== null);
          console.log('Filtered school IDs:', student.value.schools);

          if (student.value.date_of_birth instanceof Date) {
            student.value.date_of_birth = student.value.date_of_birth.toISOString().split('T')[0];
          }
          console.log('Student data before adding:', student.value);
          const newStudent = await Services.addStudent(student.value);
          console.log('New student added:', newStudent);

          if (parentId) {
            console.log('Assigning child to parent');
            await Services.assignChildToParent(parentId, newStudent.student_id);
            console.log('Child assigned to parent');
          }

          showSnackbar('Študent bol úspešne pridaný', 'success');
          emit('student-added');
          resetForm();
        } catch (error) {
          console.error('Error adding student:', error);
          showSnackbar('Chyba pri pridávaní študenta: ' + error.message, 'error');
        }
      } else {
        console.log('Form validation failed');
      }
    };

    const resetForm = () => {
      if (form.value) {
        form.value.reset();
      }
      student.value = {
        user: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          hashed_password: '1234',
          user_type: 'not_specified',
        },
        date_of_birth: null,
        classroom: '',
        studyForm: 'Denné štúdium',
        schools: [''],
      };
      selectedParent.value = null;
      parentSearch.value = '';
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      menu,
      student,
      rules,
      filteredSchools,
      formattedDateOfBirth,
      addSchool,
      removeSchool,
      availableSchools,
      submit,
      mobile,
      parentSearch,
      selectedParent,
      parentSearchResults,
      isSearchingParent,
      debouncedParentSearch,
      snackbar,
      snackbarText,
      snackbarColor,
      showSnackbar,
      noDataText,
      handleSearch,
      newParent

    };
  },
};
</script>


<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important;
  /* Fialová s 50% priehľadnosťou */
  background-color: transparent !important;
  opacity: 1 !important;
}

.custom-button {
  width: 100%;
  max-width: 200px;
}

.v-btn-toggle {
  display: flex;
  justify-content: center;
}

.v-btn-toggle .v-btn {
  flex: 1;
}

.active-btn {
  background-color: purple !important;
  color: white !important;
}

.v-btn-toggle .v-btn.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  background-color: transparent !important;
}

.v-btn.text-button:disabled {
  opacity: 0.5;
  background-color: transparent !important;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
}

.text-button {
  color: purple;
}

.active-btn {
  background-color: purple !important;
  color: white !important;
}

.v-btn-toggle .v-btn.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  background-color: transparent !important;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>
