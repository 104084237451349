<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :temporary="mdAndDown"
      :permanent="lgAndUp"
      width="256"
    >
      <SidebarNavigation />
    </v-navigation-drawer>

    <div :class="['content-wrapper', { 'with-sidebar': lgAndUp }]">
      <v-app-bar color="white" flat dense class="app-bar">
        <v-toolbar-title class="text-h6 font-weight-bold d-flex align-center">
          <v-btn icon @click="goBack" v-if="showDetails">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn icon @click="toggleDrawer" v-else-if="mdAndDown">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <span class="ml-2 toolbar">{{ pageTitle }}</span>
        </v-toolbar-title>
      </v-app-bar>
      
      <v-main class="main-content">
        <div class="content-area">
          <slot></slot>
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import SidebarNavigation from '@/components/SidebarNavigation.vue'
import { computed, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

export default {
  name: 'BaseLayout',
  components: {
    SidebarNavigation
  },
  props: {
    showDetails: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { mdAndDown } = useDisplay()
    const { lgAndUp } = useDisplay()
    const route = useRoute()
    const store = useStore()
    const drawer = ref(!mdAndDown.value)

    const userType = computed(() => store.getters.getUserType)

    const handleLogout = () => {
      store.dispatch('user/logout')
    }

    const currentYear = computed(() => new Date().getFullYear())

    const adminPageTitles = {
      '/home': 'Domov',
      '/applicants': 'Uchádzači',
      '/orders': 'Objednavky',
      '/meals': 'Obedy',
      '/suppliers': 'Dodavatelia',
      '/documents': 'Dokumenty a Maily',
      '/users': 'Správa uživateľov',
      '/settings': 'Nastavenia',
      '/payments': 'Platby',
      '/profile': 'Profil'
    }

    const parentPageTitles = {
      '/home': 'Domov',
      '/meals': 'Obedy',
      '/documents': 'Dokumenty',
      '/payments': 'Platby',
      '/profile': 'Profil'
    }

    const supplierTitles = {
      '/home': 'Objednávky',
      '/meals': 'Menu',
      '/settings': 'Nastavenia',
      '/profile': 'Profil'
    }

    const path = computed(() => route.path)
    const pageTitle = computed(() => {
      if (props.showDetails) {
        return 'Informácie o škole'
      } else if (userType.value === 'admin') {
        return adminPageTitles[path.value] || 'Domov'
      } else if (userType.value === 'parent') {
        return parentPageTitles[path.value] || 'Domov'
      } else if (userType.value === 'supplier') {
        return supplierTitles[path.value] || 'Domov'
      } else {
        return 'Domov'
      }
    })

    const goBack = () => {
      emit('update:showDetails', false)
    }

    const toggleDrawer = () => {
      if (mdAndDown.value) {
        drawer.value = !drawer.value
      }
    }

    // Watch for changes in screen size and adjust drawer accordingly
    watch(mdAndDown, (newValue) => {
      drawer.value = !newValue
    })

    // Initialize drawer state on component mount
    onMounted(() => {
      drawer.value = !mdAndDown.value
    })

    return {
      handleLogout,
      currentYear,
      pageTitle,
      goBack,
      drawer,
      toggleDrawer,
      mdAndDown,
      lgAndUp
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.app-bar {
  position: sticky;
  top: 0;
  z-index: 99;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-area {
  padding: 0px 24px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto 0 0; /* Align content area to the left */
  height: calc(100vh - 64px); /* Adjust 64px if your app bar has a different height */
}

.toolbar {
  padding-left: 8px;
}

@media (min-width: 1920px) {
  .content-area {
    max-width: 1920px; /* Increase the max-width for larger screens */
    height: calc(100vh - 64px); /* Adjust height for larger screens */
  }
}

@media (min-width: 2560px) {
  .content-area {
    max-width: 2560px; /* Increase the max-width for 2K screens */
    height: calc(100vh - 64px); /* Adjust height for 2K screens */
  }
}

@media (min-width: 3840px) {
  .content-area {
    max-width: 3840px; /* Increase the max-width for 4K screens */
    height: calc(100vh - 64px); /* Adjust height for 4K screens */
  }
}

@media (max-width: 1264px) {
  .content-area {
    padding: 8px 16px;
  }
}

@media (max-width: 600px) {
  .content-area {
    padding: 6px 12px;
    height: calc(100vh - 56px); /* Adjust if your mobile app bar has a different height */
  }
}
</style>

<style>
html {
  overflow-y: auto !important;
}

body {
  overflow-y: auto !important;
}
</style>