<template>
  <v-col cols="12" md="6" lg="5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        v-model="selectedSchoolName"
        :items="filteredSchools"
        label="Škola"
        variant="underlined"
        required
        :rules="[rules.required]"
        @update:model-value="onSchoolChange"
      ></v-select>

      <v-alert
        v-if="existingManager"
        type="info"
        color="red"
        class="mb-4"
      >
        Manažér pre túto školu už existuje: {{ existingManager.first_name }} {{ existingManager.last_name }}
      </v-alert>

      <v-autocomplete
        v-model="selectedEmployee"
        :items="employeeOptions"
        :loading="loadingEmployees"
        v-model:search-input="employeeSearch"
        item-title="full_name"
        item-value="id"
        label="Vyhľadať zamestnanca"
        variant="underlined"
        required
        :rules="[rules.required]"
        :disabled="!selectedSchoolId"
        @update:search="onEmployeeSearch"
        return-object
      >
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="item.raw.full_name" :subtitle="item.raw.email"></v-list-item>
        </template>
      </v-autocomplete>

      <v-row class="pt-4">
        <v-col cols="12" class="d-flex justify-start">
          <v-btn 
            variant="outlined" 
            @click="submitManager" 
            :disabled="!valid || !selectedEmployee"
          >
            {{ existingManager ? 'Aktualizovať manažéra' : 'Pridať manažéra' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { ref, watch, computed } from 'vue';
import Services from '@/api/services';

export default {
  props: {
    schools: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const selectedSchoolName = ref(null);
    const selectedSchoolId = ref(null);
    const employeeSearch = ref('');
    const selectedEmployee = ref(null);
    const loadingEmployees = ref(false);
    const existingManager = ref(null);
    const employeeOptions = ref([]);

    const filteredSchools = computed(() => {
      return props.schools.filter(school => school !== 'Všetky školy');
    });

    const rules = {
      required: value => !!value || 'Toto pole je povinné',
    };

    const onSchoolChange = async (schoolName) => {
      employeeSearch.value = '';
      selectedEmployee.value = null;
      existingManager.value = null;
      selectedSchoolId.value = null;
      employeeOptions.value = [];

      if (schoolName) {
        try {
          const response = await Services.getSchoolByName(schoolName);
          selectedSchoolId.value = response.id;
          const managerResponse = await Services.getSchoolManager(selectedSchoolId.value);
          existingManager.value = managerResponse;
        } catch (error) {
          console.error('Error fetching school or existing manager:', error);
          showSnackbar('Chyba pri načítaní školy alebo existujúceho manažéra.', 'error');
        }
      }
    };

    const onEmployeeSearch = async (searchValue) => {
      if (searchValue && selectedSchoolId.value) {
        loadingEmployees.value = true;
        try {
          const response = await Services.searchEmployeesBySchool(selectedSchoolId.value, searchValue);
          employeeOptions.value = response.map(employee => ({
            ...employee,
            full_name: `${employee.first_name} ${employee.last_name}`
          }));
        } catch (error) {
          console.error('Error searching employees:', error);
          showSnackbar('Chyba pri vyhľadávaní zamestnancov.', 'error');
          employeeOptions.value = [];
        } finally {
          loadingEmployees.value = false;
        }
      } else {
        employeeOptions.value = [];
      }
    };

    const submitManager = async () => {
      if (form.value.validate() && selectedEmployee.value) {
        try {
          const schoolData = {
            manager: selectedEmployee.value.id
          };
          
          const response = await Services.updateSchool(selectedSchoolId.value, schoolData);
          console.log('Manager updated', response);
          showSnackbar('Manažér bol úspešne pridaný/aktualizovaný.', 'success');
          emit('manager-added');
          resetForm();
        } catch (error) {
          showSnackbar('Chyba pri pridávaní/aktualizácii manažéra. Skúste to znova.', 'error');
          console.error('Error adding/updating manager:', error);
        }
      }
    };

    const resetForm = () => {
      selectedSchoolName.value = null;
      selectedSchoolId.value = null;
      employeeSearch.value = '';
      selectedEmployee.value = null;
      existingManager.value = null;
      employeeOptions.value = [];
      form.value.resetValidation();
    };

    watch(employeeSearch, () => {
      if (!employeeSearch.value) {
        selectedEmployee.value = null;
      }
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      rules,
      selectedSchoolName,
      selectedSchoolId,
      employeeSearch,
      selectedEmployee,
      loadingEmployees,
      existingManager,
      employeeOptions,
      onSchoolChange,
      onEmployeeSearch,
      submitManager,
      filteredSchools,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  }
};
</script>



<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple; /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px; /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5; /* Adjust line-height to ensure the text is centered */
  display: inline-block; /* Ensure the button is displayed as an inline element */
}
</style>