<template>
    <div>
      
    </div>
  </template>
    
    <script>
  
    import { useStore } from 'vuex'
    import { computed } from 'vue'
    
    export default {
      name: 'ManagerHome',
      setup() {
        const store = useStore()
    
        const isManager = computed(() => {
          if (store.getters.isAuthenticated == true && store.getters.getUserType === 'manager') {
              return true
          } else {
              return false
        }
        })
    
        return {
          isManager
        }
      }
    }
    </script>