<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-card flat>
          <v-card-text class="card-text-custom">
            <v-form ref="form" v-model="valid" class="form-container">
              <v-text-field
                v-model="localSchool.name"
                variant="underlined"
                label="Škola"
                class="input-field"
                :rules="[(v) => !!v || 'Mená školy sú povinné']"
              ></v-text-field>
              <v-text-field
                v-model="localSchool.address"
                variant="underlined"
                label="Adresa"
                class="input-field"
                :rules="[(v) => !!v || 'Adresa je povinná']"
              ></v-text-field>
              <v-text-field
                v-model="localSchool.email"
                variant="underlined"
                label="Email"
                class="input-field"
                :rules="[
                  (v) => !!v || 'Email je povinný',
                  (v) => /.+@.+\..+/.test(v) || 'Email musí byť platný',
                ]"
              ></v-text-field>
              <v-text-field
                v-model="localSchool.phone_number"
                variant="underlined"
                label="Telefónne číslo"
                class="input-field"
                :rules="[(v) => !!v || 'Telefónne číslo je povinné']"
              ></v-text-field>
              <v-select
                v-model="localSchool.manager"
                :items="managersNames"
                variant="underlined"
                :label="localSchool.manager && localSchool.manager !== 'Not specified' ? localSchool.manager : 'Vyberte manažéra'"
                required
                :rules="[(v) => !!v || 'Manažér je povinný']"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions class="card-actions-custom">
            <v-btn variant="outlined" @click="save" :loading="isLoading" class="submit-btn">Uložiť</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
  


<script>
import { ref, onMounted } from "vue";
import { computed } from "vue";
import Services from "@/api/services";

export default {
  props: {
    school: Object,
    managers: Array,
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const managersNames = ref([]);
    const managedSchools = ref([]);
    const isLoading = ref(false);
    const localSchool = ref({
      id: props.school.id,
      name: props.school.name,
      address: props.school.address,
      email: props.school.contact_email,
      phone_number: props.school.contact_phone,
      manager: props.school.manager_name && props.school.manager_name !== "Not specified" ? props.school.manager_name : "",

    });

    const save = async () => {
  if (form.value.validate()) {
    try {
      isLoading.value = true;
      const schoolData = {
        id: localSchool.value.id,
        name: localSchool.value.name,
        address: localSchool.value.address,
        email: localSchool.value.email,
        phone_number: localSchool.value.phone_number,
        manager: props.managers.find(
          (manager) => manager.first_name + " " + manager.last_name === localSchool.value.manager
        ).id,
      };

      // Call the updateSchool service
      const response = await Services.updateSchool(localSchool.value.id,schoolData);
      console.log("RESPONSE", response);
      if (response.status === 200) {
        emit("save", response.data);
        showSnackbar("Škola bola úspešne aktualizovaná", "success");
      } else {
        // Handle any errors returned from the service
        showSnackbar("Školu sa nepodarilo aktualizovať", "error");
        throw new Error(response.message || "Failed to update school information");
      }
    } catch (error) {
      console.error("Error updating school:", error);
      showSnackbar("Chyba pri aktualizácii školy: " + error.message, "error");
    } finally {
      isLoading.value = false;
    }
  }
};

    const filteredSchools = computed(() => {
      return props.allSchools.filter((school) => school.id !== props.school.id);
    });

    const availableSchools = (currentIndex) => {
      return filteredSchools.value.filter(school => 
        !managedSchools.value.includes(school.id) || 
        managedSchools.value[currentIndex] === school.id
      );
    };

    const addSchool = () => {
      if (managedSchools.value.length < filteredSchools.value.length) {
        managedSchools.value.push(null);
      }
    };

    const removeSchool = (index) => {
      managedSchools.value.splice(index, 1);
    };

    const updateManagedSchool = (index, newValue) => {
      managedSchools.value[index] = newValue;
    };

    onMounted(() => {
      console.log("LOCAL SCHOOL", localSchool.value);
      console.log("MANAGERS", props.managers);
      managersNames.value = props.managers.map(
        (manager) => manager.first_name + " " + manager.last_name
      );
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      localSchool,
      save,
      managersNames,
      managedSchools,
      filteredSchools,
      availableSchools,
      addSchool,
      removeSchool,
      updateManagedSchool,
      snackbar,
      snackbarText,
      snackbarColor,
      isLoading
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important; /* Fialová s 50% priehľadnosťou */
  background-color: transparent !important;
  opacity: 1 !important;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.card-text-custom {
  padding-bottom: 0px !important;
}

.card-actions-custom {
  padding-left: 12px !important; /* Align with text fields, adjust as needed */
  padding-top: 0px !important; /* Reduce top padding */
  margin-top: -12px !important; /* Pull the button up a bit */
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
  margin-left: 0px !important; /* Remove any default left margin */
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>