<template>
    <BaseLayout>
      <div>
        
      </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
export default {
    name: 'OrdersView',
    components: {
      BaseLayout,
    },
}
</script>