<template>
  <v-container fluid class="pa-0">
    <div v-if="!showSupplierDetails">
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-title>
              <v-container fluid class="pa-0">
                <v-row align="center" no-gutters>
                  <v-col cols="auto" v-if="showAddSupplierForm">
                    <v-btn icon @click="handleGoBack" class="mr-2">
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col :cols="showAddSupplierForm ? 9 : 12" :sm="activeTab === 2 ? 8 : 12"
                    :md="activeTab === 2 ? 9 : 12">
                    <v-tabs v-model="activeTab" :grow="$vuetify.display.smAndDown"
                      :centered="!$vuetify.display.smAndDown" dense>
                      <v-tab>Stravníci</v-tab>
                      <v-tab>Ponuka jedál</v-tab>
                      <v-tab>Dodávatelia</v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col v-if="activeTab === 2" cols="12" sm="4" md="3"
                    class="d-flex justify-center justify-sm-end mt-2 mt-sm-0">
                    <v-btn v-if="!showAddSupplierForm" small variant="outlined" :block="smAndDown" @click="handleAdd">
                      Pridať dodávateľa
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text v-if="!showAddSupplierForm">
              <div class="d-flex justify-space-between align-center mb-4">
                <v-row align="center" justify="space-between" class="w-100">
                  <v-col :cols="activeTab == 1 || activeTab == 2 ? 12 : 10" :sm="activeTab == 1 || activeTab == 2 ? 12 : 10"
                    :md="activeTab == 0 ? 11 : activeTab == 1 ? 6 : 12"
                    :lg="activeTab == 0 ? 11 : activeTab == 1 ? 6 : 12">
                    <v-select v-model="selectedSchool" :items="schoolsNames" label="Škola" outlined dense variant="solo"
                      density="compact" hide-details item-text="school" item-value="id" return-object :block="mobile"></v-select>
                  </v-col>
                  <v-col v-if="activeTab == 1" cols="12" sm="12" md="6">
                    <v-select v-model="selectedWeek" :items="weeks" label="Vyberte týždeň" outlined dense variant="solo"
                      density="compact" hide-details></v-select>
                  </v-col>
                  <v-col cols="2" sm="1" class="text-right d-flex align-center justify-end" v-if="activeTab !== 2">
                    <v-menu anchor="bottom end" rounded offset-y max-height="400" max-width="400" density="compact"
                      :close-on-content-click="false">
                      <template v-if="activeTab !== 1 && activeTab !== 2" v-slot:activator="{ props }">
                        <v-btn icon v-bind="props" class="admin-home-btn">
                          <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>

                      </template>
                      <v-card width="auto" class="admin-home-menu">
                        <v-list density="compact">
                          <v-list-item v-for="filter in filters" :key="filter.value" class="d-flex align-center">
                            <v-checkbox :model-value="selectedFilters.includes(filter.value)
                              " @update:model-value="toggleFilter(filter.value)" hide-details
                              class="d-flex align-center" density="compact">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  {{ filter.text }}
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <v-window disabled v-model="activeTab">
                <v-window-item :value="0">
                  <v-data-table-server :headers="headers" :items="diners" :loading="isLoading"
                    v-model:items-per-page="itemsPerPage" :page="page" :items-length="totalItems"
                    @update:page="handlePageChange" @update:items-per-page="handleItemsPerPage"
                    :hide-default-header="smAndDown">

                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.supplier="{ item }">
                      <template v-if="item.supplier === 'Nešpecifikované'">
                        <v-chip color="red" text-color="white">
                          {{ item.supplier }}
                        </v-chip>
                      </template>
                      <template v-else>
                        <div style="max-width: 130px;">{{ item.supplier }}</div>
                      </template>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <!-- <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="handleAction(item)">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template> -->
                  </v-data-table-server>
                </v-window-item>
                <v-window-item :value="1">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <div v-if="!isSchoolSelected" class="text-center">
                          <v-text class="text-h6 font-weight-bold">
                            Vyberte si školu pre zobrazenie jedálneho lístka
                          </v-text>
                        </div>
                        <div v-else-if="isLoadingMeals" class="text-center">
                          <v-progress-circular indeterminate color="purple"></v-progress-circular>
                        </div>
                        <div v-else-if="isMealsEmpty" class="text-center">
                          <v-alert color="purple" icon="mdi-information" border="left" prominent text class="mx-auto"
                            max-width="500">
                            <span class="text-h8 font-weight-bold">
                              Pre zvolený týždeň nie sú dostupné žiadne jedlá
                            </span>
                          </v-alert>
                        </div>
                        <template v-else-if="isSchoolSelected && !isMealsEmpty">
                          <v-card v-for="(dayMeals, day) in groupedMeals" :key="day" class="mb-6">
                            <v-card-title class="text-h6">
                              {{ day }}
                            </v-card-title>
                            <v-card-text>
                              <v-simple-table :loading="isLoadingMeals" class="hidden-sm-and-down">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left meal-column">Jedlo</th>
                                      <th class="text-left description-column">Popis</th>
                                      <th class="text-left allergens-column">Alergény</th>
                                      <th class="text-right price-column">Cena od dodávateľa</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(meal, index) in dayMeals" :key="meal.id">
                                      <tr class="meal-row">
                                        <td class="meal-column">{{ meal.name }}</td>
                                        <td class="description-column">{{ meal.description }}</td>
                                        <td class="allergens-column">
                                          <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small
                                            class="mr-1 mb-1" color="purple" text-color="white">
                                            {{ allergen.name }}
                                          </v-chip>
                                        </td>
                                        <td class="text-right price-column">{{ formatCurrency(meal.price_supplier) }}
                                        </td>
                                      </tr>
                                      <tr v-if="index < dayMeals.length - 1" class="spacer-row">
                                        <td colspan="5"></td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </template>
                              </v-simple-table>

                              <!-- Mobile version -->
                              <v-list :loading="isLoadingMeals" class="hidden-md-and-up">
                                <template v-for="(meal, index) in dayMeals" :key="meal.id">
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title class="font-weight-bold">{{ meal.name }}</v-list-item-title>
                                      <v-list-item-subtitle>{{ meal.description }}</v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small
                                          class="mr-1 mb-1" color="purple" text-color="white">
                                          {{ allergen.name }}
                                        </v-chip>
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle class="text-right">
                                        Cena: {{ formatCurrency(meal.price_supplier) }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider v-if="index < dayMeals.length - 1"></v-divider>
                                </template>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item :value="2">
                  <v-data-table-server :headers="headers" :items="diners" :loading="isLoading"
                    v-model:items-per-page="itemsPerPage" :page="page" :items-length="totalItems"
                    @update:page="handlePageChange" @update:items-per-page="handleItemsPerPage"
                    item-class="text-truncate" :class="{ 'mobile-table': smAndDown }">
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.schools="{ item }">
                      <div v-if="item.schools && item.schools != ''" class="text-truncate" style="max-width: 200px;">
                        {{ item.schools }}
                      </div>
                      <v-chip v-else color="red" text-color="white" small>
                        Nešpecifikovaná
                      </v-chip>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                      <v-btn text @click="handleAction(item)">
                        Viac
                      </v-btn>
                    </template>
                  </v-data-table-server>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="showSupplierDetails">
      <SupplierDetails :supplier="selectedSupplier" @go-back="handleGoBack" @supplier-updated="refetchSuppliers" />
    </div>
    <div v-else-if="showAddSupplierForm">
      <SupplierForm :schools="schoolsNames" @supplier-added="handleSupplierAdded" @cancel="handleCancelAdd" />
    </div>
  </v-container>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import Services from "@/api/services";
import moment from "moment";
import SupplierDetails from "@/components/SupplierDetail.vue";
import { debounce } from "lodash";
import SupplierForm from "@/components/SupplierForm.vue";
import "moment/locale/sk";
import { useDisplay } from "vuetify";

export default {
  emits: ["supplier-added"],
  components: {
    SupplierDetails,
    SupplierForm,
  },
  setup() {
    moment.locale("sk");
    const diners = ref([]);
    const isLoading = ref(false);
    const activeTab = ref(0);
    const filterMenu = ref(false);
    const selectedFilters = ref([]);
    const selectedSchool = ref("Všetky školy");
    const schools = ref([]);
    const selectedSupplier = ref(null);
    const showSupplierDetails = ref(false);
    const showAddSupplierForm = ref(false);
    const filters = [
      { text: "Žiaci", value: "student" },
      { text: "Zamestnanci", value: "employee" },
      { text: "Externý Stravníci", value: "external_diner" },
    ];
    const { mobile } = useDisplay();
    const { smAndDown } = useDisplay()
    const headers = ref([]);
    const page = ref(1);
    const itemsPerPage = ref(10);
    const totalItems = ref(0);
    const totalPages = ref(0);

    const selectedWeek = ref(0);
    const weeks = ref([]);
    const schoolsNames = ref([]);
    const meals = ref({});
    const isLoadingMeals = ref(false);

    const handleAdd = () => {
      showAddSupplierForm.value = true;
    };

    const handlePageChange = (value) => {
      page.value = value;
      fetchDiners();
    };

    const handleItemsPerPage = (value) => {
      page.value = totalItems.value > value ? 1 : page.value;
      itemsPerPage.value = value;
      fetchDiners();
    };

    const refetchSuppliers = () => {
      fetchDiners();
    };

    const handleSupplierAdded = async () => {
      showSnackbar("Dodavatel bol úspešne pridaný!", "success");
      showAddSupplierForm.value = false;
      await fetchDiners();
    };

    const handleCancelAdd = () => {
      showAddSupplierForm.value = false;
    };

    const fetchDiners = debounce(async () => {
      isLoading.value = true;
      try {
        let response;
        let schoolId =
          selectedSchool.value === "Všetky školy" ? null : selectedSchool.value;

        if (activeTab.value === 0) {
          console.log(
            itemsPerPage.value,
            page.value,
            selectedFilters.value,
            schoolId
          );
          schoolId =
            selectedSchool.value === "Všetky školy"
              ? null
              : await Services.getSchoolIdByName(selectedSchool.value);
          console.log("Fetching diners", schoolId);
          response = await Services.getDiners(
            selectedFilters.value,
            schoolId,
            page.value,
            itemsPerPage.value
          );
          headers.value = [
            { title: "Meno Stravníka", key: "name", sortable: false },
            { title: "Škola", key: "school", sortable: false },
            { title: "Meno dodávatela", key: "supplier", sortable: false },
            {
              title: "Kredity",
              align: "center",
              key: "credits",
              sortable: false,
            },
            { key: "actions", sortable: false },
          ];
          console.log("Diners:", response);

          // Fetch credits for each diner
          diners.value = await Promise.all(
            response.items.map(async (diner) => {
              let dinerCredits = 0;
              try {
                if (diner.user_type === "student" && diner.parent_id) {
                  const parentCredits = await Services.getUserCredits(
                    diner.parent_id
                  );
                  dinerCredits = parentCredits.reduce(
                    (total, credit) => total + credit.amount,
                    0
                  );
                } else if (diner.user_type !== "student") {
                  const userCredits = await Services.getUserCredits(diner.id);
                  dinerCredits = userCredits.reduce(
                    (total, credit) => total + credit.amount,
                    0
                  );
                }
              } catch (error) {
                console.error(
                  `Error fetching credits for diner ${diner.id}:`,
                  error
                );
                // Set credits to 0 for any error
                dinerCredits = 0;
              }

              return {
                name: diner.full_name,
                school: Array.isArray(diner.school_names)
                  ? diner.school_names.join(", ")
                  : diner.school_names,
                supplier: Array.isArray(diner.supplier_names) && diner.supplier_names.length > 0
                  ? diner.supplier_names.join(", ")
                  : "Nešpecifikované",
                credits: dinerCredits.toFixed(2),
                actions: diner.actions,
              };
            })
          );

          totalItems.value = response.total_items;
          totalPages.value = response.total_pages;
        } else if (activeTab.value === 1) {
          isLoadingMeals.value = true;
          if (selectedSchool.value === "Všetky školy") {
            diners.value = [];
            totalItems.value = 0;
            totalPages.value = 0;
            return;
          }
          const week_start = selectedWeek.value.split(" - ")[0];
          response = await Services.getSchoolIdByName(selectedSchool.value);
          schoolId = response;
          const formattedStartDate = moment(week_start, "DD.MM.YYYY").format(
            "YYYY-MM-DD"
          );
          response = await Services.getMealsForSchoolAndWeek(
            schoolId,
            formattedStartDate
          );
          headers.value = [
            { text: "Jedlo", value: "name" },
            { text: "Popis", value: "description", align: "center" },
            { text: "Alergény", value: "allergens", align: "center" },
            { text: "Cena od dodávateľa", value: "price_supplier" },
          ];
          meals.value = response.map((meal) => ({
            id: meal.id,
            date: meal.date,
            name: meal.name,
            description: meal.description,
            allergens: meal.allergens,
            price_supplier: meal.price_supplier,
          }));
          console.log(response);
          totalItems.value = response.length;
          totalPages.value = Math.ceil(totalItems.value / itemsPerPage.value);
          isLoadingMeals.value = false;
        } else if (activeTab.value === 2) {
          schoolId =
            selectedSchool.value === "Všetky školy"
              ? null
              : await Services.getSchoolIdByName(selectedSchool.value);
          console.log("Fetching suppliers", schoolId);
          response = await Services.getSuppliers(
            page.value,
            itemsPerPage.value,
            schoolId
          );
          console.log("Response:", response);
          headers.value = [
            { title: "Dodávateľ", key: "name" },
            { title: "Email", key: "email" },
            { title: "Kontakt", key: "contact_info" },
            { title: "Školy", key: "schools" },
            { title: "Čas objednávky", key: "time_of_sending_order" },
            { key: "actions", sortable: false },
          ];
          console.log("Suppliers:", response);
          diners.value = response.items.map((supplier) => ({
            id: supplier.id,
            name: supplier.name,
            email: supplier.email,
            contact_info: supplier.contact_info,
            schools: supplier.schools.map((school) => school.name).join(", "),
            order_modification_limit: `${supplier.order_modification_limit_hours} hodín`,
            time_of_sending_order: formatTime(supplier.time_of_sending_order),
            actions: supplier,
          }));
          page.value = response.page;
          console.log("Page:", page.value);
          totalItems.value = response.total_items;
          totalPages.value = Math.ceil(totalItems.value / itemsPerPage.value);
        }
      } catch (error) {
        console.error("Error fetching diners:", error);
        showSnackbar("Chyba pri načítaní stravníkov", "error");
      } finally {
        isLoading.value = false;
      }
    }, 300);

    const toggleFilter = (filter) => {
      if (selectedFilters.value.includes(filter)) {
        selectedFilters.value = selectedFilters.value.filter(
          (f) => f !== filter
        );
      } else {
        selectedFilters.value.push(filter);
      }
      fetchDiners();
    };

    // eslint-disable-next-line
    const formatDateTime = (dateTime) => {
      return moment(dateTime).format("DD.MM.YYYY HH:mm");
    };

    const formatTime = (timeString) => {
      // Split the time string by colon
      const [hours, minutes] = timeString.split(":");
      console.log("Hours:", hours);
      // Return the formatted time as "HH:MM"
      return `${hours}:${minutes}`;
    };

    // eslint-disable-next-line
    const calculateUndueCreditAmount = (creditsList) => {
      const currentDate = new Date();
      const currentDateOnly = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      let undueAmount = 0;

      if (!creditsList || creditsList.length === 0) {
        return undueAmount;
      }

      creditsList.forEach((credit) => {
        const dueDate = new Date(credit.credit_due_date);
        const dueDateOnly = new Date(
          dueDate.getFullYear(),
          dueDate.getMonth(),
          dueDate.getDate()
        );

        if (dueDateOnly >= currentDateOnly) {
          undueAmount += credit.amount;
        }
      });

      return undueAmount;
    };

    const isMealsEmpty = computed(() => {
      return (
        groupedMeals.value === undefined ||
        Object.keys(groupedMeals.value).length === 0
      );
    });

    const fetchSchools = async () => {
      try {
        const response = await Services.getSchools();
        console.log("Schools:", response);
        //map to schools name and id
        schools.value = response.map((school) => {
          school.value = {
            school: school.name,
            id: school.id,
          };
        });
        schoolsNames.value = response.map((school) => school.name);
        schoolsNames.value.unshift("Všetky školy");
      } catch (error) {
        console.error("Error fetching schools:", error);
        showSnackbar("Chyba pri načítaní škôl", "error");
      }
    };

    const handleAction = (item) => {
      if (activeTab.value === 2) {
        showSupplierDetails.value = true;
        selectedSupplier.value = item.actions;
      }
    };

    const isSchoolSelected = computed(() => {
      return (
        selectedSchool.value !== "Všetky školy" && selectedSchool.value !== null
      );
    });

    const generateWeeks = () => {
      const currentDate = new Date();
      const currentWeek = moment(currentDate).week();
      const currentYear = moment(currentDate).year();

      weeks.value = [];
      for (let i = 0; i < 4; i++) {
        const weekNumber = currentWeek + i;
        const weekStart = moment()
          .year(currentYear)
          .isoWeek(weekNumber)
          .startOf("isoWeek");
        const weekEnd = moment()
          .year(currentYear)
          .isoWeek(weekNumber)
          .endOf("isoWeek");

        weeks.value.push(
          `${weekStart.format("DD.MM.YYYY")} - ${weekEnd.format("DD.MM.YYYY")}`
        );
      }

      // Set the initial selected week
      selectedWeek.value = weeks.value[0];
      console.log("Weeks:", weeks.value);
      console.log("Selected week:", selectedWeek.value);
    };

    const groupedMeals = computed(() => {
      const groupedMeals = {};
      if (meals.value && Array.isArray(meals.value)) {
        meals.value.forEach((meal) => {
          let day = moment(meal.date)
            .format("dddd DD.MM.YYYY")
            .replace(/^\w/, (c) => c.toUpperCase());

          // Special handling for Štvrtok
          if (day.startsWith("štvrtok")) {
            day = "Štvrtok" + day.slice(7);
          }

          if (!groupedMeals[day]) {
            groupedMeals[day] = [];
          }
          groupedMeals[day].push(meal);
        });
      }
      return groupedMeals;
    });

    watch(selectedWeek, () => {
      meals.value = {};
      fetchDiners();
    });

    onMounted(() => {
      fetchSchools();
      fetchDiners();
      generateWeeks();
    });

    watch(activeTab, () => {
      showAddSupplierForm.value = false;
      if (!schoolsNames.value.includes("Všetky školy")) {
        schoolsNames.value.unshift("Všetky školy");
        selectedSchool.value = schoolsNames.value[0];
      }
      diners.value = [];
      console.log("Active tab:", activeTab.value);
      if (activeTab.value === 1) {
        //get rid of vsetky skoly
        schoolsNames.value = schoolsNames.value.filter(
          (school) => school !== "Všetky školy"
        );
        selectedSchool.value = schoolsNames.value[0];
        console.log("Schools:", schoolsNames.value);
      } else {
        if (!schoolsNames.value.includes("Všetky školy")) {
          schoolsNames.value.unshift("Všetky školy");
        }
      }
      fetchDiners();
    });

    watch(selectedSchool, () => {
      meals.value = {};
      diners.value = [];
      fetchDiners();
    });

    const formatCurrency = (value) => {
      return new Intl.NumberFormat("sk-SK", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    };

    const handleGoBack = () => {
      showAddSupplierForm.value = false;
      showSupplierDetails.value = false;
      selectedSupplier.value = null;
    };

    const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');

    const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
    };


    return {
      diners,
      isLoading,
      activeTab,
      filterMenu,
      selectedFilters,
      selectedSchool,
      schools,
      filters,
      headers,
      page,
      itemsPerPage,
      totalItems,
      totalPages,
      fetchDiners,
      toggleFilter,
      handleAction,
      SupplierDetails,
      selectedSupplier,
      selectedWeek,
      weeks,
      schoolsNames,
      generateWeeks,
      groupedMeals,
      formatCurrency,
      isSchoolSelected,
      isMealsEmpty,
      showSupplierDetails,
      handleGoBack,
      showAddSupplierForm,
      handleAdd,
      handleSupplierAdded,
      handleCancelAdd,
      refetchSuppliers,
      handleItemsPerPage,
      handlePageChange,
      isLoadingMeals,
      mobile,
      smAndDown,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.meal-row {
  height: 80px;
}

.spacer-row {
  height: 1px;
  background-color: #f0f0f0;
}

.v-chip {
  margin-bottom: 4px;
}

.v-card-title.primary {
  color: white;
}

.v-simple-table td,
.v-simple-table th {
  padding: 12px 16px;
}

/* Column-specific styles */
.meal-column {
  width: 20%;
}

.description-column {
  width: 41.5%;
}

.allergens-column {
  width: 20%;
}

.price-column {
  width: 6.25%;
}

/* Add word-wrap for long content */
.v-simple-table td {
  word-wrap: break-word;
  max-width: 0;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.mb-4 {
  margin-bottom: 16px;
}

.w-100 {
  width: 100%;
}

.text-right {
  text-align: right;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5;
  /* Adjust line-height to ensure the text is centered */
  display: inline-block;
  /* Ensure the button is displayed as an inline element */
}

.text-wrap {
  white-space: normal !important;
  word-break: break-word !important;
}
</style>