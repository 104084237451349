<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="8" lg="6">
        

        <v-card>
          <v-card-title>Nahrať menu</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="uploadMenu">
              <v-file-input
                v-model="file"
                label="Vyberte súbor s menu (PDF, Excel, alebo obrázok)"
                accept=".pdf,.xlsx,.xls,.jpg,.jpeg,.png"
                @change="handleFileChange"
                :error-messages="fileError"
                outlined
              ></v-file-input>
              <v-btn 
                type="submit" 
                :loading="isUploading" 
                :disabled="!file || isUploading"
              >
                Nahrať menu
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card v-if="extractedMeals.length" class="mt-4">
          <v-card-title>Skontrolujte a upravte extrahované jedlá</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(meal, index) in extractedMeals" :key="index">
                <v-list-item-content>
                  <v-text-field v-model="meal.name" label="Názov jedla"></v-text-field>
                  <v-textarea v-model="meal.description" label="Popis"></v-textarea>
                  <v-text-field 
                    v-model.number="meal.price_supplier" 
                    label="Cena od dodávateľa" 
                    type="number" 
                    step="0.01"
                  ></v-text-field>
                  <v-select
                    v-model="meal.allergens"
                    :items="allergenOptions"
                    label="Alergény"
                    multiple
                    chips
                  ></v-select>
                  <v-date-picker v-model="meal.date" label="Dátum"></v-date-picker>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn 
              @click="submitExtractedMeals" 
              color="success" 
              :loading="isSubmitting" 
              :disabled="isSubmitting"
            >
              Potvrdiť a odoslať
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-alert
          type="info"
          prominent
          border="left"
          colored-border
          color="purple"
          class="mb-4"
        >
          <v-row align="center">
            <v-col class="grow">
              Na tejto funkcionalite sa pracuje a prebieha vývojom.
            </v-col>
          </v-row>
        </v-alert>

    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

  
  <script>
  import { ref, onMounted } from 'vue';
  import Services from "@/api/services";
  
  export default {
    name: 'MenuUploadForm',
    props: {
      supplier: {
        type: Object,
        required: true,
      },
    },
    emits: ['menu-uploaded'],
    setup(props, { emit }) {
      const file = ref(null);
      const fileError = ref('');
      const isUploading = ref(false);
      const isSubmitting = ref(false);
      const extractedMeals = ref([]);
      const allergenOptions = ref([]);
      const showSnackbar = ref(false);
      const snackbarText = ref('');
      const snackbarColor = ref('success');
  
      const handleFileChange = (event) => {
        console.log('File changed:', event.target.files[0]);
        fileError.value = '';
        if (file.value) {
          const allowedTypes = ['.pdf', '.xlsx', '.xls', '.jpg', '.jpeg', '.png'];
          const fileExtension = '.' + file.value.name.split('.').pop().toLowerCase();
          if (!allowedTypes.includes(fileExtension)) {
            fileError.value = 'Neplatný typ súboru. Povolené sú len PDF, Excel a obrázky.';
            file.value = null;
          }
        }
      };
  
      const uploadMenu = async () => {
        if (!file.value) return;
  
        isUploading.value = true;
        try {
          const formData = new FormData();
          formData.append('file', file.value);
          formData.append('supplier_id', props.supplier);
  
          const response = await Services.uploadMenu(formData);
          console.log('Menu uploaded and processed:', response);
          extractedMeals.value = response.meals;
          showSnackbar.value = true;
          snackbarText.value = 'Menu bolo úspešne nahrané a spracované.';
          snackbarColor.value = 'success';
        } catch (error) {
          console.error('Error uploading menu:', error);
          showSnackbar.value = true;
          snackbarText.value = 'Chyba pri nahrávaní menu. Skúste to prosím znova.';
          snackbarColor.value = 'error';
        } finally {
          isUploading.value = false;
        }
      };
  
      const submitExtractedMeals = async () => {
        isSubmitting.value = true;
        try {
          const response = await Services.submitExtractedMeals(extractedMeals.value, props.supplier.id);
          console.log('Extracted meals submitted:', response);
          emit('menu-uploaded');
          showSnackbar.value = true;
          snackbarText.value = 'Jedlá boli úspešne pridané do menu.';
          snackbarColor.value = 'success';
          extractedMeals.value = [];
        } catch (error) {
          console.error('Error submitting extracted meals:', error);
          showSnackbar.value = true;
          snackbarText.value = 'Chyba pri odosielaní jedál. Skúste to prosím znova.';
          snackbarColor.value = 'error';
        } finally {
          isSubmitting.value = false;
        }
      };
  
      onMounted(async () => {
        try {
          const allergens = await Services.getAllergens();
          allergenOptions.value = allergens.map(allergen => ({
            text: `${allergen.code}: ${allergen.name}`,
            value: allergen.id
          }));
        } catch (error) {
          console.error('Error fetching allergens:', error);
        }
      });
  
      return {
        file,
        fileError,
        isUploading,
        isSubmitting,
        extractedMeals,
        allergenOptions,
        showSnackbar,
        snackbarText,
        snackbarColor,
        handleFileChange,
        uploadMenu,
        submitExtractedMeals,
      };
    },
  };
  </script>


<style scoped>
.v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    /* Change the text color to purple */
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px 8px;
    /* Adjust padding to ensure the outline fits the text */
    line-height: 1.5;
    /* Adjust line-height to ensure the text is centered */
    display: inline-block;
    /* Ensure the button is displayed as an inline element */
}
</style>