<template>
    <BaseLayout>
      <div>
        <component :is="userManagementComponent" />
      </div>
    </BaseLayout>
  </template>
  
  <script>
  import BaseLayout from '@/components/BaseLayout.vue'
  import AdminUserManagement from '@/components/AdminUserManagement.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  
  export default {
    name: 'UserManagementView',
    components: {
      BaseLayout,
      AdminUserManagement,
    },
    setup() {
      const store = useStore()
      const userType = computed(() => store.getters.getUserType)
      console.log("kamo som tu")
      const userManagementComponent = computed(() => {
        console.log('i am on the page')
        if (userType.value === 'admin') {
          console.log('Som tu')
          return AdminUserManagement
        }
        return null
      })
  
      return {
        userManagementComponent,
      }
    }
  }
  </script>