<template>
    <BaseLayout>
      <div>
        <div>
        <component :is="documentComponent" />
      </div>
      </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import ParentDocumentComponentVue from '@/components/ParentDocumentComponent.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import NotAvailableComponent from '@/components/NotAvailableComponent.vue'
import AdminDocumentComponentVue from '@/components/AdminDocumentsAndMailsComponent.vue'
export default {
    name: 'HomeView',
    components: {
      BaseLayout,
      ParentDocumentComponentVue,
      NotAvailableComponent,
      AdminDocumentComponentVue
    },
    setup() {
      const store = useStore()
      const userType = computed(() => store.getters.getUserType)
        
      
      const documentComponent = computed(() => {
        console.log("DocumentsView", userType.value)
        if (userType.value === 'parent') {
          return ParentDocumentComponentVue
        } else if (userType.value === 'admin') {
          return AdminDocumentComponentVue
        }else {
          return NotAvailableComponent
        } 
      })
  
      console.log(documentComponent.value)
  
      return {
        documentComponent,
      }
    }
}
</script>