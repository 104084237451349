import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { loadFonts } from './plugins/webfontloader'

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { sk } from 'vuetify/locale'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi },
  },
  locale: {
    locale: 'sk',
    fallback: 'en',
    messages: { sk },
  },
  theme: {
    dark: false,
  }
})

loadFonts()
const app = createApp(App)

app.use(router)
app.use(store)
app.use(vuetify)

app.mount('#app')