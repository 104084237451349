<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-form ref="form" v-model="valid" lazy-validation class="form-container">
          <v-text-field
            v-model="supplier.name"
            :rules="[rules.required]"
            label="Názov dodávateľa"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          <v-text-field
            v-model="supplier.email"
            :rules="[rules.required, rules.email]"
            label="Email"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          <v-text-field
            v-model="supplier.contact_info"
            :rules="[rules.required]"
            label="Telefónne číslo"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          <v-text-field
            v-model="supplier.address"
            :rules="[rules.required]"
            label="Adresa"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          
          <v-menu
            v-model="timeOfSendingOrderMenu"
            :close-on-content-click="false"
            @v-click-outside="timeOfSendingOrderMenu = false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="supplier.time_of_sending_order"
                label="Čas odoslania objednávky"
                readonly
                density="compact"
                v-bind="props"
                :rules="[rules.required]"
                variant="underlined"
                class="input-field"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timeOfSendingOrderMenu"
              v-model="supplier.time_of_sending_order"
              format="24hr"
              @update:model-value="timeOfSendingOrderMenu = false"
            >
            <template v-slot:actions>
              <v-btn
                text
                color="purple"
                @click="timeOfSendingOrderMenu = false"
              >
                Zatvoriť
              </v-btn>
            </template></v-time-picker>
          </v-menu>

          <v-menu
            v-model="orderModificationLimitMenu"
            :close-on-content-click="false"
            @v-click-outside="orderModificationLimitMenu = false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="supplier.order_modification_limit_time"
                label="Limit úpravy objednávky"
                readonly
                density="compact"
                v-bind="props"
                :rules="[rules.required]"
                variant="underlined"
                class="input-field"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="orderModificationLimitMenu"
              v-model="supplier.order_modification_limit_time"
              format="24hr"
              @update:model-value="orderModificationLimitMenu = false"
            >
            <template v-slot:actions>
              <v-btn
                text
                color="purple"
                @click="orderModificationLimitMenu = false"
              >
                Zatvoriť
              </v-btn>
            </template></v-time-picker>
          </v-menu>

          <v-card-title class="px-0">Posielať objednávku emailom</v-card-title>
          <v-btn-toggle
            v-model="supplier.send_order_by_email"
            mandatory
            class="mb-4 input-field"
          >
            <v-btn :value="true">
              Áno
            </v-btn>
            <v-btn :value="false">
              Nie
            </v-btn>
          </v-btn-toggle>

          <div v-for="(school, index) in supplier.schools" :key="index" class="d-flex align-center input-field">
            <v-select
              v-model="supplier.schools[index]"
              :items="availableSchools(index)"
              variant="underlined"
              label="Škola"
              required
              :rules="[rules.required]"
            ></v-select>
            <v-btn icon @click="removeSchool(index)" v-if="supplier.schools.length > 1">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </div>
          
          <v-btn 
            text 
            @click="addSchool" 
            :disabled="supplier.schools.length >= filteredSchools.length"
            class="text-button mb-4"
          >
            + Pridať školu
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            variant="outlined" 
            @click="submit" 
            :disabled="!valid"
            color="purple"
            class="submit-btn"
          >
            Pridať dodávateľa
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import Services from '@/api/services';
import { VTimePicker } from 'vuetify/labs/VTimePicker';


export default {
  components: {
    VTimePicker,
  },
  props: {
    schools: {
      type: Array,
      required: true,
    },
  },
  emits: ['supplier-added'],
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const timeOfSendingOrderMenu = ref(false);
    const orderModificationLimitMenu = ref(false);
    const supplier = ref({
      name: '',
      email: '',
      contact_info: '',
      address: '',
      time_of_sending_order: null,
      order_modification_limit_time: null,
      send_order_by_email: false,
      schools: [''],
    });

    const rules = {
      required: value => !!value || 'Povinné pole.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Neplatný e-mail.';
      },
    };

    const filteredSchools = computed(() => {
      return props.schools.filter(school => school !== 'Všetky školy');
    });

    const addSchool = () => {
      if (supplier.value.schools.length < filteredSchools.value.length) {
        supplier.value.schools.push('');
      }
    };

    const removeSchool = (index) => {
      supplier.value.schools.splice(index, 1);
    };

    const availableSchools = (currentIndex) => {
      return filteredSchools.value.filter(school => 
        !supplier.value.schools.includes(school) || 
        supplier.value.schools[currentIndex] === school
      );
    };

    const submit = async () => {
      if (form.value.validate()) {
        try {
          const supplierData = {
            ...supplier.value,
            schools: supplier.value.schools.filter(school => school !== ''),
          };

          const newSupplier = await Services.addSupplier(supplierData);
          emit('supplier-added', newSupplier);
          showSnackbar('Dodávateľ bol úspešne pridaný', 'success');
          form.value.reset();
          supplier.value = {
            name: '',
            email: '',
            contact_info: '',
            address: '',
            time_of_sending_order: null,
            order_modification_limit_time: null,
            send_order_by_email: false,
            schools: [''],
          };
        } catch (error) {
          console.error('Error adding supplier:', error);
          showSnackbar('Chyba pri pridávaní dodávateľa: ' + error.message, 'error');
        }
      } else {
        showSnackbar('Prosím, vyplňte všetky povinné polia', 'warning');
      }
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      supplier,
      rules,
      filteredSchools,
      addSchool,
      removeSchool,
      availableSchools,
      submit,
      timeOfSendingOrderMenu,
      orderModificationLimitMenu,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-button {
  width: 100%;
  max-width: 200px;
}

.v-btn.text-button:disabled {
  opacity: 0.5;
  background-color: transparent !important;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.submit-btn, .text-button {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
}

.submit-btn {
  margin-left: 0;
}

.text-button {
  padding-left: 0;
  margin-bottom: 16px;
}

.v-btn-toggle {
  width: 100%;
}

.v-btn-toggle .v-btn {
  flex-grow: 1;
}

.v-btn-toggle .v-btn.v-btn--active {
  background-color: purple !important;
  color: white !important;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>
