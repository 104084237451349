<template>
  <div>
    <h1 :class="[$vuetify.display.smAndDown ? 'text-h5' : 'text-h4', 'text-center mb-6 font-weight-bold']">Rodinná Škola</h1>

    <v-card
      class="mx-auto pa-6 pb-8"
      :elevation="$vuetify.display.smAndDown ? 2 : 8"
      :max-width="$vuetify.display.smAndDown ? '100%' : '448'"
      :rounded="$vuetify.display.smAndDown ? 'md' : 'lg'"
    >
      <form @submit.prevent="handleLogin">
        <div class="text-subtitle-1 text-medium-emphasis mb-2">Účet</div>

        <v-text-field
          v-model="email"
          density="compact"
          placeholder="Emailová adresa"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          class="mb-2"
          :error-messages="emailError"
          @input="validateEmail"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between mb-2">
          Heslo
          <v-btn
            text
            @click="forgotPassword"
            class="text-caption text-decoration-none custom-btn"
          >
            Zabudnuté prihlasovacie heslo?
          </v-btn>
        </div>

        <v-text-field
          v-model="password"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Zadajte svoje heslo"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          class="mb-4"
          @click:append-inner="visible = !visible"
        ></v-text-field>

        <v-card
          v-if="errorMessage"
          class="mb-4"
          color="red lighten-5"
          variant="tonal"
        >
          <v-card-text class="text-caption">
            {{ errorMessage }}
          </v-card-text>
        </v-card>

        <v-btn
          type="submit"
          class="mb-4 custom-btn"
          :disabled="!isFormValid || isLoading"
          :loading="isLoading"
          :size="$vuetify.display.smAndDown ? 'x-large' : 'large'"
          variant="outlined"
          block
        >
          Prihlásiť sa
        </v-btn>
      </form>

      <v-card-text class="text-center">
        <v-btn
          text
          class="custom-btn"
          @click="redirectToRegister"
        >
          Zaregistrujte sa teraz <v-icon icon="mdi-chevron-right"></v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const visible = ref(false);
const email = ref('');
const password = ref('');
const errorMessage = ref('');
const emailError = ref('');
const isLoading = ref(false);

const store = useStore();
const router = useRouter();

const isFormValid = computed(() => {
  return email.value.trim() !== '' && password.value.trim() !== '' && emailError.value === '';
});

const validateEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(email.value)) {
    //pass
  } else {
    emailError.value = '';
  }
};

const handleLogin = async (event) => {
  event.preventDefault();
  if (!isFormValid.value || isLoading.value) {
    return;
  }

  isLoading.value = true;
  errorMessage.value = '';

  try {
    await store.dispatch('login', { email: email.value, password: password.value });
    console.log('Logged in successfully');
    console.log(store.getters.isAuthenticated);
    if (store.getters.isAuthenticated) {
      router.push('/home');
    }
  } catch (error) {
    console.log("Detail", error.detail?.error);
    if (error.detail) {
      if(error.detail.includes("invalid_grant")) {
        errorMessage.value = 'Nesprávne prihlasovacie údaje.';
      } else if (error.detail.includes("User not found")) {
        errorMessage.value = 'Nepodarilo sa nájsť používateľa s touto emailovou adresou.';
      } else {
        errorMessage.value = 'Vyskytla sa chyba. Skúste to neskôr.';
      }
    } else {
      errorMessage.value = 'Vyskytla sa chyba. Skúste to neskôr.';
    }
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const forgotPassword = () => {
  router.push({ name: 'ResetPasswordView' });
};

const redirectToRegister = (event) => {
  event.preventDefault();
  router.push({ path: '/register', name: 'RegisterForm' });
};


</script>

<style scoped>
.v-card.sm {
  max-width: 100%;
  padding: 8px;
}

.v-card.md {
  max-width: 448px;
  padding: 12px;
}

.v-card.lg {
  max-width: 448px;
  padding: 12px;
}
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}
</style>