<template>
  <v-container fluid class="p-0">
  <div v-if="isParent">
    <!-- Dialóg na zmenu hesla -->
    <v-dialog v-model="showPasswordDialog" max-width="400" persistent>
      <v-card class="rounded-lg">
        <v-card-title
          class="headline d-flex align-center pa-4 bg-purple text-white"
        >
          <v-icon left class="mr-2">mdi-lock-reset</v-icon>
          Zmena hesla
        </v-card-title>
        <v-card-text class="pa-4 text-body-1">
          <p>Používate dočasné heslo. Prosím, zmeňte si ho pre pokračovanie.</p>
          <v-form @submit.prevent="changePassword">
            <v-text-field
              v-model="newPassword"
              label="Nové heslo"
              type="password"
              required
              class="mt-4"
              :error-messages="newPasswordErrors"
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              label="Potvrďte nové heslo"
              type="password"
              required
              class="mt-4"
              :error-messages="confirmPasswordErrors"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="changePassword"
            :disabled="!isPasswordValid"
            class="text-capitalize"
          >
            Zmeniť heslo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Hlavný obsah (viditeľný iba ak heslo nie je dočasné) -->
    <div v-if="!isTemporaryPassword">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert v-if="notifications.length === 0" type="info" class="mb-4" color="purple">
              Nemáte žiadne notifikácie.
            </v-alert>
          </v-col>
          <v-col
            v-for="notification in notifications"
            :key="notification.id"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="mb-4 d-flex flex-column" height="200">
              <v-card-title>{{ notification.title }}</v-card-title>
              <v-card-text class="flex-grow-1">
                <p>{{ notification.message }}</p>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-between align-center">
                <v-btn
                  v-if="notification.action_text && notification.action_link"
                  :to="notification.action_link"
                  color="primary"
                  text
                  class="ml-0"
                >
                  {{ notification.action_text }}
                </v-btn>
                <v-spacer v-else></v-spacer>
                <v-chip
                  :color="getTypeColor(notification.type)"
                  text-color="white"
                  small
                >
                  {{ notification.type }}
                </v-chip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <div v-else>
    <h1 class="text-center mb-6">Prístup zamietnutý</h1>
    <p class="text-center">
      Musíte byť prihlásený ako rodič, aby ste mohli zobraziť túto stránku.
    </p>
  </div>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
</v-container>
</template>



<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Services from "@/api/services";

export default {
  name: "ParentHome",
  setup() {
    const store = useStore();
    const notifications = ref([]);

    const isParent = computed(() => {
      return (
        store.getters.isAuthenticated && store.getters.getUserType === "parent"
      );
    });

    const isTemporaryPassword = computed(
      () => store.getters.isTemporaryPassword
    );
    const showPasswordDialog = ref(isTemporaryPassword.value);

    const childInfo = ref(null);
    const newPassword = ref("");
    const confirmPassword = ref("");

    const isPasswordValid = computed(() => {
      return (
        newPassword.value.length >= 8 &&
        newPassword.value === confirmPassword.value
      );
    });

    const newPasswordErrors = computed(() => {
      const errors = [];
      if (newPassword.value.length < 8) {
        errors.push("Heslo musí mať aspoň 8 znakov.");
      }
      return errors;
    });

    const confirmPasswordErrors = computed(() => {
      const errors = [];
      if (confirmPassword.value !== newPassword.value) {
        errors.push("Heslá sa nezhodujú.");
      }
      return errors;
    });

    const getTypeColor = (type) => {
      switch (type) {
        case "Dôležité":
          return "red";
        case "Platba":
          return "green";
        case "Prijímací proces":
          return "purple";
        case "Obed":
          return "orange";
        case "Všeobecné":
          return "blue";
        default:
          return "grey";
      }
    };

    const fetchNotifications = async () => {
  try {
    const userId = await Services.getUserIdByEmail(
      localStorage.getItem("email")
    );
    let response;
    console.log(userId);

    if (userId) {
      response = await Services.getNotifications(userId);
    } else {
      throw new Error("No user ID or email available");
    }
    console.log(response);
    notifications.value = response;
  } catch (error) {
    console.error("Failed to fetch notifications:", error);
    showSnackbar("Nepodarilo sa načítať notifikácie. Skúste to znova.", "error");
  }
};

const changePassword = async () => {
      if (isPasswordValid.value) {
        try {
          const response = await Services.userSetLoginPassword(
            newPassword.value,
            localStorage.getItem("email")
          );
          console.log(response);
          if (response === 200) {
            store.commit("SET_TEMPORARY_PASSWORD", false);
            showPasswordDialog.value = false;
            showSnackbar("Heslo bolo úspešne zmenené", "success");
          }
        } catch (error) {
          console.error("Nepodarilo sa zmeniť heslo:", error);
          showSnackbar("Nepodarilo sa zmeniť heslo. Skúste to znova.", "error");
        }
      } else {
        showSnackbar("Heslo nespĺňa požiadavky. Skontrolujte ho a skúste znova.", "warning");
      }
    };

    onMounted(async () => {
      if (isParent.value) {
        await fetchNotifications();
      }
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      isParent,
      childInfo,
      isTemporaryPassword,
      showPasswordDialog,
      newPassword,
      confirmPassword,
      isPasswordValid,
      newPasswordErrors,
      confirmPasswordErrors,
      changePassword,
      notifications,
      getTypeColor,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-card {
  transition: all 0.3s ease;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent;
  box-shadow: none;
}
</style>
