<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field v-model="localManager.name" variant="underlined" label="Meno"
                :rules="[v => !!v || 'Meno je povinné']" class="input-field"></v-text-field>
              <v-text-field v-model="localManager.email" variant="underlined" label="Email"
                :rules="[v => !!v || 'Email je povinný']" class="input-field"></v-text-field>
              <v-text-field v-model="localManager.phone" variant="underlined" label="Telefón"
                class="input-field"></v-text-field>

              <div>
                <label>Škola</label>
                <div v-if="localManager.schoolIds.length > 0">
                  <div v-for="(school, index) in localManager.schoolIds" :key="index" class="d-flex align-center">
                    <v-text-field :value="school" variant="underlined" readonly class="input-field"></v-text-field>
                  </div>
                </div>
                <div v-else>
                  <v-text-field value="Nešpecifikovaná" variant="underlined" readonly
                    class="input-field"></v-text-field>
                </div>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="card-actions-custom">
            <v-btn variant="outlined" @click="save" class="submit-btn">Uložiť</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  props: {
    manager: Object,
    schools: Array,
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const localManager = ref({
      id: props.manager.id,
      name: props.manager.first_name + ' ' + props.manager.last_name,
      email: props.manager.email,
      phone: props.manager.phone_number,
      schoolIds: props.manager.schoolIds || [],
    });

    const rules = {
      required: v => !!v || 'Toto pole je povinné',
    };

    const filteredSchools = computed(() => {
      return props.schools.filter(school => school !== 'Všetky školy');
    });

    const availableSchools = (currentIndex) => {
      return filteredSchools.value.filter(school =>
        !localManager.value.schoolIds.includes(school) ||
        localManager.value.schoolIds[currentIndex] === school
      );
    };

    const addSchool = () => {
      if (localManager.value.schoolIds.length < filteredSchools.value.length) {
        localManager.value.schoolIds.push(null);
      }
    };

    const removeSchool = (index) => {
      localManager.value.schoolIds.splice(index, 1);
    };

    const updateSchool = (index, value) => {
      localManager.value.schoolIds[index] = value;
    };

    const save = async () => {
      if (form.value.validate()) {
        try {
          emit('save', localManager.value);
          showSnackbar('Manažér bol úspešne aktualizovaný', 'success');
        } catch (error) {
          console.error('Error saving manager:', error);
          showSnackbar('Nepodarilo sa aktualizovať manažéra', 'error');
        }
      }
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      localManager,
      rules,
      filteredSchools,
      availableSchools,
      addSchool,
      removeSchool,
      updateSchool,
      save,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important;
  /* Fialová s 50% priehľadnosťou */
  background-color: transparent !important;
  opacity: 1 !important;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.card-text-custom {
  padding-bottom: 0px !important;
}

.card-actions-custom {
  padding-left: 12px !important;
  /* Align with text fields, adjust as needed */
  padding-top: 0px !important;
  /* Reduce top padding */
  margin-top: -12px !important;
  /* Pull the button up a bit */
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
  margin-left: 0px !important;
  /* Remove any default left margin */
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>