<template>
    <v-card>
      <v-card-title> Informácie o objednávke č. {{ orderId }} </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orderItems"
          :loading="isLoading"
          :items-per-page="10"
          hide-default-footer
          :hide-default-header="smAndDown"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.meno_jedla="{ item }">
            {{ item.meno_jedla ?? "Neznáme jedlo" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.porcia_jedla="{ item }">
            {{ item.porcia_jedla ?? "Neznáma porcia" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.cena_jedla="{ item }">
            €{{ item.cena_jedla.toFixed(2) ?? "Neznáma cena" }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.quantita_jedla="{ item }">
            {{ item.quantita_jedla ?? 1 }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.celkova_cena_jedla="{ item }">
            {{ item.quantita_jedla > 0 ? `€${(item.cena_jedla * item.quantita_jedla).toFixed(2)}` : '-' }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex flex-column align-end">
        <v-chip
          color="green"
          text-color="white"
        >
          Celková cena objednávky: €{{ totalOrderPrice.toFixed(2) }}
        </v-chip>
      </v-card-actions>
    </v-card>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </template>
  
  <script>
  import { ref, computed } from "vue";
  import Services from "@/api/services";
  import { useDisplay } from "vuetify/lib/framework.mjs";
  
  export default {
    name: "SupplierInfoComponent",
    props: {
      orderId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const { smAndDown } = useDisplay();
      const orderItems = ref([]);
      const isLoading = ref(true);
  
      const headers = [
        { title: "Meno jedla", value: "meno_jedla" },
        { title: "Porcia jedla", value: "porcia_jedla" },
        { title: "Cena jedla", value: "cena_jedla" },
        { title: "Počet jedla", value: "quantita_jedla" },
        { title: "Celková cena jedla", value: "celkova_cena_jedla" },
      ];
  
      const totalOrderPrice = computed(() => {
        return orderItems.value.reduce((total, item) => {
          return item.quantita_jedla > 0
            ? total + item.cena_jedla * item.quantita_jedla
            : total;
        }, 0);
      });
  
      const fetchOrderItems = async () => {
        try {
          isLoading.value = true;
          orderItems.value = await Services.getOrderItems(props.orderId);
          console.log("Order items:", orderItems.value);
        } catch (error) {
          console.error("Error fetching order items:", error);
          showSnackbar("Chyba pri načítaní položiek objednávky", "error");
        } finally {
          isLoading.value = false;
        }
      };
  
      // Fetch order items when component is mounted
      fetchOrderItems();
      
      const snackbar = ref(false);
      const snackbarText = ref('');
      const snackbarColor = ref('success');
      const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
      };

      return {
        headers,
        orderItems,
        isLoading,
        totalOrderPrice,
        smAndDown,
        snackbar,
        snackbarText,
        snackbarColor,
      };
    },
  };
  </script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px 8px;
    line-height: 1.5;
    display: inline-block;
  }
  </style>