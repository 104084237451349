<template>
  <div>
    <h1 :class="[$vuetify.display.smAndDown ? 'text-h5' : 'text-h4', 'text-center mb-6 font-weight-bold']">Registrácia</h1>

    <v-card
      class="mx-auto pa-6 pb-8"
      :elevation="$vuetify.display.smAndDown ? 2 : 8"
      :max-width="$vuetify.display.smAndDown ? '100%' : '448'"
      :rounded="$vuetify.display.smAndDown ? 'md' : 'lg'"
    >
      <div class="text-subtitle-1 text-medium-emphasis mb-4">Účet</div>

      <v-text-field
        v-model="email"
        density="compact"
        placeholder="Emailová adresa"
        prepend-inner-icon="mdi-email-outline"
        variant="outlined"
        class="mb-2"
        :rules="[rules.required, rules.email]"
      ></v-text-field>

      <v-text-field
        v-model="firstName"
        density="compact"
        placeholder="Meno"
        prepend-inner-icon="mdi-account-outline"
        variant="outlined"
        class="mb-2"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="lastName"
        density="compact"
        placeholder="Priezvisko"
        prepend-inner-icon="mdi-account-outline"
        variant="outlined"
        class="mb-2"
        :rules="[rules.required]"
      ></v-text-field>

      <v-select
        v-model="userType"
        :items="userTypes"
        label="Typ používateľa"
        density="compact"
        variant="outlined"
        class="mb-4"
        :rules="[rules.required]"
      ></v-select>

      <v-card
        v-if="errorMessage"
        class="mb-4"
        color="red lighten-5"
        variant="tonal"
      >
        <v-card-text class="text-caption">
          {{ errorMessage }}
        </v-card-text>
      </v-card>

      <v-btn
        class="mb-4 custom-btn"
        :disabled="!isFormValid || isLoading"
        :size="$vuetify.display.smAndDown ? 'x-large' : 'large'"
        variant="outlined"
        block
        @click="handleRegister"
        :loading="isLoading"
      >
        {{ isLoading ? "Registrácia..." : "Zaregistrovať sa" }}
      </v-btn>
    </v-card>

    <v-dialog v-model="showConfirmationDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-center">Ďakujeme za prejavený záujem</v-card-title>
        <v-card-text>
          <p>
            Ďakujeme Vám za prejav záujmu o našu školu. Vaša iniciatíva nás teší
            a sme veľmi radi, že ste si vybrali nás ako potenciálnu školu pre
            vášho študenta/ku. Vaša žiadosť bola úspešne prijatá a naši
            pracovníci sa čoskoro s Vami spoja. Môžete očakávať našu odpoveď do
            troch pracovných dní.
          </p>
          <p>
            Ďalšie kroky v procese prijatia budú zaslané na váš e-mail, alebo si
            ich budete môcť sledovať vo vašom profile, ktorý vám bude
            automaticky vytvorený. Vaše prihlasovacie údaje nájdete vo vašej
            e-mailovej schránke.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="purple" block @click="redirectToLogin" class="custom-btn">Prihlásiť sa</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import Services from "@/api/services";

const email = ref("");
const firstName = ref("");
const lastName = ref("");
const userType = ref("");
const errorMessage = ref("");
const showConfirmationDialog = ref(false);

const userTypes = ref(["Rodič", "Zamestnance", "Dodávateľ"]);

const rules = {
  required: (value) => !!value || "Toto pole je povinné.",
  email: (value) =>
    /.+@.+\..+/.test(value) || "Zadajte platnú emailovú adresu.",
};

const router = useRouter();

const isFormValid = computed(() => {
  return (
    email.value.trim() !== "" &&
    firstName.value.trim() !== "" &&
    lastName.value.trim() !== "" &&
    userType.value.trim() !== ""
  );
});

const isLoading = ref(false);

const handleRegister = async () => {
  if (isLoading.value) return; // Prevent multiple submissions
  
  isLoading.value = true;
  try {
    let userTypeToSend;
    switch (userType.value) {
      case 'Rodič':
        userTypeToSend = 'parent';
        break;
      case 'Zamestnance':
        userTypeToSend = 'employee';
        break;
      case 'Dodávateľ':
        userTypeToSend = 'supplier';
        break;
    }
    const response = await Services.register({
      email: email.value,
      first_name: firstName.value,
      last_name: lastName.value,
      user_type: userTypeToSend,
      hashed_password: ''
    });
    console.log(response);
    if(response === 200){
      showConfirmationDialog.value = true;
    } else if(response === 400){
      errorMessage.value = "Užívateľ s touto emailovou adresou už existuje.";
    }
  } catch (error) {
    errorMessage.value = "Vyskytla sa chyba. Skúste to neskôr.";
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const redirectToLogin = () => {
  router.push("/login");
};

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.custom-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.v-btn.custom-btn.v-btn--disabled {
  color: grey !important;
}

@media (max-width: 600px) {
  .v-card {
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
</style>
