<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="localEmployee.name"
                variant="underlined"
                label="Meno"
                :rules="[(v) => !!v || 'Meno je povinné']"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localEmployee.email"
                variant="underlined"
                label="Email"
                :rules="[(v) => !!v || 'Email je povinný']"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localEmployee.phone"
                variant="underlined"
                label="Telefón"
                class="input-field"
              ></v-text-field>

              <v-select
                v-model="selectedSchool"
                :items="filteredSchools"
                item-title="name"
                item-value="id"
                label="Škola"
                variant="underlined"
                required
                :rules="[rules.required]"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions class="card-actions-custom">
            <v-btn variant="outlined" @click="save" class="submit-btn"
              >Uložiť</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
    {{ snackbarText }}
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false">
        Zavrieť
      </v-btn>
    </template>
  </v-snackbar>
  </v-container>
</template>
  
  <script>
import { ref, computed } from "vue";
import Services from "@/api/services";

export default {
  props: {
    employee: Object,
    schools: Array,
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const localEmployee = ref({
      id: props.employee.id,
      name: props.employee.first_name + " " + props.employee.last_name,
      email: props.employee.email,
      phone: props.employee.phone_number,
      school_name: props.employee.school
        ? props.employee.school.name
        : "Nezaradený",
    });

    const filteredSchools = computed(() => {
      console.log("All Schools", props.schools);
      return props.schools.filter((school) => school !== "Všetky školy");
    });

    // Initialize selectedSchool with the employee's current school if they have one
    const selectedSchool = ref(
      props.employee.school ? props.employee.school : null
    );

    const rules = {
      required: (v) => !!v || "Toto pole je povinné",
    };

    const save = async () => {
      if (form.value.validate()) {
        const schoolId = await Services.getSchoolIdByName(selectedSchool.value);
        console.log("School ID:", schoolId);
        const updatedEmployee = {
          first_name: localEmployee.value.name.split(" ")[0],
          last_name: localEmployee.value.name.split(" ")[1],
          email: localEmployee.value.email,
          phone_number: localEmployee.value.phone,
          school_id: schoolId,
        };

        try {
          const result = await Services.updateEmployee(
            localEmployee.value.id,
            updatedEmployee
          );
          console.log("Employee updated:", result);
          showSnackbar("Zamestnanec bol úspešne aktualizovaný.", "success");
          emit("save");
        } catch (error) {
          console.error("Failed to update employee:", error);
          showSnackbar("Nepodarilo sa aktualizovať zamestnanca.", "error");
          
        }
      }
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      localEmployee,
      selectedSchool,
      rules,
      filteredSchools,
      save,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>
  
  <style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important; /* Fialová s 50% priehľadnosťou */
  background-color: transparent !important;
  opacity: 1 !important;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.card-text-custom {
  padding-bottom: 0px !important;
}

.card-actions-custom {
  padding-left: 12px !important; /* Align with text fields, adjust as needed */
  padding-top: 0px !important; /* Reduce top padding */
  margin-top: -12px !important; /* Pull the button up a bit */
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
  margin-left: 0px !important; /* Remove any default left margin */
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>