import { createStore } from 'vuex';
import Services from '@/api/services';

export default createStore({
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    email: localStorage.getItem('email') || null,
    userType: localStorage.getItem('userType') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    id_token: localStorage.getItem('id_token') || null,
    isTemporaryPassword: localStorage.getItem('isTemporaryPassword') === 'true' || false,
  },
  mutations: {
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token;
      localStorage.setItem('accessToken', token);
    },
    SET_ID_TOKEN(state, token) {
      state.id_token = token;
      localStorage.setItem('id_token', token);
    },
    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token;
      localStorage.setItem('refreshToken', token);
    },
    SET_EMAIL(state, email) {
      state.email = email;
      localStorage.setItem('email', email);
    },
    SET_USER_TYPE(state, userType) {
      state.userType = userType;
      localStorage.setItem('userType', userType);
    },
    SET_TEMPORARY_PASSWORD(state, isTemporary) {
      state.isTemporaryPassword = isTemporary;
      localStorage.setItem('isTemporaryPassword', isTemporary);
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.email = null;
      state.userType = null;
      state.userInfo = null;
      state.isTemporaryPassword = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      localStorage.removeItem('userType');
      localStorage.removeItem('isTemporaryPassword');
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await Services.login(email, password);
        console.log(response);
        console.log(response);
        commit('SET_ACCESS_TOKEN', response.access_token);
        commit('SET_EMAIL', response.email);
        commit('SET_USER_TYPE', response.user_type);
        commit('SET_REFRESH_TOKEN', response.refresh_token);
        commit('SET_ID_TOKEN', response.id_token);
        commit('SET_TEMPORARY_PASSWORD', response.is_temporary_password);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },


    async setPassword({ commit }, { password, email, old_password }) {
      try {
        console.log(password)
        console.log(email)
        console.log(old_password)
        const response = await Services.setPassword(password, email, old_password);
        commit('SET_TEMPORARY_PASSWORD', false);
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async setLoginPassword({ commit }, { password, email}) {
      try {

        const response = await Services.userSetLoginPassword(password, email);
        commit('SET_TEMPORARY_PASSWORD', false);
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async refreshToken({ commit }) {
      try {
        const tokenData = await Services.refreshToken(localStorage.getItem('refreshToken'));
        commit('SET_ACCESS_TOKEN', tokenData.access_token);
        commit('SET_REFRESH_TOKEN', tokenData.refresh_token);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async changePassword({ commit }, newPassword) {
      try {
        await Services.changePassword(newPassword, localStorage.getItem('id_token'), localStorage.getItem('accessToken'));
        commit('SET_TEMPORARY_PASSWORD', false);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async logout({ commit }) {
      try {
        const logoutUrlEncoded = encodeURIComponent('http://localhost:8080/login'); 
        await Services.logout(logoutUrlEncoded);
        commit('LOGOUT');
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  },
  getters: {
    isAuthenticated: state => !!state.accessToken,
    getAccessToken: state => state.accessToken,
    getEmail: state => state.email,
    getUserType: state => state.userType,
    getUserInfo: state => state.userInfo,
    getIdToken: state => state.id_token,
    isTemporaryPassword: state => state.isTemporaryPassword
  }
});
