<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-card flat>
          <v-card-text class="card-text-custom">
            <v-form ref="form" v-model="valid" class="form-container">
              <v-text-field
                v-model="localStudent.first_name"
                variant="underlined"
                label="Meno"
                :rules="[(v) => !!v || 'Meno je povinné']"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.last_name"
                variant="underlined"
                label="Priezvisko"
                :rules="[(v) => !!v || 'Priezvisko je povinné']"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.email"
                variant="underlined"
                label="Email"
                :rules="[
                  (v) => !!v || 'Email je povinný',
                  (v) => /.+@.+\..+/.test(v) || 'Email musí byť platný',
                ]"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.grade"
                variant="underlined"
                label="Priemer"
                type="number"
                :rules="[
                  (v) => !!v || 'Priemer je povinný',
                  (v) => v > 0 || 'Priemer musí byť kladné číslo',
                ]"
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.classroom"
                variant="underlined"
                label="Trieda"
                :rules="[(v) => !!v || 'Trieda je povinná']"
                class="input-field"
              ></v-text-field>

              <v-card-title class="px-0">Stravník</v-card-title>
              <v-btn-toggle
                v-model="localStudent.is_diner"
                mandatory
                class="mb-4 input-field"
              >
                <v-btn 
                  :value="true"
                  :class="{ 'active-btn': localStudent.is_diner === true }"
                >
                  Áno
                </v-btn>
                <v-btn 
                  :value="false"
                  :class="{ 'active-btn': localStudent.is_diner === false }"
                >
                  Nie
                </v-btn>
              </v-btn-toggle>

              <v-card-title class="px-0">Forma štúdia</v-card-title>
              <v-btn-toggle
                v-model="localStudent.forma_studia"
                mandatory
                class="mb-4"
                @change="handleStudyFormChange"
              >
                <v-btn 
                  :value="true"
                  :class="{ 'active-btn': localStudent.forma_studia === true }"
                >
                  Denná
                </v-btn>
                <v-btn 
                  :value="false"
                  :class="{ 'active-btn': localStudent.forma_studia === false }"
                >
                  Externá
                </v-btn>
              </v-btn-toggle>
              <v-card-title class="px-0">Škola</v-card-title>
              <div v-if="localStudent.forma_studia" class="input-field">
                <v-select
                  v-model="localStudent.school_name"
                  :items="availableSchoolNames"
                  variant="underlined"
                  :label="localStudent.school_name || 'Škola'"
                  :rules="[(v) => !!v || 'Škola je povinná']"
                ></v-select>
              </div>

              <div v-else class="input-field">
                <v-card-title class="px-0">Škola</v-card-title>
                <div
                  v-for="(school, index) in localStudent.school_names"
                  :key="index"
                  class="d-flex align-center mb-2"
                >
                  <v-select
                    v-model="localStudent.school_names[index]"
                    :items="availableSchoolNames"
                    variant="underlined"
                    label="Škola"
                    required
                    :rules="[(v) => !!v || 'Škola je povinná']"
                  ></v-select>
                  <v-btn
                    icon
                    @click="removeSchool(index)"
                    v-if="localStudent.school_names.length > 1"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  text
                  @click="addSchool"
                  :disabled="
                    localStudent.school_names.length >=
                    availableSchoolNames.length
                  "
                  class="text-button mb-4"
                >
                  + Pridať školu
                </v-btn>
              </div>

              <v-card-title class="px-0">Informácie o zákonnom zástupcovi</v-card-title>
              <v-text-field
                v-model="localStudent.parent.first_name"
                variant="underlined"
                label="Meno rodiča"
                readonly
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.parent.last_name"
                variant="underlined"
                label="Priezvisko rodiča"
                readonly
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.parent.email"
                variant="underlined"
                label="Email rodiča"
                readonly
                class="input-field"
              ></v-text-field>
              <v-text-field
                v-model="localStudent.parent.phone_number"
                variant="underlined"
                label="Telefónne číslo rodiča"
                readonly
                class="input-field"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="card-actions-custom">
            <v-btn variant="outlined" @click="save" class="submit-btn">Uložiť</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import Services from "@/api/services";

export default {
  props: {
    student: Object,
    schools: Array,
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);

    const localStudent = ref({
      id: props.student.id,
      first_name: props.student.first_name,
      last_name: props.student.last_name,
      email: props.student.email,
      phone_number: props.student.phone_number,
      grade: props.student.grade,
      classroom: props.student.classroom,
      is_diner:
        props.student.diner_type === "student" ||
        props.student.diner_type === "employee",
      forma_studia: props.student.forma_studia === "full_time",
      school_names: props.student.schools.map((school) => school.name),
      school_name: props.student.schools[0].name,
      parent: props.student.parent || {},
      created_at: props.student.created_at,
      updated_at: props.student.updated_at,
    });

    const availableSchoolNames = computed(() => {
      console.log("Local school names", localStudent.value.school_names);
      if (props.schools.includes("Všetky školy")) {
        //get rid of the "Všetky školy" option
        return props.schools.filter((school) => school !== "Všetky školy");
      }
      return props.schools.map((school) => school);
    });

    const addSchool = () => {
      if (localStudent.value.school_names.length < props.schools.length) {
        localStudent.value.school_names.push("");
      }
    };

    const removeSchool = (index) => {
      localStudent.value.school_names.splice(index, 1);
    };

    const handleDinerTypeChange = () => {
      localStudent.value.diner_type = localStudent.value.is_diner
        ? "student"
        : "not_a_diner";
    };

    const handleStudyFormChange = () => {
      if (localStudent.value.forma_studia) {
        localStudent.value.school_names = [
          localStudent.value.school_names[0] || "",
        ];
        localStudent.value.school_name = localStudent.value.school_names[0];
      } else {
        localStudent.value.school_names = [localStudent.value.school_name];
        localStudent.value.school_name = "";
      }
    };

    const save = async () => {
      if (form.value.validate()) {
        try {
          let schoolIds;
          if (localStudent.value.forma_studia) {
            // Full-time student
            const schoolId = await Services.getSchoolIdByName(
              localStudent.value.school_name
            );
            schoolIds = [schoolId];
          } else {
            // Part-time student
            schoolIds = await Promise.all(
              localStudent.value.school_names.map((name) =>
                Services.getSchoolIdByName(name)
              )
            );
          }

          const studentData = {
            id: localStudent.value.id,
            first_name: localStudent.value.first_name,
            last_name: localStudent.value.last_name,
            email: localStudent.value.email,
            phone_number: localStudent.value.phone_number,
            grade: parseInt(localStudent.value.grade),
            classroom: localStudent.value.classroom,
            diner_type: localStudent.value.is_diner ? "student" : "not_a_diner",
            forma_studia: localStudent.value.forma_studia
              ? "full_time"
              : "part_time",
            schools: schoolIds,
          };

          const response = await Services.updateStudent(studentData);
          if (response.success) {
            emit("save", response.data);
            showSnackbar("Informácie o študentovi boli úspešne aktualizované", "success");
          } else {
            throw new Error(
              response.message ||
                "Nepodarilo sa aktualizovať informácie o študentovi"
            );
          }
        } catch (error) {
          console.error("Chyba pri aktualizácii študenta:", error);
          showSnackbar(
            "Nepodarilo sa aktualizovať informácie o študentovi: " +
              error.message,
            "error"
          );
        }
      }
    };

    onMounted(() => {
      console.log("StudentDetail mounted", props.schools);
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };


    return {
      form,
      valid,
      localStudent,
      availableSchoolNames,
      addSchool,
      removeSchool,
      handleDinerTypeChange,
      handleStudyFormChange,
      save,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important;
  background-color: transparent !important;
  opacity: 1 !important;
}

.custom-button {
  width: 100%;
  max-width: 200px;
}

.text-button {
  color: purple;
}

.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.submit-btn,
.text-button {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
}

.card-text-custom {
  padding-bottom: 0px !important;
}

.card-actions-custom {
  padding-left: 12px !important; /* Align with text fields, adjust as needed */
  padding-top: 0px !important; /* Reduce top padding */
  margin-top: -12px !important; /* Pull the button up a bit */
}

.submit-btn {
  margin-left: 0px !important; /* Remove any default left margin */
}

.text-button {
  padding-left: 0;
  margin-bottom: 16px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}

.v-btn-toggle {
  display: flex;
  justify-content: center;
}

.v-btn-toggle .v-btn {
  flex: 1;
}

.active-btn {
  background-color: purple !important;
  color: white !important;
}

.v-btn-toggle .v-btn.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  background-color: transparent !important;
}

.v-btn.text-button:disabled {
  opacity: 0.5;
  background-color: transparent !important;
}
</style>
