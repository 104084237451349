<template>
  <BaseLayout>
    <div>
      <component :is="paymentComponent" />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import ParentPaymentComponent from '@/components/ParentPaymentComponent.vue'
import NotAvailableComponent from '@/components/NotAvailableComponent.vue'
import AdminPaymentComponent from '@/components/AdminPaymentCompoment.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'PaymentsView',
  components: {
    BaseLayout,
    ParentPaymentComponent,
    NotAvailableComponent,
    AdminPaymentComponent
  },
  setup() {
    const store = useStore()
    const userType = computed(() => store.getters.getUserType)

    const paymentComponent = computed(() => {
      console.log("PaymentsView", userType.value)
      if (userType.value === 'parent') {
        return ParentPaymentComponent
      } else if (userType.value === 'admin') {
        return AdminPaymentComponent
      } 
      else {
        return NotAvailableComponent
      }
    })

    console.log(paymentComponent.value)

    return {
      paymentComponent,
    }
  }
}
</script>
