<template>
  <BaseLayout>
    <div>
      <component :is="mealComponent" />
    </div>
  </BaseLayout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import BaseLayout from '@/components/BaseLayout.vue';
import AdminMealComponent from '@/components/AdminMealComponent.vue';
import ParentMealComponent from '@/components/ParentMealComponent.vue';
import SupplierMenuComponentVue from '@/components/SupplierMenuComponent.vue';



export default {
  name: 'MealsView',
  components: {
    BaseLayout,
    AdminMealComponent,
    ParentMealComponent,
    SupplierMenuComponentVue,
  },
  setup() {
    const store = useStore();

    const mealComponent = computed(() => {
      const userType = store.getters.getUserType;
      switch (userType) {
        case 'admin':
          return 'AdminMealComponent';
        case 'parent':
          return 'ParentMealComponent';
        case 'supplier':
          return 'SupplierMenuComponentVue';
        default:
          return 'ParentMealComponent'; // Default component if user type is not recognized
      }
    });

    return {
      mealComponent,
    };
  },
};
</script>
