<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-form ref="form" v-model="valid" lazy-validation class="form-container">
          <v-autocomplete
            v-if="!selectedMeal"
            v-model="selectedMealLocal"
            :items="search ? searchResults : initialMeals"
            :loading="isSearching"
            v-model:search="search"
            item-title="name"
            item-value="id"
            label="Vyhľadať alebo vybrať predchádzajúce jedlo"
            placeholder="Vyberte alebo začnite písať pre vyhľadávanie"
            prepend-icon="mdi-database-search"
            return-object
            variant="solo"
            @update:search="debouncedSearch"
            @update:model-value="populateMealDetails"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Začnite písať pre vyhľadávanie alebo vyberte z ponuky
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="mealData.name"
            :rules="[rules.required]"
            label="Meno jedla"
            variant="underlined"
            required
            class="input-field"
          ></v-text-field>
          <v-textarea
            v-model="mealData.description"
            :rules="[rules.required]"
            label="Popis jedla"
            variant="underlined"
            required
            class="input-field"
          ></v-textarea>
          <v-select
            v-model="mealData.allergens"
            :items="allergenOptions"
            item-title="name"
            item-value="id"
            label="Alergény"
            variant="underlined"
            multiple
            class="input-field"
          ></v-select>
          <v-text-field
            v-model.number="mealData.price_supplier"
            :rules="[rules.required, rules.number]"
            label="Cena celej porcie"
            variant="underlined"
            type="number"
            step="0.01"
            required
            class="input-field"
          ></v-text-field>
          <v-text-field
            v-model.number="mealData.price_supplier_half"
            :rules="[rules.number]"
            label="Cena polovičnej porcie"
            variant="underlined"
            type="number"
            step="0.01"
            class="input-field"
          ></v-text-field>
          <v-date-picker
            v-model="mealData.available_date"
            :rules="[rules.required]"
            width="100%"
            label="Dátum dostupnosti jedla"
            required
            landscape
            class="custom-date-picker input-field"
          ></v-date-picker>

          <v-alert
            v-if="showDateAlert"
            type="error"
            class="mt-2"
          >
            Prosím, vyberte dátum dostupnosti jedla.
          </v-alert>

          <v-btn 
            variant="outlined" 
            @click="submitMeal" 
            :disabled="!isFormValid"
            color="purple"
            :block="mobile"
            class="submit-btn mt-4"
          >
            {{ isUpdating ? 'Aktualizovať jedlo' : 'Pridať jedlo' }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </v-container>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import Services from '@/api/services';
import moment from 'moment';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { debounce } from 'lodash';
import { parseISO } from 'date-fns';

export default {
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    selectedMeal: {
      type: Object,
      default: null,
    },
  },
  emits: ['meal-added', 'meal-updated'],
  setup(props, { emit }) {
    console.log('SupplierMealForm props:', props);
    const { mobile } = useDisplay();
    const form = ref(null);
    const valid = ref(false);
    const allergens = ref([]);
    const showDateAlert = ref(false);
    const search = ref('');
    const selectedMealLocal = ref(null);
    const searchResults = ref([]);
    const isSearching = ref(false);
    const mealData = ref({
      id: null,
      name: '',
      description: '',
      allergens: [],
      price_supplier: null,
      price_supplier_half: null,
      has_fixed_student_price: false,
      price_student: null,
      available_date: null,
    });

    const allergenOptions = ref([]);

    const rules = {
      required: value => !!value || 'Required.',
      number: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be a number.',
    };

    const isFormValid = computed(() => {
      return valid.value && mealData.value.available_date !== null;
    });

    const isUpdating = computed(() => !!mealData.value.id);

    const fetchAllergens = async () => {
      try {
        const response = await Services.getAllergens();
        allergens.value = response;
        allergenOptions.value = response.map(allergen => ({
          id: allergen.id,
          name: `${allergen.code}: ${allergen.name}`
        }));
      } catch (error) {
        console.error('Error fetching allergens:', error);
        showSnackbar('Chyba pri načítaní alergénov', 'error');
      }
    };

    const searchMealsHandler = async (query) => {
      if (!query || query.length < 2) {
        searchResults.value = [];
        return;
      }
      isSearching.value = true;
      try {
        const results = await Services.searchMeals(query, props.supplier);
        searchResults.value = results;
        if (results.length === 0) {
          showSnackbar('Žiadne jedlá neboli nájdené', 'info');
        }
      } catch (error) {
        console.error('Error searching meals:', error);
        searchResults.value = [];
        showSnackbar('Chyba pri vyhľadávaní jedál', 'error');
      } finally {
        isSearching.value = false;
      }
    };

    const initialMeals = ref([]);

    const fetchInitialMeals = async () => {
      try {
        const results = await Services.getRecentMeals(props.supplier, 10);
        initialMeals.value = results;
      } catch (error) {
        console.error('Error fetching initial meals:', error);
        showSnackbar('Chyba pri načítaní jedál', 'error');
      }
    };


    const debouncedSearch = debounce((value) => {
      if (value) {
        searchMealsHandler(value);
      } else {
        searchResults.value = [];
      }
    }, 300);

    const populateMealDetails = (meal) => {
      if (meal) {
        mealData.value = {
          id: meal.id,
          name: meal.name,
          description: meal.description,
          allergens: meal.allergens.map(allergen => allergen.id),
          price_supplier: meal.price_supplier,
          price_supplier_half: meal.price_supplier_half,
          has_fixed_student_price: meal.has_fixed_student_price,
          price_student: meal.price_student,
          available_date: meal.date ? parseISO(meal.date) : null,
        };
      } else {
        resetForm();
      }
    };

    const submitMeal = async () => {
      if (form.value && form.value.validate()) {
        if (mealData.value.available_date === null) {
          showDateAlert.value = true;
          return;
        }

        try {
          const formattedDate = mealData.value.available_date instanceof Date 
            ? moment(mealData.value.available_date).format('YYYY-MM-DD')
            : mealData.value.available_date;

          const mealDataToSubmit = {
            name: mealData.value.name,
            description: mealData.value.description,
            allergens: mealData.value.allergens,
            price_supplier: mealData.value.price_supplier,
            price_supplier_half: mealData.value.price_supplier_half,
            has_fixed_student_price: mealData.value.has_fixed_student_price,
            price_student: mealData.value.has_fixed_student_price ? mealData.value.price_student : null,
            date: formattedDate,
            supplier_id: props.supplier,
          };

          let response;
          if (isUpdating.value) {
            response = await Services.updateMeal(mealData.value.id, mealDataToSubmit);
            emit('meal-updated', response);
            showSnackbar('Jedlo bolo úspešne aktualizované', 'success');
          } else {
            response = await Services.addMeal(mealDataToSubmit);
            emit('meal-added', response);
            showSnackbar('Jedlo bolo úspešne pridané', 'success');
          }

          console.log('Meal operation successful:', response);

          resetForm();
        } catch (error) {
          console.error('Error submitting meal:', error);
          showSnackbar('Chyba pri odosielaní jedla: ' + error.message, 'error');
        }
      }
    };

    const resetForm = () => {
      if (form.value) {
        form.value.reset();
      }
      mealData.value = {
        id: null,
        name: '',
        description: '',
        allergens: [],
        price_supplier: null,
        price_supplier_half: null,
        has_fixed_student_price: false,
        price_student: null,
        available_date: null,
      };
      showDateAlert.value = false;
      selectedMealLocal.value = null;
      search.value = '';
    };

    const removeTrailingDecimalSeparator = (value) => {
      if (value === null || value === undefined) {
        return value;
      }
      const stringValue = value.toString();
      if (stringValue.endsWith('.') || stringValue.endsWith(',')) {
        return parseFloat(stringValue.slice(0, -1));
      }
      return value;
    };

    watch(() => mealData.value.price_supplier, (newValue) => {
      if (newValue !== undefined) {
        mealData.value.price_supplier = removeTrailingDecimalSeparator(newValue);
      }
    });

    watch(() => mealData.value.price_supplier_half, (newValue) => {
      if (newValue !== undefined) {
        mealData.value.price_supplier_half = removeTrailingDecimalSeparator(newValue);
      }
    });

    watch(() => mealData.value.price_student, (newValue) => {
      if (newValue !== undefined) {
        mealData.value.price_student = removeTrailingDecimalSeparator(newValue);
      }
    });

    watch(() => props.selectedMeal, (newMeal) => {
      if (newMeal) {
        populateMealDetails(newMeal);
        selectedMealLocal.value = newMeal;
      } else {
        resetForm();
      }
    }, { immediate: true });

    onMounted(() => {
      fetchAllergens();
      fetchInitialMeals();
    });

    

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      valid,
      mealData,
      allergenOptions,
      search,
      selectedMealLocal,
      searchResults,
      isSearching,
      isUpdating,
      rules,
      submitMeal,
      showDateAlert,
      isFormValid,
      mobile,
      debouncedSearch,
      populateMealDetails,
      snackbar,
      snackbarText,
      snackbarColor,
      initialMeals
    };
  },
};
</script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .custom-button {
    width: 100%;
    max-width: 200px;
  }
  
  .custom-date-picker {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .custom-date-picker :deep(.v-date-picker-header__value) {
    color: purple !important;
  }
  
  .custom-date-picker :deep(.v-date-picker-controls .v-btn) {
    color: purple !important;
  }
  
  .v-btn-toggle {
    display: flex;
    justify-content: center;
  }
  
  .v-btn-toggle .v-btn {
    flex: 1;
  }
  
  .active-btn {
    background-color: purple !important;
    color: white !important;
  }
  
  .disabled-btn {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .form-container {
    padding: 20px 0;
  }
  
  .input-field {
    margin-bottom: 20px;
  }
  
  .submit-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
    height: 44px;
    margin-left: 0;
  }
  
  .custom-date-picker {
    margin-bottom: 20px;
  }
  
  @media (max-width: 600px) {
    .form-container {
      padding: 10px 0;
    }
  
    .input-field {
      margin-bottom: 15px;
    }
  }
  </style>