<template>
    <div class="user-payments-view">
        <v-container fluid class="pa-0 payments-container">
            <v-btn icon @click="$emit('close')" class="mb-4">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-row class="mb-4 search-fields-row">
                <v-col cols="12" class="py-1 px-2">
                    <v-text-field v-model="paymentSearch" label="Vyhľadať Platbu" prepend-inner-icon="mdi-magnify"
                        variant="solo" density="compact" hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-4 date-fields-row">
                <v-col cols="6" class="py-1 px-2">
                    <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ props }">
                            <v-text-field v-model="formattedStartDate" label="Od" prepend-inner-icon="mdi-calendar"
                                readonly variant="solo" density="compact" v-bind="props" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange[0]" @update:model-value="updateStartDate"
                            :landscape="landscape" :width="width"
                            class="custom-date-picker input-field"></v-date-picker>
                        <v-btn text color="purple" @click="resetStartDate">Resetovať</v-btn>
                    </v-menu>
                </v-col>
                <v-col cols="6" class="py-1 px-2">
                    <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y
                        min-width="290px">
                        <template v-slot:activator="{ props }">
                            <v-text-field v-model="formattedEndDate" label="Do" prepend-inner-icon="mdi-calendar"
                                readonly variant="solo" density="compact" v-bind="props" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange[1]" @update:model-value="updateEndDate" :landscape="landscape"
                            :width="width" class="custom-date-picker input-field"></v-date-picker>
                        <v-btn text color="purple" @click="resetEndDate">Resetovať</v-btn>
                    </v-menu>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="filteredPayments" :loading="loading" :search="paymentSearch"
                class="elevation-0">
                <!-- eslint-disable-next-line -->
                <template v-slot:item.amount="{ item }">
                    {{ item.amount.toFixed(2) }} €
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.status="{ item }">
                    <v-chip :color="getStatusColor(item.status)" text-color="white" size="small">
                        {{ item.status }}
                    </v-chip>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.action="{ item }">
                    <v-btn v-if="item.status === 'Uhradené' || item.status === 'Spracováva sa'" variant="text"
                        density="comfortable" color="purple" @click="downloadInvoice(item)">
                        Stiahnuť faktúru
                    </v-btn>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.payment_date="{ item }">
                    <span>{{ formatDate(item.payment_date) }}</span>
                </template>
            </v-data-table>
            <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
        </v-container>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import Services from '@/api/services';
export default {
    name: 'UserPaymentsView',
    props: {
        userId: {
            type: String,
            required: true
        },
        isStudent: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(props) {
        const payments = ref([]);
        const loading = ref(true);
        const error = ref(null);
        const paymentSearch = ref('');
        const dateRange = ref([null, null]);
        const menuStartDate = ref(false);
        const menuEndDate = ref(false);
        const totalPayments = ref(0);
        const totalPages = ref(0);
        const currentPage = ref(1);
        const itemsPerPage = ref(10);

        const headers = [
            { title: 'Platba', align: 'start', key: 'payment_name' },
            { title: 'Cena', key: 'amount', align: 'center' },
            { title: 'Splatnosť', key: 'payment_date', align: 'end' },
            { title: 'Stav', key: 'status', align: 'end' },
            { key: 'action', sortable: false, align: 'end' },
        ];

        const formattedStartDate = computed(() => {
            return dateRange.value[0] ? new Date(dateRange.value[0]).toLocaleDateString() : '';
        });

        const formattedEndDate = computed(() => {
            return dateRange.value[1] ? new Date(dateRange.value[1]).toLocaleDateString() : '';
        });

        const filteredPayments = computed(() => {
            let filtered = payments.value;
            if (dateRange.value[0] && dateRange.value[1]) {
                const [start, end] = dateRange.value;
                filtered = filtered.filter((payment) => {
                    const paymentDate = new Date(payment.payment_date);
                    return paymentDate >= new Date(start) && paymentDate <= new Date(end);
                });
            }
            return filtered;
        });

        const fetchPayments = async () => {
            try {
                console.log('Fetching payments...', props);
                const response_id = await Services.getParentId(props.userId);
                console.log('userIdToFetch:', response_id);
                const userIdToFetch = response_id;
                const response = await Services.getUserPayments(userIdToFetch, {
                    startDate: dateRange.value[0],
                    endDate: dateRange.value[1],
                    status: null,
                    search: paymentSearch.value,
                    page: Math.max(1, currentPage.value), // Ensure page is at least 1
                    itemsPerPage: itemsPerPage.value,
                });
                console.log('Fetched payments:', response);
                payments.value = response.items;
                totalPayments.value = response.total;
                totalPages.value = response.total_pages;
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    console.log('User has no payments');
                    payments.value = [];
                    totalPayments.value = 0;
                    totalPages.value = 1;
                    showSnackbar('Používateľ nemá žiadne platby.', 'info');
                } else {
                    console.error('Error fetching payments:', err);
                    error.value = 'Failed to fetch payments. Please try again later.';
                    showSnackbar('Nepodarilo sa načítať platby. Skúste to znova neskôr.', 'error');
                }
            } finally {
                loading.value = false;
            }
        };

        const formatDate = (dateString) => {
            return new Date(dateString).toLocaleDateString();
        };

        const downloadInvoice = async (paymentItem) => {
            try {
                const response = await Services.downloadInvoice(paymentItem.id);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `invoice_${paymentItem.id}.pdf`;
                link.click();
                window.URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error('Error in downloadInvoice:', error);
                showSnackbar('Chyba pri sťahovaní faktúry', 'error');
            }
        };

        const payNow = async (paymentItem) => {
            try {
                await Services.initiatePayment(paymentItem.id);
                showSnackbar('Platba bola úspešne iniciovaná', 'success');
            } catch (error) {
                console.error('Error in payNow:', error);
                showSnackbar('Chyba pri iniciovaní platby', 'error');
            }
        };

        const getStatusColor = (status) => {
            switch (status) {
                case 'Neuhradené':
                    return 'error';
                case 'Uhradené':
                    return 'success';
                case 'Spracováva sa':
                    return 'warning';
                default:
                    return 'default';
            }
        };

        const resetStartDate = () => {
            dateRange.value[0] = null;
        };

        const resetEndDate = () => {
            dateRange.value[1] = null;
        };

        const updateStartDate = (value) => {
            dateRange.value[0] = value;
            menuStartDate.value = false;
        };

        const updateEndDate = (value) => {
            dateRange.value[1] = value;
            menuEndDate.value = false;
        };

        onMounted(() => {
            fetchPayments();
        });

        watch([paymentSearch, dateRange], () => {
            fetchPayments();
        });

        const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');
        const showSnackbar = (text, color = 'success') => {
          snackbarText.value = text;
          snackbarColor.value = color;
          snackbar.value = true;
        };

        return {
            payments,
            loading,
            error,
            formatDate,
            downloadInvoice,
            payNow,
            getStatusColor,
            paymentSearch,
            dateRange,
            menuStartDate,
            menuEndDate,
            filteredPayments,
            headers,
            formattedStartDate,
            formattedEndDate,
            resetStartDate,
            resetEndDate,
            updateStartDate,
            updateEndDate,
            snackbar,
            snackbarText,
            snackbarColor,
        };
    },
};
</script>

<style scoped>
.v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent;
    box-shadow: none;
}

.selected {
    color: black;
    text-decoration: underline;
}

.rounded-corners {
    border-radius: 10px;
}

.header-cell {
    background-color: #f5f5f5;
    color: black;
    font-weight: bold;
}

.no-borders {
    border: none !important;
}

.payments-container {
    margin-top: 12px;
    /* Adjust this value as needed */
}
</style>