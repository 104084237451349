<template>
    <BaseLayout>
      <div>
        <component :is="homeComponent" />
      </div>
    </BaseLayout>
  </template>
  
  <script>
  import BaseLayout from '@/components/BaseLayout.vue'
  import AdminHome from '@/components/AdminHome.vue'
  import ParentHome from '@/components/ParentHome.vue'
  import ManagerHome from '@/components/ManagerHome.vue'
  import SupplierHomeComponentVue from '@/components/SupplierHomeComponent.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'

  export default {
    name: 'HomeView',
    components: {
      BaseLayout,
      AdminHome,
      ParentHome,
      SupplierHomeComponentVue,
    },
    setup() {
      const store = useStore()
      const userType = computed(() => store.getters.getUserType)
        
      console.log(userType.value)
      const homeComponent = computed(() => {
        if (userType.value === 'admin') {
          return AdminHome
        } else if (userType.value === 'parent') {
          return ParentHome
        }else if (userType.value === 'manager') {
          return ManagerHome
        }else if (userType.value === 'supplier') {
          console.log("HERE HERE HERE",SupplierHomeComponentVue)
          console.log(SupplierHomeComponentVue)
          return SupplierHomeComponentVue
        }
        return null
      })
  
      console.log(homeComponent.value)
  
      return {
        homeComponent,
      }
    }
  }
  </script>

  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
  </style>
  
  