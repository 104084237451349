<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="12" sm="8">
                <v-tabs v-model="selectedOption" centered :grow="$vuetify.display.smAndDown"
                  :slider-size="mobile ? 2 : 4" dense>
                  <v-btn v-if="showAddForm || showDetails || studentDetails" icon @click="handleGoBack" size="small"
                    class="mr-2">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>

                  <v-tab class="admin-home-btn" value='Skoly'>Školy</v-tab>
                  <v-tab class="admin-home-btn" value='Studenti'>Študenti</v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-end mt-3 mt-sm-0">
                <v-btn v-if="!showAddForm && !showDetails && !studentDetails" variant="outlined" @click="handleAdd"
                  :class="['admin-home-btn-add', { 'mobile-btn': mobile }]">
                  {{ selectedOption === "Skoly" ? "Pridať školu" : "Pridať študenta" }}
                </v-btn>

                <v-menu v-if="!showAddForm && !showDetails && !studentDetails" anchor="bottom end" rounded offset-y
                  max-height="400" max-width="400" density="compact" :close-on-content-click="false"
                  :mobile="smAndDown">
                  <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" class="admin-home-btn mr-2">
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  <v-card width="300" class="admin-home-menu">
                    <v-list-item v-if="selectedOption === 'Skoly'">
                      <v-list-item-title>Počet študentov</v-list-item-title>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="studentCountRange[0]" label="Min" type="number" min="0"
                            class="mt-4"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="studentCountRange[1]" label="Max" type="number" min="0"
                            class="mt-4"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-if="selectedOption === 'Studenti'">
                      <v-list-item-title>Forma štúdia</v-list-item-title>
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox v-model="studyFormFullTime" :value="'full_time'" label="Prezenčne"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox v-model="studyFormPartTime" :value="'part_time'" label="Distančne"></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-list-item-title>Uhradené školné</v-list-item-title>
                      <v-checkbox v-model="tuitionPaid" label="Yes"></v-checkbox>
                    </v-list-item>
                    <v-card-actions>
                      <v-btn @click="applyFilters">Použiť filtre</v-btn>
                      <v-btn @click="resetFilters" class="d-flex justify-end">Resetovať filtre</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-window disabled v-model="selectedOption">
              <v-window-item value="Skoly">
                <div v-if="!showDetails && !showAddForm">
                  <v-data-table :headers="headers" :items="schools" :loading="loadingSchools" class="rounded-corners">
                    <template v-slot:[`item.administrativnyPracovnik`]="{ item }">
                      <v-chip :color="item.administrativnyPracovnik === 'Not specified' ? 'red' : 'purple'" label>
                        {{ item.administrativnyPracovnik === 'Not specified' ? 'Nešpecifikovaný' :
                          item.administrativnyPracovnik }}
                      </v-chip>
                    </template>
                    <template v-slot:[`item.ziaci`]="{ item }">
                      <v-btn class="admin-home-btn" text @click="showStudents(item.skola)">Žiaci</v-btn>
                    </template>
                    <template v-slot:[`item.zobrazit`]="{ item }">
                      <v-btn class="admin-home-btn" text @click="showSchoolDetails(item.id)">Zobraziť</v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-if="showDetails">
                  <SchoolDetails :school="selectedSchool" :managers="managersArray" @save="handleManagerUpdate" />
                </div>
                <div v-if="showAddForm">
                  <SchoolForm :managers="managersArray" @school-added="handleSchoolAdded" />
                </div>
              </v-window-item>

              <v-window-item value="Studenti">
                <div v-if="
                  selectedOption === 'Studenti' && !studentDetails && !showAddForm
                ">
                  <v-row align="center" justify="space-between" class="mb-4">
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="studentSearch" label="Vyhľadať žiaka" append-inner-icon="mdi-magnify"
                        append-inner-icon-color="purple" density="compact" variant="solo" hide-details
                        @click:append-inner="fetchStudents"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select v-model="selectedSchool" :items="schoolOptions" label="Škola" density="compact"
                        variant="solo" hide-details></v-select>
                    </v-col>
                  </v-row>
                  <div v-if="!studentDetails && !showAddForm">
                    <v-data-table-server :headers="headersStudents" :items="students" :loading="loadingStudents"
                      class="rounded-corners" :items-per-page="itemsPerPage" :page="currentPage"
                      :items-length="totalItems" @update:page="handleTableUpdate"
                      @update:items-per-page="handleItemsPerPage">
                      <template v-slot:[`item.zobrazit`]="{ item }">
                        <v-btn class="admin-home-btn" text @click="showStudentDetails(item)">Zobraziť</v-btn>
                      </template>
                      <template v-slot:[`item.formaStudia`]="{ item }">
                        <v-chip label color="purple" v-if="item.formaStudia === 'full_time'">Prezenčne</v-chip>
                        <v-chip color="purple" label v-else-if="item.formaStudia === 'part_time'">Distančne</v-chip>
                      </template>
                    </v-data-table-server>
                  </div>
                </div>
                <div v-if="studentDetails">
                  <StudentDetail :student="selectedStudent" :schools="schoolOptions" />
                </div>
                <div v-if="showAddForm">
                  <StudentForm :schools="schoolOptions" @student-added="handleStudentAdded" />
                </div>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
  </v-container>
</template>






<script>
import { ref, watch, onMounted } from "vue";
import Services from "@/api/services";
import SchoolDetails from "@/components/SchoolDetail.vue";
import StudentDetail from "@/components/StudentDetail.vue";
import StudentForm from "@/components/StudentForm.vue";
import SchoolForm from "@/components/SchoolForm.vue";
import { useDisplay } from "vuetify";
import { debounce } from 'lodash';


export default {
  components: {
    SchoolDetails,
    StudentDetail,
    StudentForm,
    SchoolForm,
  },
  setup() {
    const selectedOption = ref("Skoly");
    const showAddForm = ref(false);
    const { mobile } = useDisplay();
    const { smAndDown } = useDisplay()
    const headers = ref([
      { title: "Škola", align: "start", key: "skola" },
      {
        title: "Administratívny pracovník",
        align: "start",
        key: "administrativnyPracovnik",
      },
      { title: "Meno riaditeľa", align: "start", key: "menoRiaditela" },
      { title: "Počet žiakov", align: "center", key: "pocetZiakov" },
      { title: "", align: "start", key: "ziaci", sortable: false },
      { title: "", align: "start", key: "zobrazit", sortable: false },
    ]);

    const headersStudents = ref([
      { title: "Meno", align: "start", key: "meno" },
      { title: "Priezvisko", align: "start", key: "priezvisko" },
      { title: "Ročník/Trieda", align: "center", key: "rocnik" },
      { title: "Miesto dochádzky", align: "center", key: "miestoDochadzky" },
      { title: "Forma štúdia", align: "center", key: "formaStudia" },
      { title: "Uhradené školné", align: "center", key: "uhradeneSkolne" },
      { title: "", align: "start", key: "zobrazit", sortable: false },
    ]);

    const handleGoBack = () => {
      showDetails.value = false;
      studentDetails.value = false;
      showAddForm.value = false;
    };
    const handleGoBackDetails = () => {
      showDetails.value = false;
      studentDetails.value = false;
    };

    onMounted(() => {
      console.log("Mobile:", mobile);
    });

    const schools = ref([]);
    const students = ref([]);
    const showDetails = ref(false);
    const selectedSchool = ref(null);
    const selectedStudent = ref(null);
    const schoolFilter = ref("");
    const studentSearch = ref("");
    const selectedSchoolFilter = ref(null);
    const schoolOptions = ref([]);
    const loadingSchools = ref(false);
    const loadingStudents = ref(false);
    const schoolFilterMenu = ref(false);
    const studentFilterMenu = ref(false);
    const selectedSchoolFilters = ref([]);
    const selectedStudentFilters = ref([]);
    const pickedSchool = ref(null);
    const studentCountRange = ref([0, 1000]);
    const gradeRange = ref([0, 5]);
    const studyFormFullTime = ref(false);
    const studyFormPartTime = ref(false);
    const tuitionPaid = ref(false);
    const studentDetails = ref(false);
    const managersArray = ref([]);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const showSchoolTab = ref(true);
    const totalItems = ref(0);
    const totalPages = ref(0);
    const filtersStudents = ref({
      first_name: null,
      last_name: null,
      studyForm: null,
      tuitionPaid: null,
    });
    const studentCountFilterApplied = ref(false);

    const handleTableUpdate = (options) => {


      
      if (selectedOption.value === "Studenti") {
        fetchStudents(options, itemsPerPage.value, selectedSchool.value);
      }
    };

    const handleItemsPerPage = (newItemsPerPage) => {
      console.log("Items per page:", newItemsPerPage);

      // Update the items per page
      itemsPerPage.value = newItemsPerPage;


      if (selectedOption.value === "Studenti") {
        fetchStudents(1, newItemsPerPage, selectedSchool.value);
      }
    };

    const handleManagerUpdate = () => {
      fetchSchools();
    };

    const fetchManagers = async () => {
      try {
        const response = await Services.getManagers();
        console.log("Managers:", response);
        managersArray.value = response.items
        console.log("Managers Array:", managersArray.value);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    const fetchSchools = async () => {
      loadingSchools.value = true;
      try {
        let response;
        console.log(
          "Fetching schools:",
          studentCountRange.value[0],
          studentCountRange.value[1]
        );
        if (!studentCountFilterApplied.value) {
          response = await Services.getSchools();
        } else {
          response = await Services.getSchools(
            studentCountRange.value[0],
            studentCountRange.value[1]
          );
        }
        console.log("Schools:", response);

        schools.value = response.map((school) => ({
          id: school.id,
          skola: school.name,
          administrativnyPracovnik: school.manager_name,
          menoRiaditela: "Jane Smith",
          pocetZiakov: school.student_count,
          ziaci: "Ziaci",
          zobrazit: "Zobrazit",
        }));

        schoolOptions.value = response.map((school) => school.name);
        schoolOptions.value.unshift("Všetky školy");
        selectedSchool.value = schoolOptions.value[0];
      } catch (error) {
        console.error("Error fetching schools:", error);
        showSnackbar("Chyba pri načítaní škôl", "error");
      } finally {
        loadingSchools.value = false;
      }
    };

    const fetchStudents = debounce(async (
      page = 1,
      itemsPerTablePage = 10,
      newSchool = null
    ) => {
      loadingStudents.value = true;
      console.log("Fetching students:", page, itemsPerTablePage, newSchool);
      try {
        let schoolId = null;
        if (newSchool && newSchool !== "Všetky školy") {
          const school = schools.value.find(
            (school) => school.skola === newSchool
          );
          schoolId = school.id;
        }
        console.log(
          "Fetching students:",
          page,
          itemsPerTablePage,
          schoolId,
          filtersStudents
        );
        // Use the getStudents function from the service with pagination parameters
        const response = await Services.getStudents(
          page,
          itemsPerTablePage,
          schoolId,
          filtersStudents.value
        );
        console.log("Students:", response);

        if (response && response.items) {
          students.value = response.items.map((student) => ({
            id: student.id,
            meno: student.first_name,
            priezvisko: student.last_name,
            rocnik: student.classroom,
            miestoDochadzky: schools.value.find(
              (school) => school.id === student.school_id
            ).skola,
            priemer: student.grade,
            formaStudia: student.study_form,
            uhradeneSkolne: student.paid_tuition ? "Ano" : "Nie",
            zobrazit: "Zobrazit",
          }));

          totalItems.value = response.total_items;
          currentPage.value = page;
          itemsPerPage.value = itemsPerTablePage;
        } else {
          console.error("Invalid response structure:", response);
          showSnackbar("Chyba pri načítaní študentov", "error");
        }
      } catch (error) {
        console.error("Error fetching students:", error);
        showSnackbar("Chyba pri načítaní študentov", "error");
      } finally {
        loadingStudents.value = false;
      }
    }, 300); // 300ms debounce time
    const showSchoolDetails = async (school) => {
      try {
        console.log("Fetching school details:", school);
        const response = await Services.getSchool(school);
        fetchManagers();
        console.log("Selected School:", response);
        selectedSchool.value = response;
        showDetails.value = true;
      } catch (error) {
        console.error("Error fetching school details:", error);
        showSnackbar("Chyba pri načítaní detailov školy", "error");
      }
    };

    const handleSchoolAdded = () => {
      showSnackbar("Škola bola úspešne pridaná!", "success");
      showAddForm.value = false;
      fetchSchools();
    };

    const showStudentDetails = async (student) => {
      try {
        console.log("Fetching student details:", student);
        const response = await Services.getStudentDetails(student.id);
        console.log("Selected Student:", response);
        selectedStudent.value = response;
        studentDetails.value = true;
      } catch (error) {
        console.error("Error fetching student details:", error);
        showSnackbar("Chyba pri načítaní detailu študenta", "error");
      }
    };

    // Modify the showStudents function
    const showStudents = async (school) => {
      console.log("Show Students:", school);
      pickedSchool.value = school;
      selectedSchool.value = school;
      selectedOption.value = "Studenti";
    };
    watch(selectedSchool, () => {
      if (selectedOption.value === 'Studenti') {
        handleStudentFetch();
      }
    });

    const goBack = () => {
      showDetails.value = false;
      selectedSchool.value = null;
    };

    const applySchoolFilters = () => {
      studentCountFilterApplied.value = true;
      fetchSchools();
      schoolFilterMenu.value = false;
    };

    watch(studentSearch, (newSearch) => {
      filtersStudents.value.first_name = newSearch;
      filtersStudents.value.last_name = newSearch;
      fetchStudents();
      console.log("Student search:", newSearch);
    });

    const resetFilters = () => {
      studentCountFilterApplied.value = false;
      studentCountRange.value = [0, 1000];
      studyFormFullTime.value = false;
      studyFormPartTime.value = false;
      tuitionPaid.value = false;
      filtersStudents.value = {
        first_name: null,
        last_name: null,
        gradeRange: [0, 5],
        studyForm: null,
        tuitionPaid: null,
      };
      fetchSchools();
      fetchStudents();
    };

    const applyStudentFilters = () => {
      filtersStudents.value = {
        first_name: studentSearch.value,
        last_name: studentSearch.value,
        gradeRange: gradeRange.value,
        studyForm: studyFormFullTime.value
          ? "full_time"
          : studyFormPartTime.value
            ? "part_time"
            : null,
        tuitionPaid: tuitionPaid.value,
      };

      console.log("Applying student filters:", filtersStudents.value);
      fetchStudents();
      studentFilterMenu.value = false;
    };

    const handleClickOutside = () => {
      schoolFilterMenu.value = false;
    };


    const handleStudentFetch = () => {
      if (selectedOption.value === 'Studenti') {
        if (pickedSchool.value) {
          selectedSchool.value = pickedSchool.value;
          pickedSchool.value = null;
          fetchStudents(1, 10, selectedSchool.value,);
        } else if (selectedSchool.value === "Všetky školy") {
          fetchStudents(1, itemsPerPage.value);
        } else if (selectedSchool.value !== "Všetky školy" && pickedSchool.value === null) {
          fetchStudents(currentPage.value, itemsPerPage.value, selectedSchool.value,);
        } else {
          fetchStudents(1, itemsPerPage.value);
          pickedSchool.value = null; // Reset pickedSchool after use
        }
      }
    };
    watch(selectedOption, (newOption) => {
      filtersStudents.value = {
        first_name: null,
        last_name: null,
        gradeRange: [0, 5],
        studyForm: null,
        tuitionPaid: null,
      };
      showAddForm.value = false;
      showDetails.value = false;
      studentDetails.value = false;

      if (newOption === "Studenti") {
        handleStudentFetch();
      } else if (newOption === "Skoly") {
        fetchSchools();
      }
    });

    watch(schoolFilterMenu, () => {
      console.log("School filter menu:", schoolFilterMenu.value);
    });

    onMounted(() => {
      fetchSchools();
      fetchManagers();

    });

    const handleAdd = () => {
      studentDetails.value = false;
      SchoolDetails.value = false;
      showAddForm.value = true;
      showSchoolTab.value = false;
    };

    const handleStudentAdded = () => {
      showSnackbar("Študent bol úspešne pridaný!", "success");
      showAddForm.value = false;
      students.value = [];
      filtersStudents.value = {
        first_name: null,
        last_name: null,
        gradeRange: [0, 5],
        studyForm: null,
        tuitionPaid: null,
      };
      handleStudentFetch();
    };

    const applyFilters = () => {
      if (selectedOption.value === "Skoly") {
        applySchoolFilters();
      } else {
        applyStudentFilters();
      }
      studentFilterMenu.value = false;
      schoolFilterMenu.value = false;
    };

    const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');

    const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
    };

    return {
      selectedOption,
      headers,
      headersStudents,
      schools,
      students,
      showDetails,
      selectedSchool,
      schoolFilter,
      studentSearch,
      selectedSchoolFilter,
      schoolOptions,
      loadingSchools,
      loadingStudents,
      schoolFilterMenu,
      studentFilterMenu,
      selectedSchoolFilters,
      selectedStudentFilters,
      fetchSchools,
      fetchStudents,
      showSchoolDetails,
      showStudents,
      goBack,
      applySchoolFilters,
      applyStudentFilters,
      studentCountRange,
      gradeRange,
      studyFormFullTime,
      studyFormPartTime,
      tuitionPaid,
      handleClickOutside,
      showStudentDetails,
      studentDetails,
      selectedStudent,
      showAddForm,
      handleStudentAdded,
      handleAdd,
      managersArray,
      handleSchoolAdded,
      handleGoBack,
      handleGoBackDetails,
      currentPage,
      itemsPerPage,
      totalItems,
      totalPages,
      handleTableUpdate,
      resetFilters,
      applyFilters,
      mobile,
      handleManagerUpdate,
      showSchoolTab,
      smAndDown,
      handleItemsPerPage,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.admin-home-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
}

.selected {
  color: black;
  text-decoration: underline;
}

.rounded-corners {
  border-radius: 10px;
}

.header-cell {
  background-color: #f5f5f5;
  color: black;
  font-weight: bold;
}

.admin-home-btn-add {
  text-transform: none;
  font-weight: bold;
  color: purple;
  /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5;
  /* Adjust line-height to ensure the text is centered */
  display: inline-block;
  /* Ensure the button is displayed as an inline element */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.admin-home-btn-add:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add shadow on hover */
}

.v-select .v-label {
  padding-left: 4px !important;
  padding-right: 12px !important;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  box-shadow: none;
}


@media screen and (max-width: 600px) {
  .admin-home-btn-add {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .admin-home-btn-add {
    font-size: 14px;
    /* Larger font size for tablet */
    padding: 4px 8px;
    /* Adjust padding for tablet if needed */
  }
}

.mobile-btn {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  width: 80%;
}
</style>