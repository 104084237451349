<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="12" sm="8">
                <v-tabs
                  v-model="activeTab"
                  centered
                  :grow="mobile"
                  :slider-size="mobile ? 2 : 4"
                  dense
                >
                  <v-btn
                    v-if="showManagerAddForm || showManagerDetail || showEmployeeAddForm || showEmployeeDetail"
                    icon
                    @click="handleGoBack"
                    size="small"
                    class="mr-2"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-tab class="admin-home-btn" value="Manazeri skol">Manažéri škol</v-tab>
                  <v-tab class="admin-home-btn" value="Zamestnanci">Zamestnanci</v-tab>
                </v-tabs>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center justify-sm-end mt-3 mt-sm-0"
                :block="mobile"
                v-if="!showManagerAddForm && !showManagerDetail && !showEmployeeAddForm && !showEmployeeDetail"
              >
                <v-btn
                  v-if="activeTab === 'Manazeri skol'"
                  @click="addManager"
                  variant="outlined"
                  class="admin-home-btn-add"
                  :block="mobile"
                >
                  Pridať manažéra
                </v-btn>
                <v-btn
                  v-if="activeTab === 'Zamestnanci'"
                  @click="addEmployee"
                  variant="outlined"
                  class="admin-home-btn-add"
                  :block="mobile"
                >
                  Pridať zamestnanca
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text v-if="!showManagerAddForm && !showManagerDetail && !showEmployeeAddForm && !showEmployeeDetail">
            <div class="d-flex justify-space-between align-center mb-4">
              <v-select
                v-model="selectedSchool"
                :items="schools"
                label="Škola"
                outlined
                dense
                hide-details
                variant="solo"
                density="compact"
                
              ></v-select>
              <v-menu
                v-if="activeTab === 'Zamestnanci'"
                v-model="filterMenu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item
                      v-for="filter in filters"
                      :key="filter.value"
                      @click="applyFilter(filter.value)"
                    >
                      <v-list-item-title>{{ filter.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <v-window disabled v-model="activeTab">
              <v-window-item value="Manazeri skol">
                <v-data-table-server
                  :headers="headers"
                  :items="users"
                  dense
                  :items-per-page="itemsPerPage"
                  :page="page"
                  :items-length="totalItems"
                  @update:options="handleTableUpdate"
                >
                  <template v-slot:[`item.schools`]="{ item }">
                    <v-chip
                      :color="item.schools === 'Nešpecifikovaná' ? 'red' : 'purple'"
                      label
                    >
                      {{ item.schools }}
                    </v-chip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="mr-2 custom-button" @click="showInfo(item)">
                      Info
                    </v-btn>
                  </template>
                </v-data-table-server>
              </v-window-item>
              <v-window-item value="Zamestnanci">
                <v-data-table-server
                    :headers="headers"
                    :items="users"
                    dense
                    :items-per-page="itemsPerPage"
                    :page="page"
                    :items-length="totalItems"
                    @update:options="handleTableUpdate"
                  >
                  <template v-slot:[`item.schools`]="{ item }">
                    <v-chip
                      :color="item.schools === 'Nešpecifikovaná' ? 'red' : 'purple'"
                      label
                    >
                      {{ item.schools }}
                    </v-chip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="mr-2 custom-button" @click="showInfo(item)">
                      Info
                    </v-btn>
                  </template>
                </v-data-table-server>
              </v-window-item>
            </v-window>
          </v-card-text>
          <div>
            <ManagerForm
              :schools="schools"
              v-if="showManagerAddForm"
              @manager-added="handleAddedManager"
            />
            <EmployeeForm
              :schools="schools"
              v-if="showEmployeeAddForm"
              @employee-added="handleAddedEmployee"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ManagerDetail
      v-if="showManagerDetail"
      :manager="selectedManager"
      :schools="schools"
      @go-back="showManagerDetail = false"
      @save="updateManager"
    />
    <EmployeeDetail
      v-if="showEmployeeDetail"
      :employee="selectedEmployee"
      :schools="schools"
      @go-back="showEmployeeDetail = false"
      @save="updateEmployee"
    />
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
  </v-container>
</template>


<script>
import { ref, onMounted, watch } from "vue";
import Services from "@/api/services";
import ManagerForm from "@/components/ManagerForm.vue";
import ManagerDetail from "@/components/ManagerDetail.vue";
import { useDisplay } from "vuetify";
import EmployeeDetail from './EmployeeDetail.vue';
import EmployeeForm from './EmployeeForm.vue';



export default {
  components: {
    ManagerForm,
    ManagerDetail,
    // eslint-disable-next-line
    EmployeeDetail,
    EmployeeForm
  },
  setup() {
    const { mobile } = useDisplay()
    const users = ref([]);
    const selectedSchool = ref(null);
    const activeTab = ref("Manazeri skol");
    const filterMenu = ref(false);
    const schools = ref([]);
    const selectedFilter = ref(null);
    const page = ref(1);
    const itemsPerPage = ref(10);
    const totalItems = ref(0);
    const totalPages = ref(0);
    const showManagerAddForm = ref(false);
    const showManagerDetail = ref(false);
    const selectedManager = ref(null);
    const managersInfo = ref([]);
    // eslint-disable-next-line
    const showEmployeeDetail = ref(false);
    const selectedEmployee = ref(null);
    const showEmployeeAddForm = ref(false);

    const addEmployee = () => {
      showEmployeeAddForm.value = true;
    };

    const handleAddedEmployee = () => {
      showSnackbar("Zamestnanec bol úspešne pridaný.", "success");
      showEmployeeAddForm.value = false;
      fetchUsers("employee"); 
    };

    const updateManager = async (manager) => {
      try {
        //fetch school names according to the ids
        await Services.updateManager(manager);
        showSnackbar("Manažér bol úspešne aktualizovaný.", "success");
        fetchUsers("manager");
      } catch (error) {
        showSnackbar("Chyba pri aktualizácii manažéra.", "error");
      }
    };

    const updateEmployee = async () => {
      try {
        fetchUsers("employee");
      } catch (error) {
        console.error("Error updating employee:", error);
      }
    };

    const handleTableUpdate = (options) => {
      const { page, itemsPerPage } = options;
      const userType = activeTab.value === "Manazeri skol" ? "manager" : "employee"; 
      fetchUsers(userType, page, itemsPerPage);
    };
    const handleAddedManager = () => {
      showSnackbar("Manažér bol úspešne pridaný.", "success");
      showManagerAddForm.value = false;
      fetchUsers("manager");
    };
    const handleGoBack = () => {
      showManagerDetail.value = false;
      showManagerAddForm.value = false;
      showEmployeeDetail.value = false;
      showEmployeeAddForm.value = false;
    };

    const addManager = () => {
      showManagerAddForm.value = true;
    };

    const employeesInfo = ref([]);

    const filters = [
      { text: "Žiaci", value: "student" },
      { text: "Zamestnanci", value: "employee" },
      { text: "Externý stravníci", value: "external_diner" },
    ];
    const headers = ref([
      { title: "Meno", align: "start", key: "name" },
      { title: "Pracovný Email", align: "start", key: "email" },
      { title: "Škola", align: "start", key: "schools" },
      { align: "center", key: "actions", sortable: false },
    ]);

    const fetchUsers = async (userType, newPage = page.value, newItemsPerPage = itemsPerPage.value) => {
  try {
    let response;
    let schoolId;
    if (
      selectedSchool.value === null ||
      selectedSchool.value === "Všetky školy"
    ) {
      schoolId = null;
    } else {
      schoolId = await Services.getSchoolIdByName(selectedSchool.value);
    }
    console.log("selectedSchool:", selectedSchool.value);
    console.log("Fetching users:", userType, "Page:", newPage, "Items per page:", newItemsPerPage);

    if (userType === "manager") {
      response = await Services.getManagers(
        newPage,
        newItemsPerPage,
        schoolId
      );
    } else if (userType === "employee") {
      response = await Services.getEmployees(
        newPage,
        newItemsPerPage,
        schoolId
      );
    }

    users.value = response.items.map((user) => ({
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      user_type: user.user_type,
      schools:
        userType === "manager"
          ? user.managed_schools && user.managed_schools.length > 0
            ? user.managed_schools.map((school) => school.name).join(", ")
            : "Nešpecifikovaná"
          : user.school || "Nešpecifikovaná",
    }));

    if (userType === "manager") {
      managersInfo.value = response;
    } else if (userType === "employee") {
      employeesInfo.value = response;
    }
    console.log(response);

    // Update pagination information
    totalItems.value = response.total_items;
    totalPages.value = response.total_pages;
    page.value = response.page;
    itemsPerPage.value = response.items_per_page;

    console.log("Total items:", totalItems.value, "Total pages:", totalPages.value);
  } catch (error) {
    console.error("Error fetching users:", error);
    showSnackbar(`Chyba pri načítaní ${userType === 'manager' ? 'manažérov' : 'zamestnancov'}.`, "error");
  }
};



    const fetchSchools = async () => {
      try {
        const response = await Services.getSchools();
        schools.value = response.map((school) => school.name);
        schools.value.unshift("Všetky školy");
        selectedSchool.value = "Všetky školy";
      } catch (error) {
        console.error("Error fetching schools:", error);
        showSnackbar("Chyba pri načítaní škôl.", "error");
      }
    };

    watch(selectedSchool, (newSchool) => {
      selectedSchool.value = newSchool;
      if (activeTab.value === "Manazeri skol") {
        fetchUsers("manager");
      } else if (activeTab.value === "Zamestnanci") {
        fetchUsers("employee");
      }
    });

    onMounted(() => {
      fetchSchools();
      fetchUsers("manager");
    });

    watch(activeTab, (newTab) => {
      let userType;
      showManagerAddForm.value = false;
      showManagerDetail.value = false;
      showEmployeeDetail.value = false;
      showEmployeeAddForm.value = false;
      if (newTab === "Manazeri skol") {
        userType = "manager";
      } else if (newTab === "Zamestnanci") {
        userType = "employee";
      } else if (newTab === "Uchadzaci na Ucitelov") {
        userType = "applicant";
      }
      fetchUsers(userType);
    });

    const applyFilter = (filter) => {
      selectedFilter.value = filter;
      fetchUsers(activeTab.value);
      filterMenu.value = false;
    };

    const showInfo = async (item) => {
  try {
    console.log("item:", item);
    if (activeTab.value === 'Manazeri skol') {
      const managerId = managersInfo.value.items.find(
        (manager) => manager.email === item.email
      ).id;
      const managerDetails = await Services.getManagerById(managerId);
      console.log("managerDetails:", managerDetails);
      selectedManager.value = { ...managerDetails };
      showManagerDetail.value = true;
    } else if (activeTab.value === 'Zamestnanci') {
      const employeeId = employeesInfo.value.items.find(
        (employee) => employee.email === item.email
      ).id;
      const employeeDetails = await Services.getEmployeeById(employeeId);
      console.log("employeeDetails:", employeeDetails);
      selectedEmployee.value = { ...employeeDetails };
      showEmployeeDetail.value = true;
    }
  } catch (error) {
    console.error(`Error fetching ${activeTab.value === 'Manazeri skol' ? 'manager' : 'employee'} details:`, error);
    showSnackbar(`Nepodarilo sa načítať detail ${activeTab.value === 'Manazeri skol' ? 'manažéra' : 'zamestnanca'}.`, "error");
  }
};

const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
        snackbarText.value = text;
        snackbarColor.value = color;
        snackbar.value = true;
    };

    return {
      users,
      selectedSchool,
      activeTab,
      filterMenu,
      schools,
      filters,
      headers,
      fetchUsers,
      applyFilter,
      page,
      itemsPerPage,
      totalItems,
      totalPages,
      addManager,
      showInfo,
      ManagerForm,
      showManagerAddForm,
      handleGoBack,
      handleAddedManager,
      showManagerDetail,
      selectedManager,
      updateManager,
      mobile,
      showEmployeeDetail,
      selectedEmployee,
      updateEmployee,
      showEmployeeAddForm,
      addEmployee,
      handleAddedEmployee,
      handleTableUpdate,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.admin-home-btn {
  text-transform: none;
  font-weight: bold;
  color: purple; /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
}

.selected {
  color: black;
  text-decoration: underline;
}

.rounded-corners {
  border-radius: 10px;
}

.header-cell {
  background-color: #f5f5f5;
  color: black;
  font-weight: bold;
}
.admin-home-btn-add {
  text-transform: none;
  font-weight: bold;
  color: purple; /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px; /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5; /* Adjust line-height to ensure the text is centered */
  display: inline-block; /* Ensure the button is displayed as an inline element */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.admin-home-btn-add:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple; /* Change the text color to purple */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px; /* Adjust padding to ensure the outline fits the text */
  line-height: 1.5; /* Adjust line-height to ensure the text is centered */
  display: inline-block; /* Ensure the button is displayed as an inline element */
}
@media screen and (max-width: 600px) {
  .admin-home-btn-add {
    font-size: 12px; /* Smaller font size for mobile */
    padding: 2px 6px; /* Adjust padding for mobile if needed */
  }
}

@media screen and (max-width: 960px) {
  .admin-home-btn-add {
    font-size: 14px; /* Larger font size for tablet */
    padding: 4px 8px; /* Adjust padding for tablet if needed */
  }
}
</style>
