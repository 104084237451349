<template>
  <v-container fluid class="p-0 mt-n4">
    <div class="calendar-container">
      <v-container fluid class="pa-0">
    <v-row class="align-center" dense>
      <v-col cols="12" sm="6" class="py-1">
        <v-select
          v-model="selectedChild"
          :items="childrenOptions"
          label="Vyberte dieťa"
          variant="solo"
          density="compact"
          hide-details
          @update:model-value="onChildChange"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" class="py-1">
        <div class="credits-section d-flex align-center justify-end">
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="purple"
            size="24"
            class="mr-2"
          ></v-progress-circular>
          <span v-else class="credits-text mr-2">Kredity: {{ totalCredits.toFixed(2) }}</span>
          <v-btn
            icon
            small
            :class="{ 'disable-events': customCondition }"
            @click="addCredits"
            :disabled="isLoading"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <div class="calendar-wrapper">
    <vue-cal
  ref="vuecal"
  class="vuecal"
  :events="events"
  :time="false"
  :active-view="'month'"
  :disable-views="['year', 'week', 'day']"
  hide-view-selector
  :cell-height="80"
  :min-cell-width="120"
  @cell-click="openMealModal"
  :hide-offset-dates="true"
  @event-click="openMealModal"
  :events-on-month-view="true"
  :disable-days="[6, 7]"
  locale="sk"
  :key="eventsKey"
  @view-change="fetchCalendarData"
  hide-weekends
  hide-extra-days
  :weekdays-headings="weekdaysHeadings"
  :time-from="new Date()" 
>
<template #event="{ event }">
  <div
    class="custom-event"
    :class="[
      event.class,
      { 'insufficient-credits': isEventInInsufficientCreditsZone(event) },
      { 'unclickable': !isClickable(event) || isPastEvent(event) }
    ]"
  >
    <v-chip
      :color="getEventChipColor(event)"
      :text-color="event.title === 'Nie je k dispozícii' || event.title === 'Jedálniček nie je k dispozícii' ? 'grey darken-2' : 'white'"
      :size="$vuetify.display.mdAndUp ? 'small' : 'x-small'"
      class="event-chip"
      :class="{ 'desktop-chip': $vuetify.display.mdAndUp }"
      @click="openMealModal(event)"
      :disabled="!isClickable(event) || isPastEvent(event)"
    >
      <span class="event-text">{{ event.title }}</span>
    </v-chip>
  </div>
</template>
</vue-cal>
  </div>

      <v-dialog v-model="dialogVisible" max-width="800">
        <v-card class="d-flex flex-column" style="max-height: 90vh;">
          <v-card-title class="headline purple white--text d-flex align-center py-2">
            <v-icon left color="white" size="small">mdi-calendar</v-icon>
            Výber jedla pre {{ selectedDate }}
          </v-card-title>
          <v-card-text class="py-2 flex-grow-0">
            <v-select v-model="selectedSchool" :items="schoolOptions" label="Vyberte školu" variant="outlined"
              density="compact" @change="fetchMealsForSelectedSchool" class="mb-2" :disabled="isEventPaid" hide-details>
              <template v-slot:prepend-inner>
                <v-icon size="small">mdi-school</v-icon>
              </template>
            </v-select>
          </v-card-text>
          <v-card-text class="flex-grow-1 pa-0" style="overflow: hidden;">
            <v-progress-circular v-if="loadingMeals" indeterminate color="purple" size="24"
              class="my-2 d-flex mx-auto"></v-progress-circular>
            <div v-else class="meal-cards-container" style="overflow-x: auto; white-space: nowrap; padding: 16px;">
              <div v-if="availableMeals && availableMeals.length > 0" class="d-inline-flex">
                <v-card v-for="meal in availableMeals" :key="meal.id" hover class="meal-card d-inline-block mr-4"
                  :disabled="isEventPaid || !meal.canBeOrdered"
                  :elevation="selectedMeal && selectedMeal.id === meal.id ? 8 : 2" width="320" height="280"
                  style="white-space: normal; vertical-align: top;">
                  <v-card-title
                    class="subtitle-1 font-weight-bold purple--text d-flex justify-space-between align-center py-2">
                    {{ meal.name }}
                    <v-icon v-if="selectedMeal && selectedMeal.id === meal.id" color="purple">mdi-check-circle</v-icon>
                  </v-card-title>
                  <v-card-text class="d-flex flex-column" style="height: calc(100% - 64px);">
                    <p class="mb-2 text--secondary" style="flex-grow: 1; overflow-y: auto;">{{ meal.description }}</p>
                    <div>
                      <div class="d-flex justify-space-between align-center mb-2">
                        <v-chip color="purple" text-color="white"
                          :variant="selectedMeal && selectedMeal.id === meal.id && selectedPriceType === 'full' ? 'elevated' : 'outlined'"
                          size="small" class="mr-2" @click="selectMeal(meal, 'full')" :disabled="isEventPaid">
                          <v-icon size="x-small" left
                            v-if="selectedMeal && selectedMeal.id === meal.id && selectedPriceType === 'full'">mdi-check</v-icon>
                          Normálna: €{{ meal.full_price.toFixed(2) }}
                        </v-chip>
                        <v-chip color="purple" text-color="white"
                          :variant="selectedMeal && selectedMeal.id === meal.id && selectedPriceType === 'half' ? 'elevated' : 'outlined'"
                          size="small" @click="selectMeal(meal, 'half')" :disabled="isEventPaid">
                          <v-icon size="x-small" left
                            v-if="selectedMeal && selectedMeal.id === meal.id && selectedPriceType === 'half'">mdi-check</v-icon>
                          Polovičná: €{{ meal.half_price.toFixed(2) }}
                        </v-chip>
                      </div>
                      <p v-if="meal.allergens" class="text-caption mb-0">
                        <v-icon size="x-small" color="orange darken-2">mdi-alert-circle</v-icon>
                        <strong>Alergény:</strong> {{ meal.allergens.join(", ") }}
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <p v-else class="text-center">Žiadne jedlá nie sú dostupné</p>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0 pb-2">
            <v-btn color="purple" @click="confirmMealSelection" :disabled="!selectedMeal || isEventPaid">
              Potvrdiť výber
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="dialogVisible = false">
              Zatvoriť
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row class="mt-2 flex-column flex-sm-row">
    <v-col cols="12" sm="8" md="6" order="2" order-sm="1" class="py-1">
      <div class="d-flex flex-column align-start">
        <div class="legend-item mb-1">
          <div class="legend-chip green"></div>
          <span><strong>Uhradené jedlo</strong></span>
        </div>
        <div class="legend-item mb-1">
          <div class="legend-chip orange"></div>
          <span><strong>Neuhradené jedlo</strong></span>
        </div>
        <div class="legend-item">
          <div class="legend-chip red"></div>
          <span><strong>Nedostatok kreditov</strong></span>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="4" md="6" order="1" order-sm="2" class="d-flex justify-start justify-sm-end py-1 mb-2 mb-sm-0">
      <div class="payment-section">
        <v-chip 
          :color="insufficientCredits ? 'error' : 'success'" 
          text-color="white" 
          class="px-3 py-1" 
          size="small"
        >
          <v-icon left size="small">
            {{ insufficientCredits ? "mdi-alert-circle" : "mdi-check-circle" }}
          </v-icon>
          <span class="text-caption">Kredity po úhrade: {{ remainingCreditsAfterPayment.toFixed(2) }} €</span>
        </v-chip>
      </div>
    </v-col>
  </v-row>
    </div>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { onMounted, ref, computed, watch } from "vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import Services from "@/api/services";

export default {
  components: {
    VueCal,
  },
  setup() {
    const selectedDate = ref("");
    const dialogVisible = ref(false);
    const selectedMeal = ref(null);
    const today = new Date();
    const schoolMeals = ref({});
    const availableMeals = ref([]);
    const children = ref([]);
    const vueCal = ref(null);
    const eventsKey = ref(0);
    const mealEvents = ref([]);
    const loadingMeals = ref(false);
    const schoolOptions = ref([]);
    const selectedSchool = ref(null);
    const parentId = ref(null);
    const credits = ref([]);
    const isLoading = ref(true);
    const dataLoaded = ref(false);
    const selectedChild = ref(null);
    const childrenOptions = ref([]);

    const unpaidSelections = computed(() => {
      if (!dataLoaded.value && !mealEvents.value) return [];
      console.log("Unpaid selections", events.value);
      return events.value.filter((event) => event.class === "neuhradené");
    });

    const onChildChange = () => {
      if (selectedChild.value) {
        fetchMeals();
      }
    }
    const populateChildOptions = () => {
      childrenOptions.value = children.value.map((child) => child.first_name + ' ' + child.last_name);
    };

    const eventsInInsufficientCreditsZone = computed(() => {
      if (!dataLoaded.value) return [];
      let runningTotal = totalCredits.value;

      const insufficientEvents = events.value
        .filter(event => event.class === 'neuhradené')
        .sort((a, b) => new Date(a.start) - new Date(b.start))
        .reduce((acc, event) => {
          const eventPrice = event.selected_price_type === 'full' ? event.full_price : event.half_price;
          console.log(`Event: ${event.title}, Price: ${eventPrice}, Running Total: ${runningTotal}`);
          if (runningTotal < eventPrice) {
            acc.push(event);
          } else {
            runningTotal -= eventPrice;
          }
          return acc;
        }, []);

      return insufficientEvents;
    });

    const confirmMealSelection = async () => {
      try {
        if (selectedMeal.value) {
          await assignMeal(selectedMeal.value);
        } else {
          await Services.deleteMealSelectionForDate(children.value[0].id, selectedDate.value);
          console.log("Meal selection deleted");
        }
        // Update local state or fetch updated data as needed
        await fetchCalendarData();
        dialogVisible.value = false;
      } catch (error) {
        console.error("Error confirming meal selection:", error);
        showSnackbar("Nepodarilo sa potvrdiť výber jedla", "error");
      }
    };

    const isEventInInsufficientCreditsZone = (event) => {
      const isInsufficient = eventsInInsufficientCreditsZone.value.some(e => e.meal_id === event.meal_id);
      console.log(`Event: ${event.title}, Is Insufficient: ${isInsufficient}`);
      return isInsufficient;
    };

    const getEventChipColor = (event) => {
      if (!isClickable.value(event)) {
        return 'grey';
      }
      else if (event.title === "Nevybrané jedlo") {
        return "purple";
      } else if (event.title === "Jedálniček nie je k dispozícii") {
        return "grey";
      } else {
        if (event.class === "uhradené") {
          return "green";
        } else if (event.class === "neuhradené") {
          return isEventInInsufficientCreditsZone(event) ? "red" : "orange";
        }
      }
    };

    const isMobile = computed(() => {
      return window.innerWidth < 960;
    });

    const getEventChipClass = (event) => {
      if (event.class === 'uhradené') return 'green';
      if (event.class === 'neuhradené') {
        return isEventInInsufficientCreditsZone(event) ? 'red' : 'orange';
      }
      return '';
    };
    const hasUnpaidSelections = computed(
      () => unpaidSelections.value.length > 0
    );

    const totalUnpaidAmount = computed(() => {
      if (!dataLoaded.value) return 0;
      return unpaidSelections.value.reduce((total, selection) => {
        const selectionPrice = selection.selected_price_type === 'full' ? selection.full_price : selection.half_price;
        return total + (selectionPrice || 0);
      }, 0);
    });

    const remainingCreditsAfterPayment = computed(() => {
      if (!dataLoaded.value) return 0;
      console.log("Total credits", totalCredits.value);
      console.log("Total unpaid amount", totalUnpaidAmount.value);
      return totalCredits.value - totalUnpaidAmount.value;
    });

    const insufficientCredits = computed(() => {
      if (!dataLoaded.value) return false;
      return remainingCreditsAfterPayment.value < 0;
    });

    const payForMealSelections = async () => {
      try {
        const parentId = await Services.getUserIdByEmail(
          localStorage.getItem("email")
        );
        // Call your API to pay for meal selections
        await Services.payMealSelections(parentId);
        // Refresh the calendar data and credits
        await fetchCalendarData();
        await fetchUserCredits();
        // Show success message
        // You might want to use a notification system here
        showSnackbar("Platba bola úspešná", "success");
      } catch (error) {
        console.error("Error paying for meal selections:", error);
        showSnackbar("Nepodarilo sa zaplatiť za jedlá", "error");
      }
    };

    const totalCredits = computed(() => {
      if (credits.value.length === 0) return 0;
      return credits.value.reduce((sum, credit) => sum + credit.amount, 0);
    });

    const fetchSchools = async () => {
      try {
        const response = await Services.getSchools();
        schoolOptions.value = response.map((school) => school.name);
      } catch (error) {
        console.error("Error fetching schools:", error);
        showSnackbar("Nepodarilo sa načítať školy", "error");
      }
    };

    // eslint-disable-next-line
    const fetchMealsForSelectedSchool = async () => {
      if (!selectedSchool.value) return;
      const schoolId = await Services.getSchoolIdByName(selectedSchool.value);
      console.log(
        "Fetching meals for selected school",
        selectedSchool.value,
        selectedDate.value
      );
      loadingMeals.value = true;
      const selectedChildId = children.value.find(
        (child) => child.first_name + ' ' + child.last_name === selectedChild.value
      ).id;
      try {
        const meals = await Services.getMealsForSchoolAndDate(
          schoolId,
          selectedDate.value,
          selectedChildId
        );

        const now = new Date();
        availableMeals.value = meals.map(meal => {
          const mealDate = new Date(meal.date);
          const [hours, minutes] = meal.order_modification_limit_time.split(':');
          const limitTime = new Date(mealDate);
          limitTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
          limitTime.setDate(limitTime.getDate() - 1); // Set to the day before

          const canBeOrdered = mealDate >= new Date(now.toDateString()) && now <= limitTime;

          return {
            ...meal,
            full_price: meal.full_price || meal.price_student,
            half_price: meal.half_price || meal.price_student / 2,
            canBeOrdered: canBeOrdered
          };
        });
        console.log("Available meals", availableMeals.value);
      } catch (error) {
        console.error("Error fetching meals:", error);
        showSnackbar("Chyba pri načítaní jedál. Skúste to znova.", "error");
      } finally {
        loadingMeals.value = false;
      }
    };

    const fetchUserCredits = async () => {
      try {
        isLoading.value = true;
        const parentId = await Services.getUserIdByEmail(
          localStorage.getItem("email")
        );
        credits.value = await Services.getUserCredits(parentId);
        isLoading.value = false;
      } catch (error) {
        credits.value = [];
        isLoading.value = false;
        console.error("Failed to fetch user credits:", error);
        showSnackbar("Nepodarilo sa načítať kredity. Skúste to znova neskôr.", "error");
      }
    };

    const fetchCalendarData = async () => {
      try {
        mealEvents.value = [];
        console.log("Fetching calendar data...");
        // Fetch calendar data based on the current view's date range
        const currentView = vueCal.value.view;
        currentDate.value = new Date(formatToDateInput(currentView.startDate));
        currentMonth.value = currentDate.value.getMonth() + 1;
        currentYear.value = currentDate.value.getFullYear();
        console.log("Current view:", currentDate.value);
        fetchMeals();
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        showSnackbar("Nepodarilo sa načítať kalendár. Skúste to znova.", "error");
      }
    };

    const previous = () => {
      vueCal.value.previous();
      fetchCalendarData();
    };

    const next = () => {
      vueCal.value.next();
      fetchCalendarData();
    };
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only dates

    const selectedPriceType = ref('full');

    const selectMeal = (meal, priceType) => {
      if (selectedMeal.value && selectedMeal.value.id === meal.id && selectedPriceType.value === priceType) {
        selectedMeal.value = null;
        selectedPriceType.value = null;
      } else {
        selectedMeal.value = meal;
        selectedPriceType.value = priceType;
      }
    };

    const assignMeal = async (meal) => {
      try {
        console.log("Selected school", selectedSchool.value);
        const schoolId = await Services.getSchoolIdByName(selectedSchool.value);

        const calculatedPrice = selectedPriceType.value === 'full' ? meal.full_price : meal.half_price;

        const response = await Services.createStudentMealSelection({
          student_id: child.value.id,
          meal_id: meal.id,
          date: selectedDate.value,
          status: "Neuhradené",
          school_id: schoolId,
          calculated_price: calculatedPrice,
          selected_price_type: selectedPriceType.value
        });

        console.log("Meal assigned:", response);
        if (response.message === "Meal selection created/updated successfully") {
          showSnackbar("Jedlo bolo úspešne priradené", "success");
          events.value = [];
          console.log("Events", events.value);
        } else {
          showSnackbar("Nepodarilo sa priradiť jedlo", "error");
        }
        dialogVisible.value = false;
        selectedMeal.value = null;
        events.value = [];
        await fetchMeals();

      } catch (error) {
        console.error("Error assigning meal:", error);
        showSnackbar("Chyba pri priraďovaní jedla. Skúste to znova.", "error");
      }
    };


    const currentDate = ref(null);
    const currentMonth = ref(null);
    const currentYear = ref(null);

    watch(selectedSchool, () => {
      fetchMealsForSelectedSchool();
    });

    const isEventPaid = computed(() => {
      const event = events.value.find(e => e.start === selectedDate.value);
      return event && event.class === 'uhradené';
    });

    const retry = async (fn, maxAttempts = 3) => {
      for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        try {
          return await fn();
        } catch (error) {
          if (attempt === maxAttempts) throw error;
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
        }
      }
    };

    onMounted(async () => {
      currentDate.value = new Date();
      currentMonth.value = currentDate.value.getMonth() + 1;
      currentYear.value = currentDate.value.getFullYear();

      try {
        parentId.value = await Services.getUserIdByEmail(localStorage.getItem("email"));
        children.value = await Services.getMeals(parentId.value);

        populateChildOptions();

        if (children.value.length > 0) {
          selectedChild.value = children.value[0].first_name + ' ' + children.value[0].last_name;
        }

        await Promise.all([
          retry(() => fetchMeals()),
          retry(() => fetchUserCredits()),
          retry(() => fetchSchools()),
        ]);

        console.log("Všetky dáta boli úspešne načítané.");
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error fetching data:", error);
        showSnackbar("Nepodarilo sa načítať dáta. Skúste to znova.", "error");
      }
    });
    const child = ref(null);
    const fetchMeals = async () => {
      try {
        children.value = await Services.getMeals(parentId.value);
        console.log("Children", children.value);
        const mealEvents = [];
        const allSchoolMeals = {};

        child.value = children.value.find(c => c.first_name + ' ' + c.last_name === selectedChild.value);
        if (!child.value) return;

        const schoolIds = new Set([child.value.dining_school.id]);
        child.value.meal_selections.forEach(selection => {
          if (selection.school_id) schoolIds.add(selection.school_id);
        });
        if (!child.value) {
          showSnackbar("Nebolo možné nájsť vybrané dieťa", "warning");
          return;
        }
        console.log("School IDs", schoolIds);

        for (const schoolId of schoolIds) {
          console.log(
            "Fetching meals for school",
            schoolId,
            currentYear.value,
            currentMonth.value
          );

          const meals = await Services.getMealsForSchoolAndMonth(
            schoolId,
            currentYear.value,
            currentMonth.value,
            child.value.id
          );
          console.log("Meals for school", schoolId, meals);
          allSchoolMeals[schoolId] = meals;
        }

        schoolMeals.value = allSchoolMeals;
        console.log("School meals", schoolMeals.value);

        const mealSelectionMap = new Map(
          child.value.meal_selections.map(selection => [selection.date, selection])
        );

        const processedDates = new Set();

        Object.values(allSchoolMeals).forEach(schoolMeals => {
          schoolMeals.forEach((schoolMeal) => {
            if (!processedDates.has(schoolMeal.date)) {
              processedDates.add(schoolMeal.date);

              const mealSelection = mealSelectionMap.get(schoolMeal.date);
              console.log("Meal selection for date", schoolMeal.date, mealSelection, allSchoolMeals);

              if (mealSelection) {
                const selectedMeal = allSchoolMeals[mealSelection.school_id]?.find(m => m.meal_id === mealSelection.meal_id);

                console.log("Selected meal", selectedMeal);
                mealEvents.push({
                  start: new Date(mealSelection.date),
                  end: new Date(mealSelection.date),
                  title: selectedMeal ? selectedMeal.name : "Unknown Meal",
                  content: mealSelection.is_paid ? 'Uhradené' : mealSelection.status,
                  class: mealSelection.is_paid ? 'uhradene' : mealSelection.status.toLowerCase(),
                  meal_id: mealSelection.meal_id,
                  full_price: selectedMeal ? selectedMeal.full_price : 0,
                  half_price: selectedMeal ? selectedMeal.half_price : 0,
                  selected_price_type: mealSelection.selected_price_type || 'full',
                  school_id: mealSelection.school_id,
                  order_modification_limit_time: selectedMeal ? selectedMeal.order_modification_limit : null
                });
              } else if (schoolMeal.name) {
                mealEvents.push({
                  start: new Date(schoolMeal.date),
                  end: new Date(schoolMeal.date),
                  title: "Nevybrané jedlo",
                  content: "Student nemá vybrané jedlo",
                  class: "nevybrane",
                  meal_id: null,
                  full_price: schoolMeal.full_price || 0,
                  half_price: schoolMeal.half_price || 0,
                  selected_price_type: 'full',
                  school_id: schoolMeal.supplier_id,
                  order_modification_limit_time: schoolMeal.order_modification_limit
                });
              } else {
                mealEvents.push({
                  start: new Date(schoolMeal.date),
                  end: new Date(schoolMeal.date),
                  title: "Jedálniček nie je k dispozícii",
                  content: "Jedálniček pre tento deň ešte nebol zverejnený",
                  class: "nedostupne",
                  meal_id: null,
                  full_price: 0,
                  half_price: 0,
                  selected_price_type: 'full',
                  school_id: schoolMeal.supplier_id,
                  order_modification_limit_time: null
                });
              }
            }
          });
        });

        // Add events for dates with no meals or selections
        const allDates = getDatesInMonth(currentYear.value, currentMonth.value);
        allDates.forEach(date => {
          if (!processedDates.has(date)) {
            mealEvents.push({
              start: new Date(date),
              end: new Date(date),
              title: "Nie je k dispozícii",
              content: "Jedálniček pre tento deň ešte nebol zverejnený",
              class: "nedostupne",
              meal_id: null,
              full_price: 0,
              half_price: 0,
              selected_price_type: 'full',
              school_id: null,
              order_modification_limit_time: null
            });
          }
        });
        console.log("Meal events", mealEvents);
        events.value = mealEvents;
      } catch (error) {
        console.error("Error fetching meals:", error);
        showSnackbar("Chyba pri načítaní jedál. Skúste to znova.", "error");
      }
    };

    function getDatesInMonth(year, month) {
      const date = new Date(year, month - 1, 1);
      const dates = [];
      while (date.getMonth() === month - 1 || date.getDate() === 1) {
        dates.push(date.toISOString().split('T')[0]);
        date.setDate(date.getDate() + 1);
      }
      return dates;
    }


    const getMealsForDate = (date) => {
      const formattedDate = formatDate(date);
      const mealsForDate = [];

      Object.values(schoolMeals.value).forEach((schoolMealList) => {
        const mealsForThisSchool = schoolMealList.filter(
          (meal) => meal.date === formattedDate
        );
        mealsForDate.push(...mealsForThisSchool);
      });

      console.log("Meals for date", mealsForDate);
      return mealsForDate;
    };
    const events = ref(
      mealEvents.value.map((meal) => ({
        start: meal.date,
        end: meal.date,
        title: meal.name,
        content: meal.status,
        class: meal.status.toLowerCase(),
      }))
    );

    const getMealForDate = (date) => {
      if (!isPastDate(date)) {
        selectedDate.value = date;
        availableMeals.value.append(getMealsForDate(date));
        dialogVisible.value = true;
      }
    };

    const isClickable = computed(() => {
      return (event) => {
        if (event.title === 'Nie je k dispozícii' || event.content === 'Uhradené') {
          return false;
        }
        return !isPastDate(event.date);
      };
    });
    const isPastDate = (date) => {
      const formattedDate = formatToDateInput(date);
      const cellDate = new Date(formattedDate);
      if (cellDate === "Invalid Date") {
        console.error("Invalid date provided");
        return false;
      }

      cellDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only dates

      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only dates
      return cellDate < todayDate;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const month = `${d.getMonth() + 1}`.padStart(2, "0");
      const day = `${d.getDate()}`.padStart(2, "0");
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };

    //Sun Jun 30 2024 09:22:00 GMT+0200 (stredoeurópsky letný čas) to
    function formatToDateInput(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    const openMealModal = async (cellOrEvent) => {
      let cell
      console.log("Cell or event", cellOrEvent);
      if (cellOrEvent.class !== "nedostupne" && cellOrEvent.class !== "uhradené") {
        if (cellOrEvent.start) {
          cell = cellOrEvent.start;
        } else {
          cell = cellOrEvent.startDate;
        }
        const formattedDate = formatToDateInput(cell);
        const date = new Date(formattedDate);
        if (!isNaN(date.getTime()) && !isPastDate(date)) {
          selectedDate.value = formatDate(date);

          // Reset selectedMeal and availableMeals
          selectedMeal.value = null;
          selectedPriceType.value = null;
          availableMeals.value = [];


          const existingSelection = child.value.meal_selections.find(
            (selection) => selection.date === selectedDate.value
          );
          console.log("Existing selection", existingSelection);

          if (existingSelection) {
            const school = await Services.getSchool(existingSelection.school_id);
            selectedSchool.value = school.name;
          } else {
            selectedSchool.value = child.value.dining_school.name;
          }

          // Fetch meals before opening the dialog
          await fetchMealsForSelectedSchool();

          // Now set the selectedMeal if there's an existing selection
          if (existingSelection) {
            selectedMeal.value = availableMeals.value.find(meal => meal.id === existingSelection.meal_id) || null;
            selectedPriceType.value = existingSelection.selected_price_type || 'full';
          }

          dialogVisible.value = true;
        }
      }
    };



    const onEventClick = (event, e) => {
      e.stopPropagation();
      openMealModal(event);
    };

    const addCredits = async () => {
      try {

        const result = await Services.addCredits(parentId.value);
        showSnackbar("Kredity boli úspešne pridané", "success");
        await fetchUserCredits();
        console.log("Credits added successfully:", result);
      } catch (error) {
        showSnackbar("Nepodarilo sa pridať kredity", "error");
      }
    };
    const isPastEvent = (event) => {
      const eventDate = new Date(event.start);
      eventDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only dates
      return eventDate < today;
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      events,
      dialogVisible,
      selectedDate,
      selectedMeal,
      openMealModal,
      getMealForDate,
      onEventClick,
      addCredits,
      isPastDate,
      availableMeals,
      eventsKey,
      assignMeal,
      selectMeal,
      vueCal,
      previous,
      next,
      fetchCalendarData,
      totalCredits,
      isLoading,
      fetchMealsForSelectedSchool,
      selectedSchool,
      loadingMeals,
      schoolOptions,
      hasUnpaidSelections,
      insufficientCredits,
      payForMealSelections,
      remainingCreditsAfterPayment,
      getEventChipClass,
      isEventInInsufficientCreditsZone,
      confirmMealSelection,
      isEventPaid,
      onChildChange,
      selectedChild,
      childrenOptions,
      selectedPriceType,
      getEventChipColor,
      isClickable,
      isMobile,
      isPastEvent,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>
<style scoped>
::v-deep .vuecal__title-bar {
  background-color: purple !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4em;
  line-height: 1.3;
  min-height: 2em;
  padding: 0 8px;
}

::v-deep .vuecal__title-bar button {
  color: white !important;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.calendar-container {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  width: 100%;
}

.credits-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 4px;
}

.credits-section span {
  margin-right: 10px;
  font-weight: bold;
}

.vuecal {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 600px;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.vuecal__header button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.vuecal__cell {
  border: 1px solid #e0e0e0;
}

.vuecal__cell--today {
  background-color: #e3f2fd;
}

.vuecal__cell-date {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.7em;
  color: #666;
}

.cell-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cell-content.weekend,
.cell-content.past {
  background-color: #f5f5f5;
  pointer-events: none;
}

.date-number {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.7em;
  color: #666;
}

.custom-event {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  font-size: 12px;
  text-align: center;
  position: relative;
  background: white;
  margin: 1px;
  transition: background-color 0.3s ease;
}

.custom-event::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.transparent-chip {
  background-color: transparent !important;
  box-shadow: none !important;
}

.event-chip {
  height: auto !important;
  white-space: normal !important;
  padding: 4px 8px !important; /* Increased padding */
}

.desktop-chip {
  font-size: 0.9rem !important; /* Increased font size */
}

.event-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem; /* Slightly decreased from 0.8rem */
  line-height: 1.2;
}

.vuecal__weekdays-headings {
  display: flex;
  background-color: purple;
  color: white;
  font-weight: bold;
  padding: 8px 0;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.legend-section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.legend-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-chip {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}

.legend-chip.green {
  background-color: #4caf50;
}

.legend-chip.orange {
  background-color: #ffc107;
}

.legend-chip.red {
  background-color: #f44336;
}

.unclickable {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
  color: #e0e0e0 !important;
}

.grey-background {
  background-color: #9e9e9e !important;
}

.v-chip.grey-background {
  color: white !important;
}

.mobile-event {
  height: auto !important;
  white-space: normal !important;
  padding: 4px 8px !important; /* Increased padding */
}

.mobile-event-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem; /* Slightly decreased from 0.8rem */
  line-height: 1.2;
}

.credits-text {
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .vuecal {
    max-width: 100%;
    width: 100%;
  }

  ::v-deep .vuecal__cell {
    padding: 2px;
  }

  ::v-deep .vuecal__cell-content {
    padding: 2px;
  }

  .mobile-event {
    height: auto !important;
    white-space: normal !important;
    padding: 3px 6px !important; /* Adjusted for smaller screens */
  }

  .mobile-event-text {
    font-size: 0.7rem; /* Slightly decreased from 0.75rem */
    line-height: 1.1;
  }
}

@media (max-width: 768px) {
  .calendar-container {
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    margin-left: -28px; /* Adjust this value based on your layout's padding */
    margin-right: -28px;
  }

  .vuecal {
    width: 100vw;
    max-width: 100vw;
    margin-left: 0; /* Removed calc(-50vw + 50%) */
    margin-right: 0; /* Ensure no right margin */
  }

  .credits-text {
    font-size: 0.9rem;
  }

  .payment-section {
    width: 100%;
  }

  .payment-section .v-chip {
    width: 100%;
    justify-content: center;
  }

  .legend-item {
    margin-bottom: 4px;
  }

  ::v-deep .vuecal__weekdays-headings {
    padding-left: 0;
    padding-right: 0;
  }

  ::v-deep .vuecal__body {
    width: 100vw;
  }

  ::v-deep .vuecal__cell {
    padding: 0;
  }

  ::v-deep .vuecal__cell-content {
    padding: 1px;
  }

  ::v-deep .vuecal__title-bar {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mobile-event-text {
    font-size: 0.65rem; /* Slightly decreased from 0.7rem */
    line-height: 1;
  }
}

@media (max-width: 480px) {
  ::v-deep .vuecal__cell {
    padding: 0;
  }

  .calendar-container {
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    margin-left: -28px; /* Adjust this value based on your layout's padding */
    margin-right: -28px;
  }

  ::v-deep .vuecal__cell-content {
    padding: 1px;
  }

  .mobile-event {
    padding: 2px 4px !important; /* Adjusted for even smaller screens */
  }

  .mobile-event-text {
    font-size: 0.6rem; /* Slightly decreased from 0.65rem */
    line-height: 1;
  }
}
</style>