<template>
  <v-container fluid class="p-0">
    <v-row>
      <v-col cols="12" md="6" lg="5">
        <v-form ref="form" @submit.prevent="submit" class="form-container">
          <v-text-field
            v-model="school.name"
            :rules="[rules.required]"
            label="Meno školy"
            variant="underlined"
            class="input-field"
            required
          ></v-text-field>
          <v-text-field
            v-model="school.address"
            :rules="[rules.required]"
            label="Adresa"
            variant="underlined"
            class="input-field"
            required
          ></v-text-field>
          <v-text-field
            v-model="school.email"
            :rules="[rules.required, rules.email]"
            label="Email"
            variant="underlined"
            class="input-field"
            required
          ></v-text-field>
          <v-text-field
            v-model="school.phone_number"
            :rules="[rules.required, rules.phoneNumber]"
            label="Telefónne číslo"
            variant="underlined"
            class="input-field"
            required
          ></v-text-field>
          <v-row class="mt-6">
            <v-col cols="12" class="d-flex justify-start">
              <v-btn 
                variant="outlined" 
                type="submit" 
                :disabled="!isValid"
                class="submit-btn"
              >
                Pridať školu
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>

import { ref, reactive, computed } from 'vue';
import Services from '@/api/services';

export default {
  setup(props, { emit }) {
    const form = ref(null);
    const school = reactive({
      name: '',
      address: '',
      email: '',
      phone_number: '',
    });

    const rules = {
      required: value => !!value || 'Toto pole je povinné.',
      email: value => /.+@.+\..+/.test(value) || 'E-mail musí být platný.',
      phoneNumber: value => /^\+?[0-9]{10,14}$/.test(value) || 'Telefónne číslo musí být platné.',
    };

    const isValid = computed(() => {
      return Object.values(school).every(value => !!value) &&
             rules.email(school.email) === true &&
             rules.phoneNumber(school.phone_number) === true;
    });

    const submit = async () => {
      if (isValid.value) {
        try {
          await Services.createSchool(school);
          emit('school-added');
          showSnackbar('Škola bola úspešne pridaná', 'success');
        } catch (error) {
          console.error('Error adding school:', error);
          showSnackbar('Chyba pri pridávaní školy', 'error');
        }
      }
    };
    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      form,
      school,
      rules,
      isValid,
      submit,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.form-container {
  padding: 20px 0;
}

.input-field {
  margin-bottom: 20px;
}

.submit-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 44px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 10px 0;
  }

  .input-field {
    margin-bottom: 15px;
  }
}
</style>