<template>
    <div class="documents-view">
        <v-container fluid class="pa-0">
            <v-row align="center" no-gutters>
                <v-col cols="12" :sm="9" :md="10">
                    <v-tabs v-model="activeTab" centered :grow="$vuetify.display.smAndDown"
                        :slider-size="mobile ? 2 : 4" dense>
                        <v-tab value="documents">Uložené dokumenty</v-tab>
                        <v-tab value="emailTemplates">Automatické maily</v-tab>
                    </v-tabs>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="d-flex justify-center justify-sm-end mt-2 mt-sm-0">
                    <v-btn small variant="outlined" :block="mobile"
                        @click="activeTab === 'documents' ? openFileInput() : openAddEmailTemplateDialog()"
                        class="text-none">
                        <span>{{ activeTab === 'documents' ? 'Pridať dokument' : 'Pridať email' }}</span>
                    </v-btn>
                    <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload">
                </v-col>
            </v-row>

            <v-window disabled v-model="activeTab">
                <v-window-item value="documents">
                    <v-card flat>
                        <v-card-text>
                            <v-row class="mb-4">
                                <v-col cols="12" sm="12" :block="mobile">
                                    <v-text-field v-model="documentSearch" label="Vyhľadať Dokument"
                                        prepend-inner-icon="mdi-magnify" variant="solo" density="compact"
                                        hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-data-table :headers="headers" :items="filteredDocuments" :loading="loading"
                                :search="documentSearch" class="elevation-0">
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.status="{ item }">
                                    <v-chip :color="getStatusColor(item.status)" text-color="white" size="small">
                                        {{ item.status }}
                                    </v-chip>
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.download="{ item }">
                                    <v-btn variant="text" density="comfortable" color="purple"
                                        @click="downloadDocument(item)">
                                        Stiahnuť dokument
                                    </v-btn>
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.uploaded_at="{ item }">
                                    <span>{{ formatDate(item.uploaded_at) }}</span>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-window-item>

                <v-window-item value="emailTemplates">
                    <v-card flat>
                        <v-card-text>
                            <v-data-table :headers="emailTemplateHeaders" :items="emailTemplates" :loading="loading"
                                class="elevation-0">
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.condition="{ item }">
                                    {{ item.condition || 'Žiadna podmienka' }}
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.actions="{ item }">
                                    <v-btn text @click="editEmailTemplate(item)">
                                        Upraviť
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-container>

        <!-- Email Template Editor Dialog -->
        <v-dialog v-model="emailTemplateDialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ emailTemplate.id ? 'Upraviť email' : 'Pridať email' }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="emailTemplate.name" label="Názov *" required
                        :rules="[v => !!v || 'Názov je povinný']" variant="underlined"></v-text-field>
          <v-text-field v-model="emailTemplate.subject" label="Predmet"
                        placeholder="Nešpecifikované"
                        variant="underlined"></v-text-field>
          <v-textarea v-model="emailTemplate.body" label="Obsah *" required
                      :rules="[v => !!v || 'Obsah je povinný']" variant="underlined"></v-textarea>
                      <v-select 
                          v-model="emailTemplate.condition" 
                          :items="conditions" 
                          label="Podmienka *" 
                          variant="solo"
                          :rules="[v => !!v || 'Podmienka je povinná']"
                          required
                        ></v-select>
          <v-text-field v-if="emailTemplate.condition === 'Nízky kredit'"
                        v-model="emailTemplate.creditThreshold" label="Kredity ≤ *" type="number" required
                        :rules="[v => !!v || 'Hodnota kreditu je povinná']" variant="underlined"></v-text-field>

          <!-- Display existing static attachments -->
          <div v-if="emailTemplate.staticAttachments && emailTemplate.staticAttachments.length > 0">
            <h3>Existujúce statické dokumenty:</h3>
            <v-chip v-for="attachment in emailTemplate.staticAttachments" :key="attachment.id" class="ma-1">
              {{ attachment.filename }}
              <v-btn icon x-small @click="removeStaticAttachment(attachment.id)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-chip>
          </div>

          <!-- File input for new static documents -->
          <v-file-input v-model="newStaticDocuments" label="Nové statické dokumenty" multiple chips
                        small-chips truncate-length="15" variant="underlined"
                        accept=".pdf,.doc,.docx"></v-file-input>

          <!-- Select for existing dynamic document templates -->
          <v-select v-model="emailTemplate.dynamicAttachments" :items="filteredDocuments"
                    item-title="filename" item-value="id" label="Dynamické šablóny dokumentov" multiple chips
                    variant="underlined">
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.raw.filename }} (Šablóna)</v-chip>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="confirmDeleteTemplate" v-if="emailTemplate.id">Vymazať</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="closeEmailTemplateDialog">Zrušiť</v-btn>
        <v-btn text @click="saveEmailTemplate" :disabled="!valid">Uložiť</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <ConfirmDialog ref="confirmDialog" />

  <!-- Confirmation dialog for deleting template -->
  <v-dialog v-model="deleteConfirmDialog" max-width="400" persistent>
  <v-card class="rounded-lg">
    <v-card-title class="headline d-flex align-center pa-4 bg-purple text-white">
      <v-icon left class="mr-2">mdi-alert-circle</v-icon>
      Potvrdiť vymazanie
    </v-card-title>
    <v-card-text class="pa-4 text-body-1">
      Naozaj chcete vymazať túto emailovú šablónu?
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn color="grey darken-1" text @click="deleteConfirmDialog = false" class="text-capitalize">
        Zrušiť
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn variant="outlined" @click="deleteEmailTemplate" class="text-capitalize">
        Áno
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
        <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import Service from "@/api/services";
import { useDisplay } from "vuetify/lib/framework";
import ConfirmDialog from './ConfirmDialog.vue'

export default {
    components: {
      ConfirmDialog
    },
    name: "DocumentsView",
    setup() {
        const { smAndDown: mobile } = useDisplay();
        const activeTab = ref("documents");
        const documents = ref([]);
        const emailTemplates = ref([]);
        const loading = ref(true);
        const error = ref(null);
        const documentSearch = ref("");
        const dateRange = ref([null, null]);
        const menuStartDate = ref(false);
        const menuEndDate = ref(false);
        const totalDocuments = ref(0);
        const totalPages = ref(0);
        const currentPage = ref(1);
        const itemsPerPage = ref(10);
        const fileInput = ref(null);
        const emailTemplateDialog = ref(false);
        const editingTemplate = ref(null);
        const emailTemplate = ref({
            id: null,
            name: '',
            subject: '',
            body: '',
            condition: '',
            creditThreshold: null,
            staticDocuments: [],
            dynamicDocuments: []
        });
        const valid = computed(() => {
  return (
    !!emailTemplate.value.name &&
    !!emailTemplate.value.body &&
    !!emailTemplate.value.condition &&
    (emailTemplate.value.condition !== 'Nízky kredit' || !!emailTemplate.value.creditThreshold)
  );
});
        const newStaticDocuments = ref([]);

        const headers = [
            { title: "Názov", align: "start", key: "filename" },
            { title: "Vložené", key: "uploaded_at", align: "end" },
            { key: "download", sortable: false, align: "end" },
        ];

        const emailTemplateHeaders = [
            { title: "Názov", key: "name" },
            { title: "Predmet", key: "subject" },
            { title: "Podmienka", key: "condition" },
            { key: "actions", sortable: false }
        ];

        const conditions = [
            "Nízky kredit",
        ];

        const formattedStartDate = computed(() => {
            return dateRange.value[0] ? new Date(dateRange.value[0]).toLocaleDateString() : '';
        });
    

    const deleteConfirmDialog = ref(false);

    const removeStaticAttachment = (attachmentId) => {
      emailTemplate.value.staticAttachments = emailTemplate.value.staticAttachments.filter(
        attachment => attachment.id !== attachmentId
      );
    };

    const confirmDeleteTemplate = () => {
      deleteConfirmDialog.value = true;
    };

    const deleteEmailTemplate = async () => {
  try {
    await Service.deleteEmailTemplate(emailTemplate.value.id);
    showSnackbar("Emailová šablóna bola úspešne vymazaná");
    closeEmailTemplateDialog();
    deleteConfirmDialog.value = false;
    await fetchEmailTemplates(); // Wait for the fetch to complete
  } catch (error) {
    console.error("Error deleting email template:", error);
    showSnackbar("Nastala chyba pri mazaní emailovej šablóny: " + error.message, "error");
  }
};





        const formattedEndDate = computed(() => {
            return dateRange.value[1] ? new Date(dateRange.value[1]).toLocaleDateString() : '';
        });

        const filteredDocuments = computed(() => {
            let filtered = documents.value;
            if (dateRange.value[0] && dateRange.value[1]) {
                const [start, end] = dateRange.value;
                filtered = filtered.filter((doc) => {
                    const docDate = new Date(doc.uploaded_at);
                    return docDate >= new Date(start) && docDate <= new Date(end);
                });
            }
            return filtered;
        });

        const fetchDocuments = async () => {
            try {
                loading.value = true;
                const response = await Service.getAllDocuments({
                    documentType: 'Template',
                    search: documentSearch.value,
                    startDate: dateRange.value[0],
                    endDate: dateRange.value[1],
                    page: currentPage.value,
                    itemsPerPage: itemsPerPage.value,
                });
                documents.value = response.items;
                console.log("Document Templates:", documents.value);
                totalDocuments.value = response.total;
                totalPages.value = response.totalPages;
            } catch (err) {
                console.error("Error fetching document templates:", err);
                error.value = "Failed to fetch document templates. Please try again later.";
            } finally {
                loading.value = false;
            }
        };

        const fetchEmailTemplates = async () => {
            try {
                loading.value = true;
                emailTemplates.value = [];
                const response = await Service.getSystemEmailTemplates();
                emailTemplates.value = response.map(template => ({
                    id: template.id,
                    name: template.name,
                    subject: template.subject || "Nešpecifikované",
                    body: template.body,
                    condition: template.condition ? template.condition.condition_type : null,
                    creditThreshold: template.condition && template.condition.condition_type === 'Nízky kredit'
                        ? template.condition.parameters.threshold
                        : null,
                    attachments: template.attachments,
                    created_at: template.created_at,
                    updated_at: template.updated_at
                }));
                console.log("Email templates:", emailTemplates.value);
            } catch (err) {
                error.value = "Failed to fetch email templates. Please try again later.";
                console.error("Error fetching email templates:", err);
            } finally {
                loading.value = false;
            }
        };

        const formatDate = (dateString) => {
            return new Date(dateString).toLocaleDateString();
        };

        const downloadDocument = async (documentItem) => {
            try {
                const response = await Service.downloadDocument(documentItem.id);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = documentItem.filename || 'document';
                link.click();
                window.URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error('Error in downloadDocument:', error);
                showSnackbar("Nastala chyba pri sťahovaní dokumentu: " + error.message, "error");
            }
        };

        const getStatusColor = (status) => {
            switch (status) {
                case "Nepodpísané":
                    return "error";
                case "Podpísané":
                    return "success";
                default:
                    return "default";
            }
        };

        const resetStartDate = () => {
            dateRange.value[0] = null;
        };

        const resetEndDate = () => {
            dateRange.value[1] = null;
        };

        const updateStartDate = (value) => {
            dateRange.value[0] = value;
            menuStartDate.value = false;
        };

        const updateEndDate = (value) => {
            dateRange.value[1] = value;
            menuEndDate.value = false;
        };

        const openFileInput = () => {
            fileInput.value.click();
        };

        const handleFileUpload = async (event) => {
            const file = event.target.files[0];
            if (file) {
                try {
                    loading.value = true;
                    const userId = await Service.getUserIdByEmail(localStorage.getItem("email"));
                    const result = await Service.uploadDocument(file, userId);
                    console.log("File uploaded successfully:", result);
                    showSnackbar("Súbor bol úspešne nahratý");
                    await fetchDocuments();
                } catch (error) {
                    console.error("Error uploading file:", error);
                    showSnackbar("Nastala chyba pri nahrávaní súboru: " + error.message, "error");
                } finally {
                    loading.value = false;
                }
            }
        };

        const openAddEmailTemplateDialog = () => {
            emailTemplate.value = {
                id: null,
                name: '',
                subject: '',
                body: '',
                condition: null,
                creditThreshold: null,
                attachments: [],
                dynamicAttachments: []
            };
            emailTemplateDialog.value = true;
        };



        const editEmailTemplate = (template) => {
            emailTemplate.value = { 
                ...template,
                id: template.id,
                subject: template.subject || 'Nešpecifikované',
                staticAttachments: template.attachments.filter(a => a.document_type !== 'Template'),
                dynamicAttachments: template.attachments.filter(a => a.document_type === 'Template').map(a => a.id)
            };
            newStaticDocuments.value = [];
            emailTemplateDialog.value = true;
        };


        const closeEmailTemplateDialog = () => {
            emailTemplateDialog.value = false;
            editingTemplate.value = null;
            emailTemplate.value = {
                name: '',
                subject: '',
                body: '',
                condition: null,
                creditThreshold: null,
                staticDocuments: [],
                dynamicDocuments: []
            };
        };

        const saveEmailTemplate = async () => {
    if (valid.value) {
        try {
            console.log("Saving email template:", emailTemplate.value);
            const formData = new FormData();
            formData.append('name', emailTemplate.value.name);
            
            // Handle the subject field
            const subject = emailTemplate.value.subject === 'Nešpecifikované' ? '' : (emailTemplate.value.subject || '');
            formData.append('subject', subject);
            
            formData.append('body', emailTemplate.value.body);
            formData.append('condition_type', emailTemplate.value.condition);

            if (emailTemplate.value.condition === 'Nízky kredit' && emailTemplate.value.creditThreshold) {
                formData.append('condition_threshold', emailTemplate.value.creditThreshold);
            }

            // Handle static documents (new uploads)
            if (newStaticDocuments.value && newStaticDocuments.value.length > 0) {
                newStaticDocuments.value.forEach((file) => {
                    formData.append(`static_documents`, file);
                });
            }

            // Handle dynamic document templates (existing documents)
            if (emailTemplate.value.dynamicAttachments && emailTemplate.value.dynamicAttachments.length > 0) {
                emailTemplate.value.dynamicAttachments.forEach((docId) => {
                    formData.append('dynamic_document_ids', docId);
                });
            }

            console.log("Form data:", formData);
            let response;
            if (emailTemplate.value.id) {
                response = await Service.updateEmailTemplate(emailTemplate.value.id, formData);
                console.log("Email template updated successfully", response);
                showSnackbar("Emailová šablóna bola úspešne upravená");
            } else {
                response = await Service.createEmailTemplate(formData);
                console.log("Emailová šablóna bola úspešne vytvorená.", response);
                showSnackbar("Emailová šablóna bola úspešne vytvorená");
            }
            await fetchEmailTemplates();
            closeEmailTemplateDialog();
        } catch (error) {
            console.error("Error saving email template:", error);
            showSnackbar("Nastala chyba pri ukladaní emailovej šablóny: " + error.message, "error");
        }
    }
};

        onMounted(() => {
            fetchDocuments();
            fetchEmailTemplates();
        });

        watch([documentSearch, dateRange], () => {
            fetchDocuments();
        });

        const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');

        const showSnackbar = (text, color = 'success') => {
            snackbarText.value = text;
            snackbarColor.value = color;
            snackbar.value = true;
        };

        return {
            activeTab,
            documents,
            emailTemplates,
            loading,
            error,
            formatDate,
            downloadDocument,
            getStatusColor,
            documentSearch,
            dateRange,
            menuStartDate,
            menuEndDate,
            filteredDocuments,
            headers,
            emailTemplateHeaders,
            formattedStartDate,
            formattedEndDate,
            resetStartDate,
            resetEndDate,
            updateStartDate,
            updateEndDate,
            mobile,
            fileInput,
            openFileInput,
            handleFileUpload,
            emailTemplateDialog,
            editingTemplate,
            emailTemplate,
            conditions,
            valid,
            openAddEmailTemplateDialog,
            editEmailTemplate,
            closeEmailTemplateDialog,
            saveEmailTemplate,
            snackbar,
            snackbarText,
            snackbarColor,
            newStaticDocuments,
            removeStaticAttachment,
            deleteConfirmDialog,
            confirmDeleteTemplate,
            deleteEmailTemplate
        };
    },
};
</script>

<style scoped>
.admin-home-btn-add {
    text-transform: none;
    font-weight: bold;
    color: purple;
    /* Change the text color to purple */
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px 8px;
    /* Adjust padding to ensure the outline fits the text */
    line-height: 1.5;
    /* Adjust line-height to ensure the text is centered */
    display: inline-block;
    /* Ensure the button is displayed as an inline element */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.selected {
    color: black;
    text-decoration: underline;
}

.rounded-corners {
    border-radius: 10px;
}

.header-cell {
    background-color: #f5f5f5;
    color: black;
    font-weight: bold;
}

.no-borders {
    border: none !important;
}

.v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    /* Change the text color to purple */
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px 8px;
    /* Adjust padding to ensure the outline fits the text */
    line-height: 1.5;
    /* Adjust line-height to ensure the text is centered */
    display: inline-block;
    /* Ensure the button is displayed as an inline element */
}
</style>