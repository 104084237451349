<template>
      <div>
        <v-container class="d-flex align-center justify-center" style="height: 100vh;">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-card class="elevation-0" style="margin-top: -20vh;">
                <v-card-text>
                  <strong>
                    Táto funkcia sa nenachádza v aktuálnej verzií a pridá sa v ďaľších moduloch.
                  </strong>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
</template>