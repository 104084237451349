<template>
  <div>
    <h1 :class="[$vuetify.display.smAndDown ? 'text-h5' : 'text-h4', 'text-center mb-6 font-weight-bold']">Rodinná Škola</h1>

    <v-card
      class="mx-auto pa-6 pb-8"
      :elevation="$vuetify.display.smAndDown ? 2 : 8"
      :max-width="$vuetify.display.smAndDown ? '100%' : '448'"
      :rounded="$vuetify.display.smAndDown ? 'md' : 'lg'"
    >
      <h2 :class="[$vuetify.display.smAndDown ? 'text-h6' : 'text-h5', 'text-center mb-6']">Nastavte si nové heslo</h2>

      <v-text-field
        v-model="resetCode"
        density="compact"
        placeholder="Reset Code"
        prepend-inner-icon="mdi-key-outline"
        variant="outlined"
        class="mb-2"
        required
      ></v-text-field>

      <v-text-field
        v-model="newPassword"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="New Password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        class="mb-2"
        @click:append-inner="visible = !visible"
        required
      ></v-text-field>

      <v-text-field
        v-model="confirmPassword"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="Confirm New Password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        class="mb-4"
        @click:append-inner="visible = !visible"
        required
      ></v-text-field>

      <v-card
        v-if="errorMessage"
        class="mb-4"
        color="red lighten-5"
        variant="tonal"
      >
        <v-card-text class="text-caption">
          {{ errorMessage }}
        </v-card-text>
      </v-card>

      <v-btn
        class="mb-4 custom-btn"
        :disabled="!isFormValid"
        :size="$vuetify.display.smAndDown ? 'x-large' : 'large'"
        variant="outlined"
        block
        @click="setNewPassword"
      >
        Uložiť
      </v-btn>
    </v-card>
    <v-snackbar
  v-model="snackbar"
  :color="snackbarColor"
  :timeout="3000"
  location="top"
>
  {{ snackbarText }}
  <template v-slot:actions>
    <v-btn
      color="white"
      variant="text"
      @click="snackbar = false"
    >
      Zavrieť
    </v-btn>
  </template>
</v-snackbar>
  </div>
</template>

<script>
import services from '@/api/services';
import router from '@/router';
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const email = ref('');
    const resetCode = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const visible = ref(false);
    const errorMessage = ref('');
    const route = useRoute();

    onMounted(() => {
      email.value = route.query.email;
      console.log(email.value);
    });

    const isFormValid = computed(() => {
      return resetCode.value.trim() !== '' && newPassword.value.trim() !== '' && newPassword.value === confirmPassword.value;
    });

    const setNewPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        errorMessage.value = "Heslá sa nezhodujú";
        return;
      }
      try {
        await services.resetPassword(newPassword.value, resetCode.value, email.value);
        showSnackbar('Heslo bolo úspešne zmenené. Prosím, prihláste sa s novým heslom.', 'success');
        router.push('/login');
      } catch (error) {
        console.error(error);
        errorMessage.value = "Nastala chyba pri resetovaní hesla. Skúste to znova.";
        showSnackbar("Nastala chyba pri resetovaní hesla. Skúste to znova.", "error");
      }
    };


    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      resetCode,
      newPassword,
      confirmPassword,
      visible,
      errorMessage,
      isFormValid,
      setNewPassword,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.custom-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.v-btn.custom-btn.v-btn--disabled {
  color: grey !important;
}

@media (max-width: 600px) {
  .v-card {
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
</style>
