<template>
  <BaseLayout>
    <div>
        <component :is="settingsComponent" />
      </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import NotAvailableComponent from "@/components/NotAvailableComponent.vue";
import SupplierSettingsComponent from "@/components/SupplierSettingsComponent.vue";
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: "SettingsView",
  components: {
    BaseLayout,
    SupplierSettingsComponent,
    NotAvailableComponent,
  },
  setup() {
      const store = useStore()
      const userType = computed(() => store.getters.getUserType)
        
      console.log(userType.value)
      const settingsComponent = computed(() => {
        if (userType.value === 'supplier') {
          return SupplierSettingsComponent
        }else{
          return NotAvailableComponent
        
        }
      })
  
  
      return {
        settingsComponent,
      }
    }
};
</script>