<template>
  <v-col cols="12" md="6" lg="5">
    <v-transition-fade>
      <v-spacer></v-spacer>
      <v-form ref="form" v-model="valid" lazy-validation>



        <v-menu v-model="orderTimeMenu" :close-on-content-click="false" @v-click-outside="orderTimeMenu = false"
          transition="scale-transition" offset-y>
          <template v-slot:activator="{ props }">
            <v-text-field v-model="orderTimePlaceholder" :placeholder="orderTimePlaceholder"
              label="Čas poslania objednávky" readonly density="compact" v-bind="props" :rules="[rules.required]"
              variant="underlined" class="mb-4 input-field"></v-text-field>
          </template>
          <v-time-picker v-if="orderTimeMenu" v-model="orderTimePlaceholder" format="24hr"
            @update:model-value="orderTimeMenu = false">
            <template v-slot:actions>
              <v-btn text color="purple" @click="orderTimeMenu = false">
                Zatvoriť
              </v-btn>
            </template>
          </v-time-picker>
        </v-menu>

        <v-menu v-model="changeDeadlineMenu" :close-on-content-click="false"
          @v-click-outside="changeDeadlineMenu = false" transition="scale-transition" offset-y>
          <template v-slot:activator="{ props }">
            <v-text-field v-model="timeChangeDeadline" label="Zmeny do" readonly density="compact" v-bind="props"
              :rules="[rules.required, rules.timeChangeDeadlineBeforeOrderTime]" variant="underlined"
              class="mb-4 input-field"></v-text-field>
          </template>
          <v-time-picker v-if="changeDeadlineMenu" v-model="timeChangeDeadline" format="24hr"
            @update:model-value="changeDeadlineMenu = false">
            <template v-slot:actions>
              <v-btn text color="purple" @click="changeDeadlineMenu = false">
                Zatvoriť
              </v-btn>
            </template>
          </v-time-picker>
        </v-menu>

        <v-text-label class="mb-4">Poslať objednávku emailom</v-text-label>
        <v-btn-toggle v-model="sendEmail" mandatory class="mb-4 input field">
          <v-btn value="Ano" :class="{ 'active-btn': sendEmail === 'Ano' }">
            Áno
          </v-btn>
          <v-btn value="Nie" :class="{ 'active-btn': sendEmail === 'Nie' }">
            Nie
          </v-btn>
        </v-btn-toggle>

        <div v-for="(school, index) in supplierSchools" :key="index" class="d-flex align-center mb-4">
          <v-select v-model="supplierSchools[index]" :items="availableSchools(index)" item-title="name" item-value="id"
            placeholder="Vyber školu" label="Dodáva pre školu" variant="underlined" required class="input-field"
            density="compact" :rules="[rules.required]"></v-select>
          <v-btn icon @click="removeSchool(index)" v-if="supplierSchools.length > 1">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
        </div>

        <v-btn text @click="addSchool" :disabled="supplierSchools.length >= schoolsNames.length"
          class="text-button mb-4">
          + Pridať školu
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit" :disabled="!valid" class="mt-4" variant="outlined">
          {{ isEditing ? 'Aktualizovať' : 'Save' }}
        </v-btn>
      </v-form>
    </v-transition-fade>
  </v-col>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
    {{ snackbarText }}
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false">
        Zavrieť
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted } from 'vue';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import Services from '@/api/services';


export default {
  emits: ['supplier-updated'],
  components: {
    VTimePicker,
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null);
    const valid = ref(false);
    const orderTimeMenu = ref(false);
    const changeDeadlineMenu = ref(false);

    const supplierSchools = ref([]);
    const orderTime = ref(null);
    const changeDeadline = ref(null);
    const sendEmail = ref('Ano');

    const schools = ref([]);
    const isEditing = ref(false);
    const schoolsNames = ref([]);
    const supplierSettings = ref(props.supplier);
    const orderTimePlaceholder = ref('Čas poslania objednavky');
    orderTimePlaceholder.value = supplierSettings.value.time_of_sending_order;
    const timeChangeDeadline = ref(null);
    sendEmail.value = supplierSettings.value.send_order_by_email ? 'Ano' : 'Nie';
    console.log('supplierSettings:', supplierSettings.value);
    console.log('sendEmail:', sendEmail.value);
    const rules = {
      required: value => !!value || 'Required.',
      timeChangeDeadlineBeforeOrderTime: value => {
        if (!orderTimePlaceholder.value || !value) return true;
        return new Date(`2000-01-01T${value}`) < new Date(`2000-01-01T${orderTimePlaceholder.value}`) || 'Zmeny objednávky musia byť pred časom poslania objednávky.';
      },
    };

    const fetchSchools = async () => {
      try {
        const response = await Services.getSchools();
        schools.value = response.map(school => ({
          name: school.name,
          id: school.id,
        }));
        schoolsNames.value = response.map(school => school.name);
      } catch (error) {
        console.error('Error fetching schools:', error);
        showSnackbar('Chyba pri načítaní škôl', 'error');
      }
    };

    const fetchSupplierSettings = async () => {
      try {
        isEditing.value = true;
        orderTime.value = supplierSettings.value.orderTime;
        timeChangeDeadline.value = supplierSettings.value.order_modification_limit_time;
        sendEmail.value = supplierSettings.value.send_order_by_email ? 'Ano' : 'Nie';
        supplierSchools.value = supplierSettings.value.schools.map(school => school.id);
      } catch (error) {
        console.error('Error fetching supplier settings:', error);
        showSnackbar('Chyba pri načítaní nastavení dodávateľa', 'error');
      }
    };

    const addSchool = () => {
      if (supplierSchools.value.length < schoolsNames.value.length) {
        supplierSchools.value.push(null);
      }
    };

    const removeSchool = (index) => {
      supplierSchools.value.splice(index, 1);
    };

    const availableSchools = (currentIndex) => {
      return schools.value.filter(school =>
        !supplierSchools.value.includes(school.id) ||
        supplierSchools.value[currentIndex] === school.id
      );
    };

    const submit = async () => {
      if (form.value.validate()) {
        const payload = {
          supplierId: props.supplier.id,
          schools: supplierSchools.value.filter(id => id !== null),
          time_of_sending_order: orderTimePlaceholder.value,
          order_modification_limit_time: timeChangeDeadline.value,
          send_order_by_email: sendEmail.value === 'Ano' ? true : false,
        };
        console.log('payload:', payload);
        try {
          const response = await Services.updateSupplierSettings(payload);
          showSnackbar('Údaje dodávateľa boli úspešne aktualizované', 'success');
          console.log('Supplier settings updated successfully:', response);
          emit('supplier-updated');
        } catch (error) {
          showSnackbar('Nepodarilo sa aktualizovať údaje dodávateľa', 'error');
          console.error('Failed to update supplier settings:', error);
        }
      }
    };

    onMounted(() => {
      fetchSchools();
      fetchSupplierSettings();
    });



    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };
    return {
      form,
      valid,
      orderTimeMenu,
      changeDeadlineMenu,
      supplierSchools,
      orderTime,
      changeDeadline,
      sendEmail,
      schools,
      rules,
      isEditing,
      submit,
      schoolsNames,
      orderTimePlaceholder,
      timeChangeDeadline,
      addSchool,
      removeSchool,
      availableSchools,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-btn:disabled {
  color: rgba(128, 0, 128, 0.5) !important;
  background-color: transparent !important;
  opacity: 1 !important;
}

.custom-button {
  width: 100%;
  max-width: 200px;
}

.v-btn-toggle {
  display: flex;
  justify-content: center;
}

.v-btn-toggle .v-btn {
  flex: 1;
}

.active-btn {
  background-color: purple !important;
  color: white !important;
}

.v-btn-toggle .v-btn.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  background-color: transparent !important;
}

.input-field {
  margin-bottom: 20px;
}

.v-btn.text-button:disabled {
  opacity: 0.5;
  background-color: transparent !important;
}
</style>