<template>
  <div class="documents-view">
    <v-container fluid class="pa-0 documents-container">
      <v-row class="mb-4 search-fields-row">
        <v-col cols="12" class="py-1 px-2">
          <v-text-field v-model="documentSearch" label="Vyhľadať Dokument" prepend-inner-icon="mdi-magnify"
            variant="solo" density="compact" hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-4 date-fields-row">
        <v-col cols="6" class="py-1 px-2">
          <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="290px">
            <template v-slot:activator="{ props }">
              <v-text-field v-model="formattedStartDate" label="Od" prepend-inner-icon="mdi-calendar" readonly
                variant="solo" density="compact" v-bind="props" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="dateRange[0]" @update:model-value="updateStartDate" :landscape="landscape"
              :width="width" class="custom-date-picker input-field"></v-date-picker>
            <v-btn text color="purple" @click="resetStartDate">Resetovať</v-btn>
          </v-menu>
        </v-col>
        <v-col cols="6" class="py-1 px-2">
          <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="290px">
            <template v-slot:activator="{ props }">
              <v-text-field v-model="formattedEndDate" label="Do" prepend-inner-icon="mdi-calendar" readonly
                variant="solo" density="compact" v-bind="props" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="dateRange[1]" @update:model-value="updateEndDate" :landscape="landscape"
              :width="width" class="custom-date-picker input-field"></v-date-picker>
            <v-btn text color="purple" @click="resetEndDate">Resetovať</v-btn>
          </v-menu>
        </v-col>
      </v-row>

      <v-data-table :headers="headers" :items="filteredDocuments" :loading="loading" :search="documentSearch"
        class="elevation-0">
        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" text-color="white" size="small">
            {{ item.status }}
          </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.download="{ item }">
          <v-btn variant="text" density="comfortable" color="purple" @click="downloadDocument(item)">
            Stiahnuť dokument
          </v-btn>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.uploaded_at="{ item }">
          <span>{{ formatDate(item.uploaded_at) }}</span>
        </template>
      </v-data-table>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import Service from "@/api/services";

export default {
  name: "DocumentsView",
  setup() {
    const documents = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const documentSearch = ref("");
    const dateRange = ref([null, null]);
    const menuStartDate = ref(false);
    const menuEndDate = ref(false);
    const totalDocuments = ref(0);
    const totalPages = ref(0);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);

    const headers = [
      { title: "Názov", align: "start", key: "filename" },
      { title: "Vložené", key: "uploaded_at", align: "end" },
      { title: "Stav", key: "status", align: "end" },
      { key: "download", sortable: false, align: "end" },
      { key: "podpisat", sortable: false, align: "end" },
    ];

    const rules = {
      required: value => !!value || 'Required.'
    };

    const formattedStartDate = computed(() => {
      return dateRange.value[0] ? new Date(dateRange.value[0]).toLocaleDateString() : '';
    });

    const formattedEndDate = computed(() => {
      return dateRange.value[1] ? new Date(dateRange.value[1]).toLocaleDateString() : '';
    });

    const filteredDocuments = computed(() => {
      let filtered = documents.value;
      if (dateRange.value[0] && dateRange.value[1]) {
        const [start, end] = dateRange.value;
        filtered = filtered.filter((doc) => {
          const docDate = new Date(doc.uploaded_at);
          return docDate >= new Date(start) && docDate <= new Date(end);
        });
      }
      return filtered;
    });

    const fetchDocuments = async () => {
      try {
        const userId = await Service.getUserIdByEmail(
          localStorage.getItem("email")
        );
        const response = await Service.getUserDocuments(userId, {
          startDate: dateRange.value[0],
          endDate: dateRange.value[1],
          status: null, // or whatever status you want to filter by
          search: documentSearch.value,
          page: currentPage.value,
          itemsPerPage: itemsPerPage.value,
        });
        documents.value = response.items;
        totalDocuments.value = response.total;
        totalPages.value = response.total_pages;
      } catch (err) {
        error.value = "Failed to fetch documents. Please try again later.";
        showSnackbar("Nepodarilo sa načítať dokumenty. Skúste to znova neskôr.", "error");
      } finally {
        loading.value = false;
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    const downloadDocument = async (documentItem) => {
      try {
        const response = await Service.downloadDocument(documentItem.id);

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = documentItem.filename || 'document';
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error in downloadDocument:', error);
        showSnackbar("Nepodarilo sa stiahnuť dokument. Skúste to znova.", "error");
      }
    }

    const getStatusColor = (status) => {
      switch (status) {
        case "Nepodpísané":
          return "error";
        case "Podpísané":
          return "success";
        default:
          return "default";
      }
    };

    const signDocument = async (document) => {
      try {
        await Service.signDocument(document.id);
        await fetchDocuments();
      } catch (err) {
        console.error("Failed to sign document:", err);
      }
    };

    const resetStartDate = () => {
      dateRange.value[0] = null;
    };

    const resetEndDate = () => {
      dateRange.value[1] = null;
    };

    const updateStartDate = (value) => {
      dateRange.value[0] = value;
      menuStartDate.value = false;
    };

    const updateEndDate = (value) => {
      dateRange.value[1] = value;
      menuEndDate.value = false;
    };

    onMounted(() => {
      fetchDocuments();
    });

    watch([documentSearch, dateRange], () => {
      fetchDocuments();
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    // eslint-disable-next-line
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      documents,
      loading,
      error,
      formatDate,
      downloadDocument,
      getStatusColor,
      signDocument,
      documentSearch,
      dateRange,
      menuStartDate,
      menuEndDate,
      filteredDocuments,
      headers,
      rules,
      formattedStartDate,
      formattedEndDate,
      resetStartDate,
      resetEndDate,
      updateStartDate,
      updateEndDate,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent;
  box-shadow: none;
}

.selected {
  color: black;
  text-decoration: underline;
}

.rounded-corners {
  border-radius: 10px;
}

.header-cell {
  background-color: #f5f5f5;
  color: black;
  font-weight: bold;
}

.no-borders {
  border: none !important;
}

.documents-container {
  margin-top: 12px;
  /* Adjust this value as needed */
}

@media (max-width: 600px) {

  .search-fields-row,
  .date-fields-row {
    margin-bottom: 8px !important;
  }

  .v-text-field {
    font-size: 0.9rem;
  }

  .v-text-field .v-field__input {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
</style>