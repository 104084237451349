<template>
  <v-container fluid class="p-0">
    <div v-if="!showMealForm && !showUploadForm">
      <v-row>
        <v-col cols="12" :class="{ 'd-flex align-center justify-space-between': mdAndUp }">
          <v-select v-model="selectedWeek" :items="availableWeeks" item-title="label" item-value="value"
            label="Vyberte týždeň" variant="solo" density="compact" hide-details
            :class="{ 'flex-grow-1 mr-4': mdAndUp, 'mb-4': !mdAndUp }"></v-select>
          <v-btn small variant="outlined" @click="openMealForm"
            :class="{ 'mr-2': mdAndUp, 'mb-4': !mdAndUp, 'w-100': !mdAndUp }">
            Pridať jedlo
          </v-btn>
          <v-btn small variant="outlined" @click="openUploadForm" :class="{ 'w-100': !mdAndUp }">
            Nahrať menu
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text v-if="meals.length === 0" class="text-center">
        <v-alert color="purple" icon="mdi-information" border="left" prominent text class="mx-auto" max-width="500">
          <span class="text-h8 font-weight-bold">
            Pre zvolený týždeň nie sú dostupné žiadne jedlá
          </span>
        </v-alert>
      </v-card-text>
      <template v-if="!isMealsEmpty">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader v-if="isLoadingMeals" type="card, card, card" class="mb-6"></v-skeleton-loader>
              <template v-else>
                <v-card v-for="(dayMeals, day) in groupedMeals" :key="day" class="mb-6">
                  <v-card-title class="text-h6">
                    {{ day }}
                  </v-card-title>
                  <v-card-text>
                    <!-- Desktop version -->
                    <v-simple-table :loading="isLoadingMeals" class="hidden-sm-and-down">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left meal-column">Jedlo</th>
                            <th class="text-left description-column">Popis</th>
                            <th class="text-left allergens-column">Alergény</th>
                            <th class="text-right price-column">Cena od dodávateľa</th>
                            <th class="text-right actions-column"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(meal, index) in dayMeals" :key="meal.id">
                            <tr class="meal-row">
                              <td class="meal-column">{{ meal.name }}</td>
                              <td class="description-column">{{ meal.description }}</td>
                              <td class="allergens-column">
                                <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small class="mr-1 mb-1"
                                  color="purple" text-color="white">
                                  {{ allergen.name }}
                                </v-chip>
                              </td>
                              <td class="text-right price-column">{{ formatCurrency(meal.price_supplier) }}</td>
                              <td class="text-right actions-column">
                                <v-btn icon small @click="editMeal(meal)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small @click="confirmDelete(meal.id)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                            <tr v-if="index < dayMeals.length - 1" class="spacer-row">
                              <td colspan="5"></td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <!-- Mobile version -->
<v-list :loading="isLoadingMeals" class="hidden-md-and-up">
  <template v-for="(meal, index) in dayMeals" :key="meal.id">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">{{ meal.name }}</v-list-item-title>
        <v-list-item-subtitle class="mt-1">{{ meal.description }}</v-list-item-subtitle>
        <v-list-item-subtitle class="mt-2">
          <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small
            class="mr-1 mb-1" color="purple" text-color="white">
            {{ allergen.name }}
          </v-chip>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="mt-2 d-flex justify-space-between align-center">
          <span>Cena: {{ formatCurrency(meal.price_supplier) }}</span>
          <div>
            <v-btn icon small @click="editMeal(meal)" class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon small @click="confirmDelete(meal.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider v-if="index < dayMeals.length - 1" class="my-2"></v-divider>
  </template>
</v-list>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </div>
    <div v-else-if="showMealForm">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn icon @click="closeMealForm" class="mr-4">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <MealForm :supplier="supplier" :selectedMeal="selectedMeal" @meal-added="handleMealAdded"
        @meal-updated="handleMealUpdated" />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <v-btn icon @click="closeUploadForm" class="mr-4">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <MenuUploadForm :supplier="supplier" @menu-uploaded="handleMenuUploaded" />
    </div>
    <ConfirmDialog ref="confirmDialog" />
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import Services from "@/api/services";
import moment from "moment";
import MealForm from "@/components/SupplierMealForm.vue";
import MenuUploadForm from "@/components/MenuUploadForm.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default {
  components: {
    MealForm,
    MenuUploadForm,
    ConfirmDialog
  },
  name: 'SupplierMenu',
  emits: ['show-add-form'],
  setup() {
    const supplier = ref(null);
    const meals = ref([]);
    const selectedWeek = ref(0);
    const isLoadingMeals = ref(false);
    const showMealForm = ref(false);
    const showUploadForm = ref(false);
    const selectedMeal = ref(null);
    const { mdAndUp } = useDisplay();
    const availableWeeks = computed(() => {
      const currentDate = new Date();
      const currentWeek = moment(currentDate).week();
      const currentYear = moment(currentDate).year();

      const weeks = [];
      for (let i = 0; i < 4; i++) {
        const weekNumber = currentWeek + i;
        const weekStart = moment().year(currentYear).isoWeek(weekNumber).startOf("isoWeek");
        const weekEnd = moment().year(currentYear).isoWeek(weekNumber).endOf("isoWeek");

        weeks.push({
          label: `${weekStart.format("DD.MM.YYYY")} - ${weekEnd.format("DD.MM.YYYY")}`,
          value: i,
          start: weekStart.format("DD.MM.YYYY"),
          end: weekEnd.format("DD.MM.YYYY"),
        });
      }

      return weeks;
    });

    const groupedMeals = computed(() => {
      const groupedMeals = {};
      if (meals.value && Array.isArray(meals.value)) {
        meals.value.forEach((meal) => {
          let day = moment(meal.date).format("dddd DD.MM.YYYY");

          // Capitalize the first letter, handling "Štvrtok" separately
          day = day.replace(/^štvrtok/i, 'Štvrtok').replace(/^\w/, c => c.toUpperCase());

          if (!groupedMeals[day]) {
            groupedMeals[day] = [];
          }
          groupedMeals[day].push(meal);
        });
      }
      return groupedMeals;
    });

    const isMealsEmpty = computed(() => {
      return Object.keys(meals.value).length === 0;
    });

    const fetchMeals = async () => {
      isLoadingMeals.value = true;
      try {
        const startDate = availableWeeks.value[selectedWeek.value].label.split(" - ")[0];
        const formattedStartDate = moment(startDate, "DD.MM.YYYY").format("YYYY-MM-DD");
        const supplierEmail = localStorage.getItem("email");
        supplier.value = await Services.getUserIdByEmail(supplierEmail);
        console.log("Supplier:", supplier.value);
        const response = await Services.getMealsForSupplier(
          supplier.value,
          formattedStartDate
        );
        console.log("Meals Response:", response);
        meals.value = response;
      } catch (error) {
        console.error("Error fetching meals:", error);
        showSnackbar("Chyba pri načítaní jedál", "error");
      } finally {
        isLoadingMeals.value = false;
      }
    };

    const formatCurrency = (value) => {
      return new Intl.NumberFormat("sk-SK", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    };

    const openMealForm = () => {
      console.log("OPENING THE MEAL FORM", supplier.value);
      showMealForm.value = true;
    };

    const closeMealForm = () => {
      showMealForm.value = false;
    };

    const openUploadForm = () => {
      showUploadForm.value = true;
    };

    const closeUploadForm = () => {
      showUploadForm.value = false;
    };

    const handleMealAdded = () => {
      showSnackbar("Jedlo bolo úspešne pridané!", "success");
      closeMealForm();
      fetchMeals();
    };

    const handleMealUpdated = () => {
      showSnackbar("Jedlo bolo úspešne aktualizované!", "success");
      closeMealForm();
      fetchMeals();
    };

    const handleMenuUploaded = () => {
      showSnackbar("Menu bolo úspešne nahrané!", "success");
      closeUploadForm();
      fetchMeals();
    };

    const editMeal = (meal) => {
      console.log("Editing meal:", meal);
      selectedMeal.value = meal;
      showMealForm.value = true;
      console.log("Selected meal:", selectedMeal.value);
    };

    const confirmDialog = ref(null);
    const confirmDelete = async (mealId) => {
      const confirmed = await confirmDialog.value.open(
        "Potvrdenie odstránenia",
        "Naozaj chcete odstrániť toto jedlo?"
      );
      if (confirmed) {
        deleteMeal(mealId);
      }
    };

    const deleteMeal = async (mealId) => {
      try {
        await Services.deleteMeal(mealId);
        showSnackbar("Jedlo bolo úspešne odstránené!", "success");
        fetchMeals();
      } catch (error) {
        console.error("Error deleting meal:", error);
        showSnackbar("Pri odstraňovaní jedla došlo k chybe.", "error");
      }
    };

    watch(selectedWeek, () => {
      fetchMeals();
    });

    onMounted(() => {
      fetchMeals();
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      meals,
      selectedWeek,
      availableWeeks,
      isLoadingMeals,
      groupedMeals,
      isMealsEmpty,
      formatCurrency,
      openMealForm,
      closeMealForm,
      openUploadForm,
      closeUploadForm,
      handleMealAdded,
      handleMealUpdated,
      handleMenuUploaded,
      editMeal,
      confirmDelete,
      deleteMeal,
      showMealForm,
      showUploadForm,
      supplier,
      selectedMeal,
      MealForm,
      MenuUploadForm,
      ConfirmDialog,
      mdAndUp,
      confirmDialog,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  }
};
</script>



<style scoped>
.meal-row {
  height: 80px;
}

@media (max-width: 959px) {
  .v-col {
    flex-direction: column;
  }
}

.spacer-row {
  height: 1px;
  background-color: #f0f0f0;
}

.v-chip {
  margin-bottom: 4px;
}

.v-card-title.primary {
  color: white;
}

.v-simple-table td,
.v-simple-table th {
  padding: 12px 16px;
}

/* Column-specific styles */
.meal-column {
  width: 20%;
}

.description-column {
  width: 41.5%;
}

.allergens-column {
  width: 20%;
}

.price-column {
  width: 6.25%;
}

/* Add word-wrap for long content */
.v-simple-table td {
  word-wrap: break-word;
  max-width: 0;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}
</style>