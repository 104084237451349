<template>
  <v-dialog v-model="show" max-width="400" persistent>
    <v-card class="rounded-lg">
      <v-card-title class="headline d-flex align-center pa-4 bg-purple text-white">
        <v-icon left class="mr-2">mdi-alert-circle</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text class="pa-4 text-body-1">
        {{ message }}
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn color="grey darken-1" text @click="cancel" class="text-capitalize">
          Zrušiť
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" @click="confirm" class="text-capitalize">
          Áno
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      show: false,
      title: '',
      message: '',
      resolve: null,
    }
  },
  methods: {
    open(title, message) {
      this.title = title
      this.message = message
      this.show = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    cancel() {
      this.show = false
      this.resolve(false)
    },
    confirm() {
      this.show = false
      this.resolve(true)
    },
  },
}
</script>

<style scoped>
.v-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.headline {
  font-size: 1.25rem;
  font-weight: 500;
}

.text-body-1 {
  font-size: 1rem;
  line-height: 1.5;
}

.v-btn {
  font-weight: 500;
}
.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent;
  box-shadow: none;
}
</style>
