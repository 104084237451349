<template>
    <v-container fluid class="pa-0" v-if="!showUserPayments">
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-card-title>
                        <v-row no-gutters align="center">
                            <v-col cols="12" sm="8">
                                <v-tabs v-model="activeTab" centered :grow="mobile" :slider-size="mobile ? 2 : 4" dense>
                                    <v-tab class="admin-home-btn" value="Študenti">Študenti</v-tab>
                                    <v-tab class="admin-home-btn" value="Sumy">Sumy</v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <div class="d-flex justify-space-between align-center mb-4">
                            <v-select v-model="selectedSchool" :items="schoolsNames" label="Škola" outlined dense
                                hide-details variant="solo" density="compact"></v-select>
                            <v-menu v-model="filterMenu" :close-on-content-click="false" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-filter-variant</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-list>
                                        <v-list-item v-for="filter in filters" :key="filter.value"
                                            @click="applyFilter(filter.value)">
                                            <v-list-item-title>{{ filter.text }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                        </div>

                        <!-- Študenti Tab Content -->
                        <div v-if="activeTab === 'Študenti'">
                            <v-row align="center">
                                <v-col cols="12" sm="8">
                                    <v-tabs v-model="studentiSubTab" centered :grow="mobile"
                                        :slider-size="mobile ? 2 : 4" dense>
                                        <v-tab value="platby">Platby</v-tab>
                                        <v-tab value="dotacie">Dotácie</v-tab>
                                    </v-tabs>
                                </v-col>
                                <v-col cols="12" sm="4" class="d-flex justify-end">
                                    <v-btn variant="outlined" class="admin-home-btn-add" :block="mobile"
                                        v-if="activeTab === 'Študenti' && studentiSubTab === 'dotacie'"
                                        @click="openAddSubsidyDialog">
                                        Pridať dotáciu
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-window disabled v-model="studentiSubTab">
                                <v-window-item value="platby">
                                    <!-- Existing Platby content -->
                                    <v-data-table :headers="studentsHeaders" :items="students" dense
                                        :items-per-page="itemsPerPage" v-model:page="page"
                                        :server-items-length="totalItems" @update:page="fetchStudents"
                                        @update:items-per-page="fetchStudents">
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actions="{ item }">
                                            <v-btn v-if="activeTab === 2" text @click="handleAction(item)">
                                                Viac
                                            </v-btn>
                                            <v-btn icon @click="openUserPayments(item)">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-window-item>

                                <v-window-item value="dotacie">
                                    <!-- New Dotácie content -->
                                    <v-data-table :headers="dotacieStudentHeaders" :items="studentsWithSubsidies" dense
                                        :items-per-page="itemsPerPage" v-model:page="page"
                                        :server-items-length="totalItems" @update:page="fetchDotacie"
                                        @update:items-per-page="fetchDotacie">
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actions="{ item }">
                                            <v-btn small @click="editSubsidy(item)">Upraviť</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-window-item>
                            </v-window>
                        </div>
                        <!-- Sumy Tab Content -->
                        <div v-if="activeTab === 'Sumy'">
                            <v-tabs v-model="sumyActiveTab">
                                <v-tab value="Školné">Školné</v-tab>
                                <v-tab value="Obedy">Obedy</v-tab>
                                <v-tab value="Dotácie">Dotácie</v-tab>
                            </v-tabs>

                            <v-window disabled v-model="sumyActiveTab">
                                <v-window-item value="Školné">
                                    <NotAvailableComponent />
                                </v-window-item>

                                <v-window-item value="Obedy">
                                    <v-data-table :headers="obedyHeaders" :items="obedyItems" dense
                                        :items-per-page="itemsPerPage" hide-default-footer>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.cena_obedu="{ item }">
                                            <v-chip color="purple" text-color="white" small>
                                                {{ item.cena_obedu }} €
                                            </v-chip>
                                        </template>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actions="{ item }">
                                            <v-btn small @click="editOverheadCost(item)">
                                                Upraviť
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-window-item>

                                <v-window-item value="Dotácie">
                                    <v-data-table :headers="dotacieHeaders" :items="subsidy" :loading="loading"
                                        :items-per-page="itemsPerPage" :page="page" hide-default-footer>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.amount="{ item }">
                                            <v-chip color="purple" text-color="white" small>
                                                {{ item.amount }} €
                                            </v-chip>
                                        </template>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actions="{ item }">
                                            <v-btn @click="editSubsidySchool(item)">Upraviť</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-window-item>
                            </v-window>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Pridanie dotácie pre jednotlivca -->
        <v-dialog v-model="addSubsidyDialog" max-width="500px" width="auto " :fullscreen="$vuetify.display.smAndDown">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Pridať novú dotáciu</span>
                </v-card-title>
                <v-card-text>
                    <v-autocomplete v-model="selectedStudent" :items="studentsList" :loading="loadingStudents"
                        v-model:search-input="searchStudent" item-text="fullName" item-value="id"
                        label="Vyhľadať študenta" placeholder="Začnite písať meno alebo priezvisko"
                        prepend-icon="mdi-database-search" @update:search="searchStudents"
                        return-object></v-autocomplete>
                    <v-text-field v-model="newSubsidyAmount" label="Výška dotácie" type="number" step="0.01"
                        prefix="€"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="addSubsidyDialog = false">Zrušiť</v-btn>
                    <v-btn text @click="createOrUpdateSubsidy">Uložiť</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for editing dotaciu skoly -->
        <v-dialog v-model="editDotaciaDialog" max-width="500px" width="auto " :fullscreen="$vuetify.display.smAndDown">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Upraviť dotáciu</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="editedDotacia.vyska_dotacie" variant="solo" density="compact"
                        label="Výška dotácie" type="number" step="0.01"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="saveDotacia">Uložiť</v-btn>
                    <v-btn color="grey" text @click="editDotaciaDialog = false">Zrušiť</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pre upravenie navysenia obeda pre skolu -->
        <v-dialog v-model="editDialog" width="auto " :fullscreen="$vuetify.display.smAndDown">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Upraviť navýšenie obeda</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="editedItem.navysenie" variant="solo" density="compact"
                        label="Navýšenie obeda" type="number" step="0.01"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="saveEdit">Uložiť</v-btn>
                    <v-btn color="grey" text @click="editDialog = false">Zrušiť</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Dialog pre upravenie dotacie pre skolu -->
        <v-dialog v-model="schoolSubsidyDialog" max-width="500px" width="auto "
            :fullscreen="$vuetify.display.smAndDown">
            <v-card v-if="editedSchoolSubsidy">
                <v-card-title>
                    <span class="text-h5">Upraviť dotáciu pre {{ editedSchoolSubsidy.level || 'školu' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="editedSchoolSubsidy.amount" variant="solo" density="compact"
                        :label="`Dotácia pre ${editedSchoolSubsidy.level || 'školu'}`" type="number"
                        step="0.01"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="saveSchoolSubsidy">Uložiť</v-btn>
                    <v-btn color="grey" text @click="schoolSubsidyDialog = false">Zrušiť</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog" max-width="400" persistent>
            <v-card class="rounded-lg">
                <v-card-title class="headline d-flex align-center pa-4 bg-purple text-white">
                    <v-icon left class="mr-2">mdi-alert-circle</v-icon>
                    Upozornenie
                </v-card-title>
                <v-card-text class="pa-4 text-body-1">
                    {{ warningMessage }}
                </v-card-text>
                <v-card-text class="pa-4 text-body-1" style="color: #6A1B9A; font-weight: bold;">
                    Chcete pokračovať s aktualizáciou dotácie?
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn color="grey darken-1" text @click="handleWarningResponse(false)" class="text-capitalize">
                        Nie
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn variant="outlined" @click="handleWarningResponse(true)" class="text-capitalize">
                        Áno
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
    <user-payments-view v-else :userId="selectedUserId" :isStudent="true" @close="closeUserPayments" />
</template>




<script>
import { ref, onMounted, watch } from "vue";
import Services from "@/api/services";
import NotAvailableComponent from "./NotAvailableComponent.vue";
import { useDisplay } from "vuetify";
import "moment/locale/sk";
import moment from "moment";
import UserPaymentsView from './UserPaymentsComponent.vue';


// Constants
const ITEMS_PER_PAGE = 10;
const PAYMENT_TYPES = {
    SKOLNE: "Školné",
    OBEDY: "Obedy",
    DOTACIE: "Dotácie",
};


export default {
    components: {
        NotAvailableComponent,
        UserPaymentsView
    },
    setup() {
        // Data properties
        const { mobile } = useDisplay();
        const studentiSubTab = ref('platby');
        const activeTab = ref("Študenti");
        const sumyActiveTab = ref("Školné");
        const selectedSchool = ref(null);
        const filterMenu = ref(false);
        const page = ref(1);
        const itemsPerPage = ref(ITEMS_PER_PAGE);
        const totalItems = ref(0);
        const schoolsNames = ref([]);
        const schools = ref([]);
        const filters = ref([
            { text: "Všetky", value: "all" },
            { text: "Zaplatené", value: "paid" },
            { text: "Nezaplatené", value: "unpaid" },
        ]);

        const selectedUserId = ref(null);
        const showUserPayments = ref(false);

        const openUserPayments = (item) => {
            console.log(item)
            selectedUserId.value = item.id;
            showUserPayments.value = true;
        };

        const closeUserPayments = () => {
            showUserPayments.value = false;
            selectedUserId.value = null;
        };

        const schoolSubsidyDialog = ref(false);
        const editedSchoolSubsidy = ref({
            level: "",
            amount: 0,
        });


        // Students tab data
        const students = ref([]);
        const studentsHeaders = ref([
            { title: "Meno žiaka", key: "studentName" },
            { title: "Meno zákonného zástupcu", key: "guardianName" },
            { title: "Ročník/trieda", key: "classInfo", align: "center" },
            { title: "Uhradené školné", key: "paidTuition", align: "center" },
            { title: "Uhradené obedy", key: "paidLunches", align: "center" },
            { key: "actions", sortable: false },
        ]);

        const editSubsidySchool = (item) => {
            console.log("Edit school subsidy:", item);
            editedSchoolSubsidy.value = {
                level: item.level || '',
                amount: item.amount || 0,
            };
            console.log("Edited school subsidy:", editedSchoolSubsidy.value);
            schoolSubsidyDialog.value = true;
        };

        const newSchoolSubsidyData = ref(null);
        const saveSchoolSubsidy = async () => {
            try {
                let type;
                if (editedSchoolSubsidy.value.level == 'Prvý stupeň') {
                    type = 'prvy_stupen';
                } else if (editedSchoolSubsidy.value.level == 'Druhý stupeň') {
                    type = 'druhy_stupen';
                } else if (editedSchoolSubsidy.value.level == 'Materská škola') {
                    type = 'materska_skola';
                }
                const subsidyData = {
                    [type]: editedSchoolSubsidy.value.amount
                };
                const schoolId = schools.value.find(
                    (school) => school.name === selectedSchool.value
                ).id;

                console.log("Subsidy data:", subsidyData, schoolId, editedSchoolSubsidy.value.level);

                // Instead of immediately updating, first check for warnings
                const response = await Services.checkSchoolSubsidyWarnings(schoolId, subsidyData);
                console.log('Check school subsidy warnings response:', response);
                if (response.warnings && response.warnings.length > 0) {
                    subsidyWarnings.value = response.warnings;
                    newSchoolSubsidyData.value = {
                        schoolId,
                        subsidyData,
                        level: editedSchoolSubsidy.value.level
                    };
                    warningMessage.value = 'Aktualizácia tejto dotácie môže spôsobiť, že niektoré jedlá budú pre študentov zadarmo.';
                    warningDialog.value = true;
                } else {
                    // If no warnings, proceed with the update
                    await updateSchoolSubsidy(schoolId, subsidyData);
                }
            } catch (error) {
                console.error('Error saving school subsidy:', error);
                showSnackbar('Vyskytla sa chyba pri ukladaní dotácie pre školu', 'error');
            }
        };

        const searchStudent = ref('');
        const fetchedStudents = ref([]);
        const addSubsidyDialog = ref(false);
        const loadingStudents = ref(false);
        const studentsList = ref([]);
        const selectedStudent = ref(null);

        const openAddSubsidyDialog = () => {
            addSubsidyDialog.value = true;
        };

        const newSubsidyAmount = ref(null);

        const searchStudents = async (search) => {
            if (search && search.length > 2) {
                loadingStudents.value = true;
                console.log('Searching students:', search);
                try {
                    const response = await Services.searchStudents(search);
                    console.log('Search students response:', response);
                    fetchedStudents.value = response;
                    studentsList.value = response.map(student => student.first_name + ' ' + student.last_name);
                    console.log('Students list:', studentsList.value);
                } catch (error) {
                    console.error('Error searching students:', error);
                } finally {
                    loadingStudents.value = false;
                }
            }
        };

        const newSubsidyData = ref(null);
        const warningMessage = ref('');

        const createOrUpdateSubsidy = async () => {

            try {
                const student_id = await Services.getStudentIdByName(selectedStudent.value);
                console.log("STUDENT SUBSIDIES", studentsWithSubsidies.value);
                const existingSubsidy = studentsWithSubsidies.value.find(s => s.student_id === student_id.id);
                let existingSubsidyId;
                if (existingSubsidy) {
                    existingSubsidyId = existingSubsidy.subsidy_id ? existingSubsidy.subsidy_id : null;
                } else {
                    existingSubsidyId = null;
                }
                console.log("PRINT PRINT", student_id)
                console.log('Existing subsidy:', existingSubsidyId);
                let response;
                if (existingSubsidyId) {
                    response = await Services.updateStudentSubsidy(student_id.id, newSubsidyAmount.value);
                    warningMessage.value = 'Aktualizácia tejto dotácie môže spôsobiť, že niektoré jedlá budú pre študenta zadarmo.';
                } else {
                    response = await Services.createStudentSubsidy(student_id.id, newSubsidyAmount.value);
                    warningMessage.value = 'Vytvorenie tejto dotácie môže spôsobiť, že niektoré jedlá budú pre študenta zadarmo.';
                }

                if (response.warnings && response.warnings.length > 0) {
                    subsidyWarnings.value = response.warnings;
                    newSubsidyData.value = {
                        id: response.id,
                        student_id: student_id.id,
                        amount: newSubsidyAmount.value
                    };
                    warningDialog.value = true;
                } else {
                    editedDotacia.value = {
                        id: student_id.id,
                        druh_dotacie: 'Škola',
                        vyska_dotacie: newSubsidyAmount.value
                    };
                    saveDotacia();
                    addSubsidyDialog.value = false;

                    showSnackbar(existingSubsidyId ? 'Dotácia bola úspešne aktualizovaná' : 'Dotácia bola úspešne vytvorená', 'success');
                }
            } catch (error) {
                console.error('Error creating/updating subsidy:', error);
                showSnackbar('Chyba pri vytváraní/aktualizácii dotácie. Skúste to prosím znova.', 'error');
            }
        };



        const updateSubsidyInList = (subsidy, studentId) => {
            const index = studentsWithSubsidies.value.findIndex(s => s.id === studentId);
            if (index !== -1) {
                // Update existing subsidy
                studentsWithSubsidies.value[index].vyska_dotacie = subsidy.amount;
            } else {
                // Add new subsidy
                studentsWithSubsidies.value.push({
                    id: studentId,
                    student_name: selectedStudent.value,
                    vyska_dotacie: subsidy.amount
                });
            }
        };


        // Sumy tab data
        const skolneItems = ref([]);
        const obedyItems = ref([]);
        const dotacieItems = ref([]);

        const skolneHeaders = ref([
            { title: "Študent", key: "student" },
            { title: "Suma", key: "amount" },
            { title: "Dátum splatnosti", key: "dueDate" },
            { title: "Stav", key: "status" },
        ]);

        const obedyHeaders = ref([
            {
                title: "Typ porcie",
                key: "typ_porcie",
                align: "start",
                sortable: false,
            },
            { title: "Navýšenie obeda", key: "cena_obedu", align: "center" },
            { key: "actions", align: "center", sortable: false },
        ]);

        const subsidy = ref([]);

        const dotacieHeaders = ref([
            { title: "Druh dotácie", key: "level" },
            { title: "Suma", key: "amount", align: "end" },
            { key: "actions", align: "end", sortable: false },
        ]);

        const studentsWithSubsidies = ref([]);

        const dotacieStudentHeaders = ref([
            { title: "Meno", align: "start", key: "meno" },
            { title: "Ročník/Trieda", align: "center", key: "rocnik" },
            { title: "Miesto dochádzky", align: "center", key: "miestoDochadzky" },
            { title: "Forma štúdia", align: "center", key: "formaStudia" },
            { title: "Výška dotácie", align: "center", key: "vyskaDotacie" },
            { align: "start", key: "actions" },
        ]);


        const fetchStudentsWithSubsidies = async () => {
            try {
                const response = await Services.getStudentsWithSubsidies(page.value, itemsPerPage.value);
                console.log('Students with subsidies response:', response);
                studentsWithSubsidies.value = response.items.map((student) => {
                    return {
                        student_id: student.id,
                        meno: student.first_name + ' ' + student.last_name,
                        rocnik: student.grade,
                        miestoDochadzky: student.attendance_location,
                        formaStudia: student.study_form === 'part_time' ? 'Externé' : 'Denné',
                        vyskaDotacie: student.subsidies[0].amount,
                        subsidy_id: student.subsidies[0].id,
                        actions: "Upraviť",
                    };
                })
                totalItems.value = response.total_items;
                console.log('Students with subsidies:', studentsWithSubsidies.value);
            } catch (error) {
                console.error('Error fetching students with subsidies:', error);
                // Handle error (e.g., show error message)
            }
        };

        const editedDotacia = ref({
            id: null,
            druh_dotacie: '',
            vyska_dotacie: 0
        });

        const editDotaciaDialog = ref(false);

        const editSubsidy = (item) => {
            editedDotacia.value = {
                subsidy_id: item.subsidy_id,
                id: item.student_id,
                druh_dotacie: item.druh_dotacie,
                vyska_dotacie: item.vyska_dotacie
            };
            editDotaciaDialog.value = true;
        };

        const warningDialog = ref(false);
        const subsidyWarnings = ref([]);

        const saveDotacia = async () => {
            try {
                console.log('Edited dotacia:', editedDotacia.value);
                const response = await Services.updateStudentSubsidy(
                    editedDotacia.value.id,
                    parseFloat(editedDotacia.value.vyska_dotacie)
                );

                if (response.warnings && response.warnings.length > 0) {
                    warningMessage.value = 'Aktualizácia tejto dotácie môže spôsobiť, že niektoré jedlá budú pre študenta zadarmo';
                    subsidyWarnings.value = response.warnings;
                    warningDialog.value = true;
                } else {
                    // If no warnings, proceed with the update
                    applySubsidyUpdate(response);
                    fetchStudentsWithSubsidies();
                }

            } catch (error) {
                console.error('Error saving dotacia:', error);
                // Handle error (e.g., show error message to user)
            }
        };

        const applySubsidyUpdate = (updatedSubsidy) => {
            // Update the local data
            const index = studentsWithSubsidies.value.findIndex(s => s.id === updatedSubsidy.id);
            if (index !== -1) {
                studentsWithSubsidies.value[index] = {
                    ...studentsWithSubsidies.value[index],
                    vyska_dotacie: updatedSubsidy.amount
                };
            }

            editDotaciaDialog.value = false;
            fetchStudentsWithSubsidies();
        };

        const formatDate = (dateTime) => {
            return moment(dateTime).format("DD.MM.YYYY");
        };

        const handleWarningResponse = async (confirm) => {
            try {
                if (confirm) {
                    if (newSubsidyData.value) {
                        // Handle student subsidy
                        updateSubsidyInList(newSubsidyData.value, newSubsidyData.value.student_id);
                    } else if (newSchoolSubsidyData.value) {
                        // Handle school subsidy
                        await updateSchoolSubsidy(newSchoolSubsidyData.value.schoolId, newSchoolSubsidyData.value.subsidyData);
                    }
                } else {
                    // User cancelled, no action needed for school subsidy
                    if (newSubsidyData.value && !studentsWithSubsidies.value.find(s => s.id === newSubsidyData.value.student_id)) {
                        const result = await Services.deleteSubsidy(newSubsidyData.value.id);
                        if (!result.success) {
                            throw new Error('Failed to delete subsidy');
                        }
                    }
                }
            } catch (error) {
                console.error('Error handling warning response:', error);
                showSnackbar('Chyba pri spracovaní odpovede. Skúste to prosím znova.', 'error');
            } finally {
                editDialog.value = false;
                addSubsidyDialog.value = false;
                schoolSubsidyDialog.value = false;
                warningDialog.value = false;
                newSubsidyData.value = null;
                newSchoolSubsidyData.value = null;
            }
        };
        const fetchSchoolSubsidy = async () => {
            try {
                const school = schools.value.find(
                    (school) => school.name === selectedSchool.value
                );

                if (!school) {
                    console.error('Selected school not found');
                    return;
                }

                console.log('School ID:', school.id);
                const response = await Services.getSchoolSubsidy(school.id);
                console.log('School subsidy:', response);

                if (typeof response === 'object' && response !== null) {
                    const isKindergarten = response.is_kindergarten // Assuming you have this property in your school object
                    console.log('Is kindergarten:', isKindergarten);
                    if (isKindergarten == true) {
                        subsidy.value = [{
                            level: 'Materská škola',
                            amount: response.materska_skola,
                            actions: "Upraviť",
                        }];
                    } else {
                        subsidy.value = [
                            {
                                level: 'Prvý stupeň',
                                amount: response.prvy_stupen,
                                actions: "Upraviť",
                            },
                            {
                                level: 'Druhý stupeň',
                                amount: response.druhy_stupen,
                                actions: "Upraviť",
                            }
                        ];
                    }
                } else {
                    console.error('Unexpected response format');
                    subsidy.value = [];
                }
            } catch (error) {
                console.error('Error fetching school subsidy:', error);
                subsidy.value = [];
                // Handle error (e.g., show error message)
            }
        }

        const editDialog = ref(false);

        const editedItem = ref({
            typ_porcie: '',
            navysenie: 0
        });

        const editOverheadCost = (item) => {
            editedItem.value = {
                typ_porcie: item.typ_porcie,
                navysenie: item.cena_obedu || 0
            };
            editDialog.value = true;
        };

        const closeEdit = () => {
            editDialog.value = false;
            editedItem.value = {
                typ_porcie: '',
                navysenie: 0
            };
        };

        const saveEdit = async () => {
            try {

                console.log("Edited item:", editedItem.value.typ_porcie, editedItem.value.navysenie);
                let type
                if (editedItem.value.typ_porcie == 'Prvý stupeň') {
                    type = 'prvy_stupen';
                } else if (editedItem.value.typ_porcie == 'Prvý stupeň (polovičná porcia)') {
                    type = 'prvy_stupen_half';
                } else if (editedItem.value.typ_porcie == 'Druhý stupeň') {
                    type = 'druhy_stupen';
                } else if (editedItem.value.typ_porcie == 'Druhý stupeň (polovičná porcia)') {
                    type = 'druhy_stupen_half';
                } else if (editedItem.value.typ_porcie == 'Materská škola') {
                    type = 'materska_skola';
                } else if (editedItem.value.typ_porcie == 'Materská škola (polovičná porcia)') {
                    type = 'materska_skola_half';
                }
                const schoolId = schools.value.find(
                    (school) => school.name === selectedSchool.value
                ).id;
                // Update only the navysenie (surcharge) for the specific meal type
                await Services.updateSchoolOverheadCosts(schoolId, {
                    [type]: Number(editedItem.value.navysenie)
                });

                // Update the local data
                const index = obedyItems.value.findIndex(item => item.typ_porcie === editedItem.value.typ_porcie);
                if (index !== -1) {
                    obedyItems.value[index].navysenie = Number(editedItem.value.navysenie);
                }

                await fetchObedy();
                closeEdit();
                showSnackbar('Navýšenie obeda bolo úspešne aktualizované', 'success');
            } catch (error) {
                console.error('Error updating overhead cost:', error);
                showSnackbar('Vyskytla sa chyba pri ukladaní navýšenia obeda', 'error');
            }
        };

        const fetchSchools = async () => {
            try {
                const response = await Services.getSchools();
                console.log("Schools:", response);
                //map to schools name and id
                schools.value = response;
                schoolsNames.value = response.map((school) => school.name);
                schoolsNames.value.unshift("Všetky školy");
            } catch (error) {
                console.error("Error fetching schools:", error);
            }
        };

        watch(selectedSchool, (newValue, oldValue) => {
            console.log("Selected school changed:", newValue, oldValue);
            if (sumyActiveTab.value === 'Obedy' && newValue !== oldValue) {
                fetchObedy();
            } else if (activeTab.value === 'Študenti' && newValue !== oldValue) {
                fetchStudents(page.value, itemsPerPage.value, newValue);
            } else if (sumyActiveTab.value === 'Dotácie' && newValue !== oldValue) {
                fetchSchoolSubsidy();
            }
        });

        // Methods
        const fetchStudents = async (
            newPage = page.value,
            newItemsPerPage = itemsPerPage.value,
            newSchool = selectedSchool.value
        ) => {
            console.log("Fetching students:", newPage, newItemsPerPage, newSchool);
            try {
                let schoolId = null;
                if (newSchool && newSchool !== "Všetky školy") {
                    console.log("Schools", schools.value);
                    const school = schools.value.find(
                        (school) => school.name === newSchool
                    );
                    schoolId = school ? school.id : null;
                }
                console.log("Fetching students:", newPage, newItemsPerPage, schoolId);
                // Use the getStudents function from the service with pagination parameters
                const response = await Services.getStudentsPayments(
                    newPage,
                    newItemsPerPage,
                    schoolId
                );
                console.log("Students response:", response);

                if (response && Array.isArray(response.items)) {
                    students.value = response.items.map((student) => {
                        return {
                            id: student.id,
                            studentName: student.studentName,
                            guardianName: student.guardianName,
                            classInfo: student.classInfo,
                            paidTuition: student.paidTuition,
                            paidLunches: student.paidLunches,
                            actions: "Not Now",
                        };
                    });
                    console.log("Students:", students.value);
                    totalItems.value = response.total_items || 0;
                    page.value = response.page || 1;
                    itemsPerPage.value = newItemsPerPage;
                } else {
                    console.error("Invalid response structure:", response);
                    students.value = [];
                    totalItems.value = 0;
                    page.value = 1;
                }
            } catch (error) {
                console.error("Error fetching students:", error);
                showSnackbar('Vyskytla sa chyba pri načítaní žiakov', 'error');
                students.value = [];
                totalItems.value = 0;
                page.value = 1;
            }
        };

        const fetchSkolne = async () => {
            // Implement fetching školné data
        };

        const fetchObedy = async () => {
            if (selectedSchool.value && selectedSchool.value !== "Všetky školy") {
                try {
                    const schoolId = schools.value.find(
                        (school) => school.name === selectedSchool.value
                    ).id;
                    const response = await Services.getSchoolOverheadCosts(
                        schoolId
                    );
                    console.log("Overhead costs:", response);

                    obedyItems.value = [
                        { typ_porcie: "Prvý stupeň", cena_obedu: response.prvy_stupen, actions: "Upraviť" },
                        {
                            typ_porcie: "Prvý stupeň (polovičná porcia)",
                            cena_obedu: response.prvy_stupen_half,
                            actions: "Upraviť"
                        },
                        { typ_porcie: "Druhý stupeň", cena_obedu: response.druhy_stupen, actions: "Upraviť" },
                        {
                            typ_porcie: "Druhý stupeň (polovičná porcia)",
                            cena_obedu: response.druhy_stupen_half,
                            actions: "Upraviť"
                        },
                        {
                            typ_porcie: "Materská škola",
                            cena_obedu: response.materska_skola,
                            actions: "Upraviť"
                        },
                        {
                            typ_porcie: "Materská škola (polovičná porcia)",
                            cena_obedu: response.materska_skola_half,
                            actions: "Upraviť"
                        },
                    ];

                    // Filter out items based on school type
                    if (response.is_kindergarten) {
                        obedyItems.value = obedyItems.value.filter((item) =>
                            item.typ_porcie.includes("Materská škola")
                        );
                    } else {
                        obedyItems.value = obedyItems.value.filter(
                            (item) => !item.typ_porcie.includes("Materská škola")
                        );
                    }
                } catch (error) {
                    console.error("Error fetching overhead costs:", error);
                    showSnackbar('Vyskytla sa chyba pri načítaní navýšenia obeda', 'error');
                    obedyItems.value = [];
                }
            } else {
                obedyItems.value = [];
            }
        };
        const updateOverheadCosts = async (item) => {
            try {
                await Services.updateSchoolOverheadCosts(item.id, {
                    prvy_stupen: item.prvy_stupen,
                    prvy_stupen_half: item.prvy_stupen_half,
                    druhy_stupen: item.druhy_stupen,
                    druhy_stupen_half: item.druhy_stupen_half,
                    materska_skola: item.materska_skola,
                    materska_skola_half: item.materska_skola_half,
                });
                // Refresh the data after update
                await fetchObedy();
            } catch (error) {
                console.error("Error updating overhead costs:", error);
                showSnackbar('Vyskytla sa chyba pri ukladaní navýšenia obeda', 'error');
            }
        };

        const fetchDotacie = async () => {
            // Implement fetching dotácie data
        };

        const applyFilter = (filter) => {
            console.log("Applying filter:", filter);
        };

        watch(sumyActiveTab, async (newTab) => {
            console.log("Active sumy tab changed:", newTab);
            if (newTab === 'Obedy') {
                await fetchObedy();
            } else if (newTab === 'Dotácie') {
                await fetchSchoolSubsidy();
            } else if (newTab === 'Školné') {
                await fetchSkolne();
            }
        });

        watch(studentiSubTab, async (newTab) => {
            console.log("Active studenti sub tab changed:", newTab);
            if (newTab === 'dotacie') {
                await fetchStudentsWithSubsidies();
            }
        });

        watch(activeTab, async (newTab) => {
            console.log("Active tab changed:", newTab);
            if (newTab === 'Študenti') {
                selectedSchool.value = "Všetky školy";
                schoolsNames.value.unshift("Všetky školy");
                await fetchStudents(page.value, itemsPerPage.value, selectedSchool.value);
            }
            if (newTab === 'Sumy') {
                schoolsNames.value = schoolsNames.value.filter(school => school !== "Všetky školy");
                selectedSchool.value = schoolsNames.value[0];
                console.log(schoolsNames.value, selectedSchool.value);
            }
        });
        // Lifecycle hooks
        onMounted(() => {
            selectedSchool.value = "Všetky školy";
            fetchSchools();
            fetchStudents(page.value, itemsPerPage.value, selectedSchool.value);
            fetchStudentsWithSubsidies();
        });

        const snackbar = ref(false);
        const snackbarText = ref('');
        const snackbarColor = ref('success');

        const showSnackbar = (text, color = 'success') => {
            snackbarText.value = text;
            snackbarColor.value = color;
            snackbar.value = true;
        };

        const updateSchoolSubsidy = async (schoolId, subsidyData) => {
            const updatedSubsidy = await Services.updateSchoolSubsidy(schoolId, subsidyData);
            const index = subsidy.value.findIndex(s => s.level === editedSchoolSubsidy.value.level);
            if (index !== -1) {
                subsidy.value[index] = {
                    ...subsidy.value[index],
                    amount: updatedSubsidy[editedSchoolSubsidy.value.level.toLowerCase().replace(' ', '_')]
                };
            }
            showSnackbar('Dotácia pre školu bola úspešne aktualizovaná', 'success');
            schoolSubsidyDialog.value = false;
            fetchSchoolSubsidy();
        };

        return {
            activeTab,
            sumyActiveTab,
            selectedSchool,
            filterMenu,
            page,
            itemsPerPage,
            totalItems,
            schools,
            filters,
            students,
            studentsHeaders,
            skolneItems,
            obedyItems,
            dotacieItems,
            skolneHeaders,
            obedyHeaders,
            dotacieHeaders,
            PAYMENT_TYPES,
            fetchStudents,
            fetchSkolne,
            fetchObedy,
            fetchDotacie,
            applyFilter,
            schoolsNames,
            updateOverheadCosts,
            editOverheadCost,
            saveEdit,
            editDialog,
            editedItem,
            studentiSubTab,
            dotacieStudentHeaders,
            studentsWithSubsidies,
            editSubsidy,
            subsidy,
            schoolSubsidyDialog,
            editSubsidySchool,
            saveSchoolSubsidy,
            editedSchoolSubsidy,
            editDotaciaDialog,
            saveDotacia,
            editedDotacia,
            openAddSubsidyDialog,
            selectedStudent,
            newSubsidyAmount,
            addSubsidyDialog,
            createOrUpdateSubsidy,
            searchStudent,
            searchStudents,
            studentsList,
            mobile,
            handleWarningResponse,
            warningDialog,
            subsidyWarnings,
            fetchSchoolSubsidy,
            formatDate,
            warningMessage,
            UserPaymentsView,
            openUserPayments,
            closeUserPayments,
            selectedUserId,
            showUserPayments,
            snackbar,
            snackbarText,
            snackbarColor,
            newSchoolSubsidyData
        };
    },
};
</script>

<style scoped>
.admin-home-btn-add {
    text-transform: none;
    font-weight: bold;
    color: purple;
    /* Change the text color to purple */
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px 8px;
    /* Adjust padding to ensure the outline fits the text */
    line-height: 1.5;
    /* Adjust line-height to ensure the text is centered */
    display: inline-block;
    /* Ensure the button is displayed as an inline element */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

@media screen and (max-width: 600px) {
    .admin-home-btn-add {
        font-size: 12px;
        /* Smaller font size for mobile */
        padding: 2px 6px;
        /* Adjust padding for mobile if needed */
    }
}

@media screen and (max-width: 960px) {
    .admin-home-btn-add {
        font-size: 14px;
        /* Larger font size for tablet */
        padding: 4px 8px;
        /* Adjust padding for tablet if needed */
    }
}

.v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent;
    box-shadow: none;
}
</style>